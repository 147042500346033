.tarjeta-credito .contenedorTarjetita {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 20px;
}

@media(max-width: 714px){

    .tarjeta-credito .contenedorTarjetita {
        margin: 0 auto;
    }
}
.tarjeta-credito .tarjeta {
    background: #666;
    overflow: hidden;
    border-radius: 10px;
    width: 300px;
    height: 175px;
    padding: 10px;
    position: relative;
    transition: all .4s ease;
    margin: 60px auto;
}

.tarjeta-credito .tarjeta.mastercard {
    background-color: #097ca8;
    background-image: repeating-linear-gradient(45deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, .03) 2px, rgba(255, 255, 255, .04) 3px, rgba(255, 255, 255, .05) 4px), repeating-linear-gradient(135deg, rgba(255, 255, 255, .05) 1px, rgba(255, 255, 255, 0) 2px, rgba(255, 255, 255, .04) 3px, rgba(255, 255, 255, .03) 4px), repeating-linear-gradient(90deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, .03) 2px, rgba(255, 255, 255, .04) 3px, rgba(255, 255, 255, .05) 4px), repeating-linear-gradient(210deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, .03) 2px, rgba(255, 255, 255, .04) 3px, rgba(255, 255, 255, .05) 4px), repeating-radial-gradient(circle at 30% 30%, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, .03) 2px, rgba(255, 255, 255, .04) 3px, rgba(255, 255, 255, .05) 4px), repeating-radial-gradient(circle at 70% 70%, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, .03) 2px, rgba(255, 255, 255, .04) 3px, rgba(255, 255, 255, .05) 4px), repeating-radial-gradient(circle at 90% 20%, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, .03) 2px, rgba(255, 255, 255, .04) 3px, rgba(255, 255, 255, .05) 4px), repeating-radial-gradient(circle at 15% 80%, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, .03) 2px, rgba(255, 255, 255, .04) 3px, rgba(255, 255, 255, .05) 4px), linear-gradient(-25deg, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, .2) 70%, rgba(255, 255, 255, 0) 90%);
}

.tarjeta-credito .tarjeta.visa {
    background-color: #766ba8;
    background-image: repeating-linear-gradient(45deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, .03) 2px, rgba(255, 255, 255, .04) 3px, rgba(255, 255, 255, .05) 4px), repeating-linear-gradient(135deg, rgba(255, 255, 255, .05) 1px, rgba(255, 255, 255, 0) 2px, rgba(255, 255, 255, .04) 3px, rgba(255, 255, 255, .03) 4px), repeating-linear-gradient(90deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, .03) 2px, rgba(255, 255, 255, .04) 3px, rgba(255, 255, 255, .05) 4px), repeating-linear-gradient(210deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, .03) 2px, rgba(255, 255, 255, .04) 3px, rgba(255, 255, 255, .05) 4px), repeating-radial-gradient(circle at 30% 30%, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, .03) 2px, rgba(255, 255, 255, .04) 3px, rgba(255, 255, 255, .05) 4px), repeating-radial-gradient(circle at 70% 70%, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, .03) 2px, rgba(255, 255, 255, .04) 3px, rgba(255, 255, 255, .05) 4px), repeating-radial-gradient(circle at 90% 20%, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, .03) 2px, rgba(255, 255, 255, .04) 3px, rgba(255, 255, 255, .05) 4px), repeating-radial-gradient(circle at 15% 80%, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, .03) 2px, rgba(255, 255, 255, .04) 3px, rgba(255, 255, 255, .05) 4px), linear-gradient(-25deg, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, .2) 70%, rgba(255, 255, 255, 0) 90%);
}

.tarjeta-credito .tipoTarjeta {
    position: absolute;
    right: 10px;
    top: 10px;
}

.tarjeta-credito .chip {
    margin-top: 45px;
}

.tarjeta-credito .tarjeta .nroTarjeta {
    font-family: "Gill Sans Std";
    font-size: 20px;
    clear: both;
    margin-top: 10px;
    font-weight: bold;
    text-shadow: 0 1px 3px #000;
    color: #fff;
}

.tarjeta-credito .tarjeta .nombreTitular {
    position: absolute;
    bottom: 15px;
    font-family: "Gill Sans Std";
    font-size: 14px;
    clear: both;
    font-weight: bold;
    text-shadow: 0 1px 3px #000;
    color: #fff;
    text-transform: uppercase;
    font-family: "Gill Sans Std";
    font-size: 14px;
    max-height: 32px;
    width: 75%;
    max-width: 150px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: horizontal;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    text-shadow: 0 1px 3px #000;
}

.tarjeta-credito .contenedorVencimientoTarjeta {
    bottom: 15px;
    right: 15px;
    position: absolute;
}

.tarjeta-credito .vencimientoTarjeta {
    font-family: "Gill Sans Std";
    letter-spacing: 0;
    position: relative;
    float: right;
    font-weight: bold;
    text-shadow: 0 1px 3px #000;
    color: #fff;
    font-size: 14px;
    display: flex;
    flex-direction: row;
}


/*Resumen pedido*/
.tarjeta-credito .datosResumen {
    border: 1px solid black;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.tarjeta-credito .datosResumen h4 {
    margin: 10px 0 5px 0;
}

.tarjeta-credito .datosResumen p {
    font-size: 14px;
    float: left;
}

.tarjeta-credito .filaDetalle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.tarjeta-credito .filaDetalle p {
    font-size: 11px;
}

.tarjeta-credito .datosResumen .lineaNegra {
    width: 100%;
    line-height: 1px;
    border: 0.5px solid black;
}

.tarjeta-credito .info-instalacion p{
    font-family: "Gill Sans Std";
    font-size: 10px;
}


/*@media screen and (max-width: 1194px) {*/
/*    .tarjeta-credito .datosTarjeta, .tarjeta-credito .datosResumen {*/
/*        height: 470px;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 1059px) {*/
/*    .tarjeta-credito .datosTarjeta, .tarjeta-credito .datosResumen {*/
/*        height: 575px;*/
/*    }*/
/*}*/

@media screen and (max-width: 760px) {
    .tarjeta-credito .datosTarjeta, .tarjeta-credito .datosResumen {
        height: fit-content;
    }

}

@media screen and (max-width: 320px) {
    .tarjeta-credito .tarjeta {
        /*width: 200px;*/
        height: 140px;
        padding: 10px;
    }
}

@media (max-width: 715px) {
    .contenedorTarjeta .datos {
        width: 100%;
    }

    .contenedor-vencycod{
        max-width: 312px;
        align-self: center;
        width: 100%;
        margin: 0!important;
    }

    .tarjeta-credito .campo{
        margin: 0;
    }
}
