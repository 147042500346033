.productos-home .grid{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.linea-derecha{
    border-right: 2px solid #00ffea;
    padding-right: 43px;
}


.compra-profesional{
    text-align: center;
    max-width: 300px;
    margin: 0 auto;
    position: relative;
}
.compra-profesional img{
    margin-top: 20px;
    width: 100%;
}

.compra-profesional Button {
    position: absolute;
    left: 80px;
    bottom: 10%;
    background-image: none;
    border: none;
    border-radius: 50px;
    width: 123px;
    text-shadow: none !important;
    color: white !important;
    padding-bottom: 2px;
    height: 30px;
    background-color: #00aeef !important;
    font-family: "Myriad Pro" !important;
    font-weight: 500 !important;
}


.compra-profesional Button:hover{
    background-color: #0084b9 !important;

}

.know{
    max-width: 300px;
    margin: 0 auto;
    position: relative;
}
@media (max-width: 991px){
    .know{
        max-width: none;
        margin-bottom: 40px;
    }
}

@media (max-width: 380px){
    .know h4{
        font-size: 15px !important;
    }
}

.know h4{
    margin-top: 70px;
    color:white;
    font-family: "Montserrat";
    font-weight: 300;
    font-size: 2rem;
}

.linea-corta{
    border-top: 2px solid #00ffea;
    width: 57px;
    margin-top: -3px;
    margin-left: 35px;
}

.bigButton.cam{
    background-image: url("../../assets/img/lineCam.png");
    background-repeat: no-repeat;
    background-position: 150%;
    padding-top: 16px;

}
.bigButton.casa{
    background-image: url("../../assets/img/lineCasa.png");
    background-repeat: no-repeat;
    background-position: 112%;

}
.bigButton{
    border:1px solid #00ffea;
    border-top: 6px solid white;
    border-bottom: 4px solid #00ffea;
    height: 250px;
    cursor: pointer;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1);
    transition: all 200ms ease-in;
    transform: scale(1);
    margin:5%;
    background-color: #00aeef;
}

@media(max-width: 768px){
    .bigButton{
        margin: 0;
    }
}
.bigButton:hover{
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1.1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1.1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1.1);
    transition: all 200ms ease-in;
    transform: scale(1.1);
}

.bigButton h4{
    padding: 0 10%;
    color:#8ddd49;
    font-family: "Montserrat";
    font-weight: 200;
    font-size: 3.8rem;
    margin-top: 14%;

}


@media(max-width: 1024px){
    .linea-derecha{
        padding-right: 20px;
        padding-left: 20px;
        border: none;

    }
}