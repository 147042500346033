.opensafe-head{
    border-top:2px solid #00ffeb;
    padding-top: 25px;
    padding-left: 15px;
    padding-right: 15px;
}

.opensafe-head .container-cuenta{
    display: inline-flex;
    align-items: baseline;
    color: #00ffeb;
    border-bottom: 1px solid #00ffeb;
    margin-top: 50px;
}

.opensafe-head .container-cuenta h3,.opensafe-head .container-cuenta h4{
    margin: 0;
    padding: 4px;
    font-family: "Myriad Pro";
    font-weight: 100;
}
.opensafe-head .container-cuenta a{
    color: white;
    font-family: "Myriad Pro";
    font-weight: 100;
    padding: 4px;
}

.opensafe-head .herramienta-head{
    display: flex;
    align-items: flex-end;
    padding-bottom: 30px;

}

.opensafe-head .dot-blanco{
    background-image: url("../img/dot.png");
    background-repeat: repeat-x;
    background-position: bottom;
    height: 10px;
    width: 100%;
    margin-right: 50px;
    background-position-x: 28px;
}
.opensafe-head .herramienta-head h1 {
    text-align: right;
    color: #00ffeb;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 65px;
    white-space: nowrap;
    margin: 0px;
}

@media (max-width: 680px) {
    .opensafe-head .herramienta-head h1 {
        font-size: 40px;
    }

    .opensafe-head .container-cuenta h3 {
        font-size: 20px;
    }

    .opensafe-head .container-cuenta h4 {
        font-size: 14px;
    }
    .opensafe-head .container-cuenta a {
        font-size: 10px;
    }
}

/*============================================*/

.opensafe-herramientas{
    background-color: white;
    width: 100%;
    min-height: calc(100vh - 410px);
}

/*===========================================*/

.herramienta-invitar{
    color:#00aaeb;
    padding: 0 15px 0 15px;
}
.herramienta-invitar h5{
    font-family: "Myriad Pro";
    font-size: 30px;
    padding-top: 50px;
}

.herramienta-invitar p{
    max-width: 450px;
    font-family: "Montserrat";
    font-weight: 100;
    font-size: 14px;
    color: black
}

.herramienta-invitar input, .herramienta-invitar select, .herramienta-invitar textarea{
    background: none !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    border: none;
    border-top:1px solid #66cdf7;
    border-bottom:2px solid #00aff2;
    box-shadow: none!important;
    color:#00aff2;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-bottom: 30px;
    font-family: "Myriad Pro";
    font-size: 16px;
    font-weight: 100;
    height: 48px;
    width: 100%;
}

input.input-codacceso {
    background-color: #00a0ef !important;
    color:white;
    font-weight: 500;
    font-size: 17px;
}

/*===================================*/
.herramienta-invitar Button{
    background: none;
    box-shadow: none;
    border: none;
    text-shadow: none;
    background-color: #00aaeb;
    border-radius: 150px;
    font-size: 25px;
    font-family: "Montserrat";
    font-weight: 400;
    color:white;
    margin-top: 50px;
    padding-left: 60px;
    padding-right: 60px;
    -webkit-transition: ease-in 100ms;
    -moz-transition: ease-in 100ms;
    -ms-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    outline: none;
    font-family:"Myriad Pro";
    font-weight: 100;
}

.herramienta-invitar .btn-default:hover, .herramienta-invitar .btn-default:focus {
    background-color: #00aaeb;
    color:white;
    -webkit-transition: ease-in 100ms;
    -moz-transition: ease-in 100ms;
    -ms-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    outline: none;

}

.opensafe-herramientas .usuarioInvitado h4 {
    padding-top: 30px;
}