.modificarPedido {
    background-color: white;
    width: 100%;
    padding: 0 50px 50px 50px;
    color: #00aaeb;
    min-height: 57vh;
}

.modificarPedido .titulo {
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
}

.modificarPedido .titulo h3 {
    color: #00aaeb;
    margin-bottom: 20px;
    float: left;
}

.modificarPedido .titulo p {
    float: left;
    padding-top: 10px;
    cursor: pointer;
    margin-left: 5px;
}

.modificarPedido .comboCUITDNI {
    font-weight: bold;
}

.modificarPedido input:first-child {
    border-top: 1px solid #00ffea !important;
}

.modificarPedido input, .modificarPedido select {
    font-family: "Myriad Pro";
    font-weight: 100;
    line-height: 44px;
    font-size: 20px;
    width: 100%;
    color: #555;
    /*background-color: #00AEEF;*/
    background: none;
    border: none;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    border-bottom: 2px solid #00aaea;
    padding: 20px;
    margin-bottom: 20px;
    padding: 0 13px;
    height: 45px;
}

.modificarPedido input:focus {
    outline: none;
}

.modificarPedido select {
    padding: 0px 0 0px 20px;
    height: fit-content;
    -webkit-appearance: none;
    border-top: 1px solid #00ffeb;
}

.modificarPedido .condicionIva p {
    font-weight: bold;
}

/*Boton enviar*/
.modificarPedido .btn-default {
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    text-shadow: none;
    background-color: #00aaeb;
    border-radius: 150px;
    font-size: 25px;
    font-family: "Montserrat";
    font-weight: 400;
    color: white;
    margin-top: 50px;
    padding-left: 60px;
    padding-right: 60px;
    -webkit-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    outline: none;
    font-family: "Myriad Pro";
    font-weight: 100;
}

.modificarPedido .btn-default:hover {
    background-color: #00aaeb;
    color: white;
    -webkit-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    outline: none;
}

.modificarPedido .botonesCuentaYOrden {
    display: flex;
    justify-content: space-evenly;
}

.modificarPedido .botonesCuentaYOrden button {
    display: flex;
    justify-content: space-evenly;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 160px;
}

@media screen and (max-width: 500px) {
    .modificarPedido .boton {
        width: 100px !important;
        font-size: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .modificarPedido .titulo h3 {
        font-size: 18px;
    }
}

@media (max-width: 600px) {
    .modificarPedido {
        padding-right: 10px;
        padding-left: 10px;
    }
}

@media (max-width: 450px) {
    .modificarPedido h3 {
        font-size: 20px;
    }

    .modificarPedido .comboCUITDNI, .modificarPedido label, .modificarPedido p, .modificarPedido .help-block {
        font-size: 12px;
    }

    .modificarPedido input, .modificarPedido select {
        font-size: 15px;
    }
}