.equipo {
    background-image: url("../img/equipo2.png");
    background-repeat: no-repeat;
    background-position: center bottom;
    /*background-size: cover;*/
    height: 665px;
    border-bottom: 2px solid #00ffea;
    background-color: #00b0f2;
}

@media(max-width: 686px){
    .equipo{
        -webkit-background-size: 112%;
        background-size: 112%;
        height: 391px;
    }
}


@media(max-width: 668px){
    .equipo{
        -webkit-background-size: 153%;
        background-size: 153%;
        height: 467px;
    }
    .equipo .cuadro-white h1 {
        font-size: 35px!important;
    }
}

@media(max-width: 530px){
    .equipo{
        -webkit-background-size: 163%;
        background-size: 163%;
        height: 460px;
    }
}

@media(max-width: 481px){
    .equipo{
        -webkit-background-size: 193%;
        background-size: 193%;
        height: 490px;
    }
    .equipo .cuadro-white {
        top:47%;
    }
}
@media(max-width: 384px){
    .equipo{
        height: 400px;
    }
    .equipo .cuadro-white {
        font-size: 10px;
        top:50% !important;
    }
}

@media(max-width: 360px){
    .equipo{
        height: 350px;
    }
    .equipo .cuadro-white {
        font-size: 10px;
        top:40% !important;
    }
}



.equipo .cuadro-white {
    background-color: white;
    text-align: center;
    position: relative;
    top: 46%;
    padding: 20px;
    color: #00aeef;
}

.equipo .cuadro-white h1 {
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 45px;
}

.equipo .cuadro-white p {
    font-family: "Myriad Pro";
    font-weight: 100 !important;
    margin-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
}

.historia {
    margin-top: 90px;
}

.historia .anios {
    background-image: url("../img/recuadro1.png");
    background-repeat: no-repeat;
    background-position-x: 70px;
    background-size: auto;
    padding-bottom: 18px;
}

.historia h2 {
    padding-top: 100px;
    color: white;
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 85px;
    padding-left: 15px;
    padding-right: 15px;
}

.historia p {
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    max-width: 500px;
    font-family: "Myriad Pro";
    font-weight: 100;
    padding-bottom: 32px;
    font-size: 16px;
    padding-top: 25px;
    max-width: 350px;
}

.historia .linea-bottom {
    height: 205px;
    border-bottom: 2px solid #00ffea;
}


@media(max-width: 994px){
    .historia{
        display: none;
    }
    /*.historia-responsive{*/
        /*display: inline;*/
    /*}*/
}



.historia .nro-entregados {
    background-image: url("../img/recuadro2.png");
    background-repeat: no-repeat;
    background-size: auto;
    background-position-x: 70px;
    padding-bottom: 130px;
}

.historia .nro-entregados h2 {
    text-align: right;
}

.historia .nro-entregados p {
    text-align: right;
    font-size: 15px;
    padding-top: 20px;
    max-width: 250px;
    padding-bottom: 30px;
    float: right
}

.historia .entregados p{
    margin-top: 168px;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 25px;
    text-align: right;
    border-left: 1px solid #00ffea;
    padding-bottom: 0px;
    padding-top: 0px;
}
.historia .anios-especialistas h2{
    padding-top: 116px;
    font-size: 95px;
}
.historia .anios-especialistas p{
    font-size: 30px;
    padding-top: 0;
    padding-bottom: 84px;

}

.historia .items-tareas{
    margin-top: -14px;
}
.historia .recuadro{
    border: 1px solid #00ffea;
    display: inline-block;
    margin-left: -24px !important;
    padding-left: 24px !important;
    padding-right: 110px !important;
    padding-top: 5px !important;
    background-color: #00aeef !important;
    background-color: #00aeef !important;
    z-index: 13;
    position: relative;
}
.historia .items-tareas p{
    padding: 0;
    font-size: 30px;
    font-family: "Myriad Pro";
    font-weight: 100;
    margin: 0;
    background-color: transparent;
}

.historia ul{
    list-style-type: none;
    padding-left: 160px;
}


.historia li{
    margin-top: 35px;
}

.historia .linea-cctv{
    border-bottom: 1px solid #00ffea;
    margin-top: 199px;
    z-index: 34;
}

.llamanos{
    margin-top: 50px;
    background-color:#9ae235;
    border-top: 2px solid #00ffea;
    background-image: url("../img/pais.png");
    height: 591px;
    background-repeat: no-repeat;
    background-position: -20px;
}

@media(max-width: 1068px) {
 .llamanos{
     background-position: -190px;
 }
}

@media(max-width: 920px) {
    .llamanos{
        background-position: -300px;
    }
}

@media(max-width: 776px) {
    .llamanos{
        background-position: -500px;
    }
}
@media(min-width: 520px){
    .llamanos .invisible{
        visibility: visible;
    }
}

.llamanos h3{
    color:#00aeef;
    margin-top: 20%;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 50px;
    text-align: right;
}
.llamanos h3 sb{
    border-bottom: 2px solid #00ffea;
}
.llamanos p{
    font-family: Montserrat;
    font-weight: 500;
    color:#00aeef;
    font-size: 67px !important;
    margin-top: 110px;
    text-align: right;
}

.mejores-proyectos{
    background-image: url("../img/bg-mejoresProyectos.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    text-align: center;
    border-bottom: 3px solid #00ffea;
    padding-right: 15px;
    padding-bottom: 190px;
}

.mejores-proyectos h2{
    color: white;
    font-family: "Montserrat";
    font-weight: 300;
    font-size: 60px;
    margin-top: 24%;
}

@media(max-width: 400px){
    .mejores-proyectos h2{
        font-size: 45px;
    }
}


.mejores-proyectos p{
    color: white;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 18px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.mejores-proyectos button{
    background: none!important;
    border: none !important;
    background-color: #8ddd49 !important;
    color: white !important;
    text-shadow: none !important;
    width: auto;
    /*padding-bottom: 36px;*/
    pading-left: 30px;
    padding-right: 30px;
    border-radius: 50px;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1);
    transition: all 200ms ease-in;
    transform: scale(1);
    font-family: "Myriad Pro";
    font-weight: 200;
    font-size: 20px;
}

.mejores-proyectos button:hover{
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1.1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1.1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1.1);
    transition: all 200ms ease-in;
    transform: scale(1.1);
}

.mejores-proyectos a{
    color:white;
    font-family: "Myriad Pro";
    font-weight: 500;
    font-size: 16px;
}


@media(min-width:994px ){
    .historia-responsive{
        display: none;
    }
}

.historia-responsive{
    margin-top: 80px;
    color:white;
}

.historia-responsive .anios, .historia-responsive .entregados{
    border: 1px solid #00ffeb;
    padding: 10px;
    padding-left: 20px;
    margin-right: 15px;
}

.historia-responsive h2{
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 50px;
}

@media(max-width: 500px){
    .historia-responsive h2 {
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 40px;
    }

    .historia-responsive ul{
        padding-inline-start: 10px;
    }
}

.historia-responsive p, li{
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 16px;
}

.historia-responsive .entregados p:first-child{
    margin-bottom: 0px;
    font-size: 30px;
    line-height: 25px;
    margin-top: 20px;

}
.historia-responsive .entregados h2{
    margin-bottom: 0px;
    margin-top: 0px;

}

.historia-responsive .items-tareas ul{
    display: flex;
    list-style-type: none;
    margin-left: -2px !important;
    padding: 0 !important;
}

.historia-responsive .items-tareas li{
    margin-left: 5px;
    margin-right: 5px;

}

.historia-responsive .items-tareas li p {
 font-weight: 500;
    font-size: 15px;
}

@media(max-width: 340px){
    .historia-responsive .items-tareas li p {
font-size: 10px !important;
    }
}