.cuentas {
    background-color: #f7fbfe;
    height: 100%;
    min-height: 965px;
}

.cuentas .mleft9 {
    margin-left: 4% !important;
    margin-right: 0 !important;
}


.cuentas .lesspadin {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.cuentas .row {
    margin-right: 0 !important;
}

/*--------------------ESTILO PARA BARRA DE NAVEGACION-----------------*/

.plataforma .navbar {
    margin-bottom: 0 !important;
}

@media screen and (min-width: 768px) {
    .plataforma .navbar {
        display: none;
    }

    .mleft9 {
        margin-top: -40px;
    }
}

@media screen and (max-width: 767px) {
    .mleft9 {
        margin-top: -40px;
    }
}

/*--------------PRINCIPAL---------------*/
.cuentas .logoKit {
    margin: 75px 0 30px 73px;
    width: 81px;
}

.cuentas h1 {
    font-family: "Myriad Pro";
    font-weight: 100;
    color: #00ffea;
    font-size: 300%;
    margin-top: 20px;
    margin-bottom: 30px;
}

.cuentas .flechitaIzquierda {
    float: left;
    margin-right: 20px;
    margin-top: 2px;
}

.cuentas .cuentaAliasEIdentificacion {
    font-family: "Myriad Pro";
    margin-top: 2px;
    color: #00aaeb;
}

.cuentas .iconosHerramientas {
    padding-top: 20px;
    padding-left: 0;
    margin-left: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.cuentas .iconosHerramientas img {
    width: 106px;
    float: left;
    margin-left: auto;
    margin-bottom: 10px;
    background-size: 110%;
    border-radius: 50%;
    background-position: center;
    margin-right: 20px;
}

.cuentas .iconosHerramientas img:hover {
    cursor: pointer;
    border: 7px solid #00a2e5;
    -webkit-transform: scale(1.1);
}

.cuentas .imgLineaVertical {
    float: left;
    margin-right: 45px;
    height: 330px;
}

.cuentas .imgLineaVerticalDer {
    float: right;
    margin-left: 18%;
    height: 614px;
}

/*Perfil*/
.cuentas .opciones h3 {
    padding-top: 30px;
    font-family: "Myriad Pro" !important;
    font-weight: 100;
    color: #00aaeb;
    margin-top: 0;
}

/*Nombre de usuario - empresa*/
.cuentas .opciones #nombre {
    font-family: "Myriad Pro";
    font-weight: 100;
    color: #00aaeb;
    font-size: 1.3em;
}

/*Cerrar sesion y Cambiar Contraseña*/
.cuentas .menu, .menu li a {
    margin-top: 35px;
    list-style: none;
    font-family: "Myriad Pro" !important;
    font-weight: 100 !important;
    color: black !important;
    font-size: 1.05em;
}

/*Formulario para subir Logo*/
.cuentas .logo-empresa form {
    text-align: -webkit-right;
    text-align: -moz-right;
    display: -moz-box;
}

.cuentas .logo-empresa img {
    display: inline;
    margin-top: 20px;
    cursor: pointer;
    max-width: 95px;
}

/*Boton Upload Logo*/
.cuentas .logo-empresa .botones-upload {
    position: relative;
    /*display: table-caption;*/
}

.cuentas .logo-empresa .btn-default {
    margin: 0 !important;
    background: none !important;
    border: none !important;
    max-width: 100px;
}

.cuentas .logo-empresa .examinar {
    float: right;
    border: 2px solid white !important;
    box-shadow: none !important;
    border-radius: 40px;
    color: white !important;
    text-shadow: none !important;
    padding-top: 2px;
    font-family: "Myriad Pro";
    font-weight: 100;

}

.cuentas .logo-empresa .subir {
    border: 2px solid #00ffea !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-radius: 87px;
    color: #00ffea !important;
    padding-top: 2px;
    padding-bottom: 1px;
    text-shadow: none !important;
    /* padding-top: 2px; */
    font-family: "Myriad Pro";
    font-weight: 100;
    cursor: pointer;
    display: inherit;
    position: relative;
    top: 10px;
    outline: none !important;
    /* left: 5px; */
}

.cuentas .logo-empresa .botones-upload .examinar:focus, .cuentas .logo-empresa .botones-upload .subir:focus {
    outline: none !important;
}

.cuentas .logo-empresa .botones-upload > input[type="file"] {
    position: absolute;
    font-size: 2px;
    top: 20px;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.cuentas .logo-empresa .botones-upload > .examinar {
    display: inline-block;
}

#cuentas p {
    color: #00ffeb;
    font-family: "Myriad Pro";
    font-weight: 200;
    font-size: 120%;
}

/*responsive logos y titulos izquierda*/
@media (max-width: 618px) {
    #cuentas h1 {
        font-size: 38px;
    }

    #cuentas .cuentaAliasEIdentificacion {
        font-size: 20px;
        margin-top: 4px;
    }

    .cuentas .logoKit {
        width: 75px;
        margin: 80px 0 10px 30px;
    }

    .cuentas .iconosHerramientas img {
        width: 96px;
    }

    .cuentas .logoKit {
        /*width: 45px;*/
    }
}

@media (max-width: 450px) {
    #cuentas h1 {
        font-size: 33px;
    }

    #cuentas .cuentaAliasEIdentificacion {
        font-size: 15px;
        margin-top: 7px;
    }

    .cuentas .logoKit {
        width: 65px;
    }

    .cuentas .iconosHerramientas img {
        width: 86px;
    }

    .cuentas .logoKit {
        /*width: 40px;*/
    }
}

@media screen and (max-width: 350px) {
    #cuentas .cuentaAliasEIdentificacion {
        font-size: 14px;
    }

    .cuentas .imgLineaVertical {
        margin-right: 20px;
    }

    .cuentas .logoKit {
        width: 60px;
    }
}

/*responsive  perfil y titulos derecha*/
@media screen and (max-width: 1100px) {
    .cuentas .opciones h3 {
        font-size: 18px;
    }

    .cuentas #nombre {
        font-size: 16px;
    }

    .cuentas .menu .page-scroll {
        font-size: 14px;
    }
}

@media screen and (max-width: 850px) {
    .cuentas .opciones h3 {
        font-size: 15px;
    }

    .cuentas #nombre {
        font-size: 1.05em;
    }

    .cuentas .menu .page-scroll {
        font-size: 1em;
    }
}

