.landing {
    background-color: white;
    width: 100%;
    min-height: 1000px;
    height: fit-content;
    padding: 20px 50px 0 50px;
    color: #00aaeb;
}

.landing input {
    outline: none;
}

.landing .recuadro {
    padding-top: 0 !important;
}

.landing .borderCeleste {
    border-top: 2px solid #00FFEA;
    border-bottom: 1px solid #00FFEA;
    padding-top: 20px;
}

.landing .subtitulo {
    font-weight: 700 !important;
    margin-top: 80px;
    margin-bottom: 30px;
    font-family: "Myriad Pro";
    font-size: 20px;
}

.landing .subtitulo .label {
    font-size: 13px;
    font-weight: 200;
    padding-top: 7px;
    margin-left: 10px;
    display: inline;
    padding: 0.2em 0.6em 0.3em;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
}

.landing .subtitulo {
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 20px;
    color: #00aaeb;
}

.landing .recuadro p {
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 20px;
    color: #00aaeb;
}

.landing .recuadro .inputLanding {
    font-family: "Myriad Pro";
    font-weight: 100;
    color: #00aaeb;
    line-height: 56px;
    font-size: 20px;
    width: 100%;
    background: none;
    border: none;
    border-top: 1px solid #00ffea;
    border-bottom: 1px solid #00ffea;
    padding-left: 15px;
    height: 60px;
}

.landing .formLanding p {
    font-family: "Myriad Pro" !important;
    font-size: 16px !important;
    font-weight: 100 !important;
    margin-top: 7px;
}

.landing .formLanding fieldset {
    border-bottom: 1px solid #04ffea;
    border-top: 1px solid #04ffea !important;
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 18px;
    padding-bottom: 15px;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.landing .formLanding legend {
    color: #00aaeb;
    font-family: "Myriad Pro";
    font-weight: 200;
    border: none;
    font-size: 17px;
    width: inherit !important;
    padding-right: 15px;
    margin-bottom: 5px;
}

.landing .formLanding fieldset p {
    display: inline;
    padding-left: 8px;
    padding-right: 31px;
    top: 2px;
    position: relative;
    font-weight: 100 !important;
}

/*Tabla Landing*/
.landing .parrafoInvisible {
    background-color: #E2EAED !important;
    color: #E2EAED !important;
}

.landing .kit-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    overflow-y: hidden;
    mask-image: linear-gradient(to right, transparent, white 15px, white 90%, transparent);
    position: relative;
}

@media (max-width: 715px) {
    .landing .kit-container {
        mask-image: linear-gradient(to right, transparent, white 10px, white 89%, transparent);
    }
}

/*.landing .kit-container::-webkit-scrollbar {*/
/*    display: none;*/
/*}*/

.landing .items-kit-container {
    display: flex;
    flex-direction: row;
    float: left;
    padding-bottom: 15px;
    padding-top: 15px;
}

.landing .item-kit {
    border: 1px solid white;
    width: 260px;
    border-radius: 5px;
    padding: 15px;
    padding-bottom: 8px;
    margin: 5px;
    box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.23);
    cursor: pointer;
}

.landing .item-invisible {
    border: 1px solid #E2EAED;
    min-width: 240px;
    border-radius: 5px;
    padding: 15px;
    padding-bottom: 8px;
    margin: 5px;
    box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.23);
    /*background-color: #E2EAED;*/
    height: 300px;

    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: ease;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    /*height: 96px;*/
    position: relative;

}

.landing .item-kit.selected {
    border: 1px solid #00a0ef;
    padding-bottom: 2px;
    border-bottom-width: 10px;
}

.landing .item-checkradio {
    text-align: right;
}

.landing .item-img {
    height: 100px;
}

.landing .item-img img {
    width: 100%;
}

.landing .item-cuerpo h5 {
    min-height: 60px;
    font-family: "Myriad Pro";
}

.landing .item-precio {
    font-family: "Montserrat";
    font-size: 27px;
    font-weight: 200;
    text-align: right;
    padding-bottom: 7px;
}

.landing .item-kit:first-child {
    margin-left: 25px;
}

.landing .item-kit:last-child {
    margin-right: 25px;
}

.landing .formLanding .imagenTabla {
    width: 100px;
    margin: 0 auto;
    display: block;
}

.landing .formaDePago {
    border: none;
    background-color: #00ffc452;
    height: 38px;
    border-radius: 4px;
    margin-left: 15px;
    outline: none;
}


.landing .inputImagen {
    display: flex;
    flex-wrap: nowrap;
}

.landing .inputImagen input {
    border: none !important;
}

.landing input[type="file"] {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}

.landing .textoContacto {
    line-height: 32px;
    padding-top: 10px;
    padding-left: 10px;
    font-size: 18px;
    font-family: "Myriad Pro";
    font-weight: 100;
    width: 100%;
    background-color: #00AEEF;
    background: none;
    border: none;
    border-bottom: 2px solid #00ffea;
    border-top: 1px solid #00ffea;
}

.landing .inputImagen img {
    max-height: 45px;
}

.landing button {
    margin-top: 50px;
    background: none !important;
    border: none !important;
    background-color: #00aaeb !important;
    color: white !important;
    text-shadow: none !important;
    width: auto;
    padding-bottom: 36px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 50px;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 20px;
    -webkit-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
}

.landing button.actualizar-cotiz {
    padding-bottom: 0;
    margin: 0;
    box-shadow: 3px 2px 5px #00000059;
    padding-top: 2px;
    outline: none;
    margin-left: 10px;
}

.landing .button:hover {
    -webkit-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    outline: none;
}

.landing .input-row {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    margin-top: 25px;
}

.landing label {
    color: #00aaeb;
    font-family: "Myriad Pro";
    font-weight: 200;
    border: none;
    font-size: 17px;
}

.landing [type="radio"]:checked + span:before {
    background: #00aded;
    box-shadow: 0 0 0 0.13em #00ffea;
}

.landing input.invalid {
    box-shadow: 0 0 0 0.13em #ff2f0052 !important;
}

.landing [type="radio"].invalid + span:before{
    border: 0.125em solid #ff2f0052;
    box-shadow: 0 0 0 0.15em #ff2f0052;
}

.landing [type="radio"] + span:before {
    border-width: 1px;
}

.landing [type="radio"] + span:before {
    cursor: pointer;
}

.containerLugarEntrega {
    display: flex;
    flex-direction: column-reverse;
}

.landing .inputResumen {
    background-color: #00ffc452;
    border: none;
    border-radius: 3px;
    padding-bottom: 4px;
    padding-top: 6px;
    padding-left: 9px;
    outline: none;
    color: #00aaea;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 16px;
    margin-left: 10px;
    width: 100px;
}


.landing .inputDolar {
    background-color: transparent;
    padding-left: 0;
    margin-left: 2px;
}

.landing .resumenPrecio ul li {
    margin-top: 15px;
    margin-bottom: 15px;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 16px;
}

.landing .resumenPrecio ul li:first-child {
    margin-top: 0px;
}

.landing .resumenPrecio ul li label {
    font-size: 20px;
    font-weight: 500;
    margin-top: 25px;
}


.landing .resumenPrecio input[type="checkbox"] {
    margin-left: 10px;
}

.landing .resumenPrecio ul li label.instalacionChk {
    font-weight: 100;
    font-size: 15px;
    padding-left: 5px;
}

.landing .resumenPrecio ul li .inputResumen:disabled {
    background-color: #827d7d33;
}

.landing .columnaProductosKit td {
    vertical-align: middle!important;
    padding: 5px!important;
}

.landing .tablaResumen.table{
    font-size: 10px;
}

.landing td.tableColumnCantidad {
    text-align: center;
}

.landing .tablaResumen th {
    padding: 5px;
}
@media screen and (max-width: 700px) {
    .landing {
        padding: 20px 0 0 0;
    }

    .landing .formLanding .imagenTabla {
        width: 70px;
    }
}