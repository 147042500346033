.sliderMarca .owl-theme {
    border-bottom: 2px solid #00ffea;
    background-color: white;
}

.sliderMarca .owl-nav {
    width: 100%;
    position: absolute;
    margin-top: -139px !important;
    padding-left: 23px;
    padding-right: 23px;
}

.sliderMarca .owl-carousel .owl-stage{
    display: flex;
}

.sliderMarca .owl-theme .owl-nav [class*=owl-] {
    width: 30px; /*or your image's width*/
    height: 100px; /*or your image's height*/
    background-repeat: no-repeat !important;
    background-color: transparent !important;
    border-radius: none;
}

.sliderMarca .owl-theme .owl-next {
    background-image: url("../img/marcas/next.png") !important;
    float: right;
    color: transparent;
}

.sliderMarca .owl-theme .owl-prev {
    background-image: url("../img/marcas/prev.png") !important;
    float: left;
    color: transparent;
}

.sliderMarca .owl-theme .item {
    width: 100%;
    text-align: center;
    max-width: 180px;
    margin: 0 auto;
    vertical-align: middle;
    align-items: center;
    align-self: center;
    padding: 24px;
}

.sliderMarca .owl-carousel.owl-drag .owl-item {
    display: flex;
}
.sliderMarca .owl-theme .item:before {
    content: ' ';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
}

.sliderMarca .owl-theme .item img {
    display: inline-block;
    text-align: center;
    margin: auto auto;
    /*width: auto;*/
    /*height: auto;*/
    max-width: 150px;
    vertical-align: middle;
}

.sliderMarca .owl-theme .owl-dots .owl-dot:last-child {
    display: none;
}

@media (max-width: 869px) {
    .sliderMarca .owl-theme .item img {
        max-width: 130px;
    }
}

@media (max-width: 600px) {
    .sliderMarca .owl-theme .item img {
        max-width: 80px;
    }

    .sliderMarca .owl-theme .owl-next {
        display: none;
    }

    .sliderMarca .owl-theme .owl-prev {
        display: none;
    }
}

.padding-kit{
    padding:49px 95px 95px !important;
}

.padding-sinKit{
    padding:88px 95px 95px;
}

@media(max-width: 1100px){
    .padding-kit{
        padding:29px 75px 75px !important;
    }

    .padding-sinKit{
        padding:58px 45px 45px;
    }

    .sliderMarca .owl-nav {
        display: none;
    }
}

@media(max-width: 600px){
    .padding-kit{
        padding:76px 45px 45px !important;
    }

    .padding-sinKit {
        padding: 38px 25px 25px;
    }

    .sliderMarca .owl-nav {
        display: none;
    }
    .sliderMarca .owl-theme .item {
        height: 170px;
    }
}


@media(max-width: 570px){
    .padding-kit{
        padding:58px 45px 45px !important;
    }

}

@media(max-width: 400px){
    .padding-kit{
        padding:51px 32px 23px !important;
    }

    .sliderMarca .owl-theme .item {
        height: 120px;
    }
}

@media(max-width: 768px){
    .padding-kit{
        padding:79px 75px 75px !important;
    }
}

@media screen and (max-width: 767px) {
    .sliderMarca .item {
        height: 150px !important;
        padding-top: 35px !important;
    }
}