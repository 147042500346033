.detallesPedido {
    background-color: white;
    width: 100%;
    padding: 0 50px 50px 50px;
    color: #00aaeb;
    min-height: 57vh;
}

.detallesPedido .titulo {
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
}

.detallesPedido .titulo h3 {
    color: #00aaeb;
    margin-bottom: 20px;
    float: left;
}

.detallesPedido .titulo p {
    float: left;
    padding-top: 10px;
    cursor: pointer;
    margin-left: 5px;
}

.detallesPedido .cuadroDatosPedido {
    border: 1px solid #00aaeb;
    padding: 0 20px 20px 20px;
}

.detallesPedido .datosPedido {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
}

.detallesPedido .datosPedido .item, .detallesPedido textarea {
    margin-right: 20px;
}

.detallesPedido .datosPedido .observacion {
    height: 120px;
}

.detallesPedido input, .detallesPedido select, .detallesPedido .datosPedido label, .detallesPedido textarea {
    color: #00a0ef;
}

.detallesPedido input[type=date]::-webkit-inner-spin-button,
.detallesPedido input[type=date]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.detallesPedido input[type=date] {
    -moz-appearance: textfield;
}

.detallesPedido select {
    background-color: transparent;
    border: 1px solid #00aaeb;
    padding: 5px 20px;
    color: #00aaeb;
    width: 350px;
    height: fit-content;
}

.detallesPedido input {
    background-color: transparent;
    border: 1px solid #00aaeb;
    padding: 15px 10px;
    color: #00aaeb;
    height: 20px;
    width: 200px;
}

.detallesPedido textarea {
    width: 700px;
    height: 100px;
}

.detallesPedido .textoTipoEntrega {
    color: #00a0ef;
}

/*TABLA*/
.detallesPedido table {
    border-right: 1px solid #00ffea;
    border-bottom: 3px solid #00aaeb;
}

.detallesPedido .tableHead {
    border-top: 3px solid #00aaeb;
    border-bottom: 3px solid #00aaeb;
}

.detallesPedido .tableHead th {
    font-family: Montserrat;
    font-weight: 300;
    font-size: 10px;
    border-left: 1px solid #00ffea;
    text-align: center;
    color: #00aaeb;
    text-transform: uppercase;
    padding: 10px;
}

.detallesPedido .columnaProductos td {
    border-left: 1px solid #00ffea;
    border-bottom: 2px solid #00aaeb;
    border-top: 2px solid #00aaeb;
    text-align: center;
    font-size: 14px;
    color: #00aaeb;
    padding: 10px;
}

.detallesPedido .borderAzul {
    border-top: 2px solid #00aaeb !important;
}

.detallesPedido .imagenTabla {
    width: 100px;
    height: 100px;
    border-top: 1px solid #00ffeb;
    border-bottom: 7px solid #00ffeb;
}

.detallesPedido .kit td {
    border-bottom: 0 !important;
    border-top: 0 !important;
}

.detallesPedido .kit td h5 {
    margin-top: 0;
    margin-bottom: 0;
}

.detallesPedido .verMas {
    font-size: 11px;
}

.detallesPedido .primera {
    border-top: 2px solid #00aaeb !important;
}

.detallesPedido .columnaProductos td {
    border-left: 1px solid #00ffea;
    border-bottom: 2px solid #00aaeb;
    border-top: 2px solid #00aaeb;
    text-align: center;
    font-size: 14px;
    color: #00aaeb;
    padding: 10px;
}

.detallesPedido .columnaProductos td:first-child {
    padding-left: 0;
    padding-right: 0;
    padding-top: 20px;
}

.detallesPedido .columnaProductos td:last-child {
    border-right: 1px solid #00ffea;
}

.detallesPedido .columnaDetalles td {
    border-right: 1px solid #00ffea;
    border-left: 1px solid #00ffea;
    text-align: center;
}

.detallesPedido .btn-default {
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    text-shadow: none;
    background-color: #00aaeb;
    border-radius: 150px;
    font-size: 25px;
    font-family: "Montserrat";
    font-weight: 400;
    color: white;
    /*margin-top: 50px;*/
    padding-left: 60px;
    padding-right: 60px;
    -webkit-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    outline: none;
    font-family: "Myriad Pro";
    font-weight: 100;
}

.detallesPedido .btn-default:hover {
    background-color: #00aaeb;
    color: white;
    -webkit-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    outline: none;
}

/*Responsive datos pedido*/
@media (max-width: 732px) {
    .detallesPedido .datosPedido {
        justify-content: left;
    }
    .detallesPedido select {
        width: 200px;
    }

    .detallesPedido input {
        width: 200px;
    }

    .detallesPedido textarea {
        width: 200px !important;
    }
}

/*Responsive datos pedido*/
@media (max-width: 581px) {
    .detallesPedido .datosPedido {
        justify-content: center;
    }
    .detallesPedido select {
        width: 250px !important;
    }

    .detallesPedido input {
        width: 250px !important;
    }

    .detallesPedido textarea {
        width: 250px !important;
    }
}
