.headerHerramienta{
    background-color: #00aeef;
    margin-top: 0;
    padding-top: 30px;
}

/*header*/
.headerHerramienta div {
    margin-bottom: 30px;
    font-family: "Myriad Pro";
}

.headerHerramienta div img {
    cursor: pointer;
    float: left;
    margin: 45px 0 0 75px;
    width: 50px;
}

@media screen and (max-width: 500px) {
    .headerHerramienta div img {
        margin: 45px 0 0 45px;
        width: 40px;

    }
}

.headerHerramienta p, .headerHerramienta h1 {
    float: right;
    color: white;
    text-align: right;
    font-weight: 100;
    font-size: 44px;
    margin-top: 20px;
}

.headerHerramienta .textoBienvenida p {
    font-size: 15px;
    max-width: 550px;
}

/*responsive header*/
@media screen and (max-width: 1000px) {
    .headerHerramienta h1 {
        font-size: 50px;
    }
}

@media screen and (max-width: 600px) {
    .headerHerramienta h1 {
        font-size: 40px;
    }
}