.evento{
    padding-bottom: 100px;
}

.evento .header{
    padding-left: 15px;
    padding-right: 15px;
}

@media(max-width: 679px){
    .evento .header img:first-child{
        max-width: 138px;
    }
    .evento .header img:last-child{
        max-width: 80px;
    }
}



.evento .banner{
    margin-top: 50px;
    margin-bottom: 100px;
    border-top: 2px #8ed635 solid;
    border-bottom: 2px #8ed635 solid;

}

.evento .banner img{
    width: 110%;
}

.evento label{
    font-family: "Myriad Pro";
    font-weight: 100;
    color:white;
    font-size: 22px;
}

.evento input, .evento select{
    background: none !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    border: none;
    border-top:1px solid #00ffeb;
    border-bottom:1px solid #00ffeb;
    box-shadow: none!important;
    color:white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-bottom: 30px;
    font-family: "Myriad Pro";
    font-size: 16px;
    font-weight: 100;
    height: 48px;
    width: 100%;
}

.evento select option{
    color: black;
}
.evento .form-control:focus {
    border-color: #00ffeb !important;
    outline: 0;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
}

.evento span.help-block{
    color:#00ffeb;
    font-family: "Montserrat";
    font-weight: 200;
    font-size: 20px;
}

@media(max-width: 783px ){
    .evento span.help-block{
        font-size: 13px;
    }
}

.evento .datos{
    margin-top: 30px;
}

.evento Button{
    background: none;
    box-shadow: none;
    border: none;
    text-shadow: none;
    background-color: #9ae235;
    border-radius: 150px;
    font-size: 25px;
    font-family: "Montserrat";
    font-weight: 400;
    color:#00aaeb;
    margin-top: 50px;
    padding-left: 60px;
    padding-right: 60px;
    -webkit-transition: ease-in 100ms;
    -moz-transition: ease-in 100ms;
    -ms-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    outline: none;
}

.evento .btn-default:hover, .evento .btn-default:focus {
    background-color: #9ae235;
    color:#00aaeb;
    -webkit-transition: ease-in 100ms;
    -moz-transition: ease-in 100ms;
    -ms-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
    outline: none;
}

.evento .registro-error{
    text-align: center;
    margin:15px auto;
    display: block;
    font-family: "Montserrat";
    font-weight: 100;
    font-size: 20px;
    color:#00ffeb;

}