.documentacion h1 {
    color: white;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 10.8em;
    line-height: 135px;
    margin-top: 70px;
}

.documentacion h1 b {
    color: white;
    font-family: "Myriad Pro";
    font-weight: 700;
    line-height: 135px;
    margin-top: 70px;
    font-size: inherit;
    text-decoration: none;
}

.documentacion h3 {
    margin-top: 60px;
}

@media (max-width: 768px) {
    .documentacion h3 {
        margin-top: 70px;
    }
}


.documentacion h2 {
    padding-top: 15px;
    color: black;
    text-align: center;
    background-color: white;
    margin-bottom: 35px;
    margin-top: 0;
}

.documentacion .container-limit {
    padding-left: 20px;
    padding-right: 20px;
}

.documentacion h4 {
    font-weight: 200;
}

@media (max-width: 768px) {
    .documentacion h1 {
        font-size: 6em;
        line-height: 0;
    }

    .documentacion h2 {
        font-size: 25px;
        font-weight: 200;
        padding-bottom: 10px;
    }
}

@media (max-width: 422px) {
    .documentacion h1 {
        font-size: 4.5em;
        line-height: 0px;
    }
}

.documentacion .seccion-blanca {
    margin-top: 70px;
    background-color: white;
    background-image: url('../img/nuevoLocal/cuadroSup.png');
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
    background-size: 1200px;
    color: #00aaeb;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 28px;
    /*padding-top: 100px;*/
    padding-bottom: 100px;
    border-bottom: 3px solid #5cffe2;

}

@media (max-width: 400px) {
    .documentacion .seccion-blanca {
        font-size: 18px;

    }
}

.documentacion p {
    margin-bottom: 0;
    color: black;
    font-size: 16px;
}

.documentacion b {
    color: black;
    font-size: 20px;
    font-weight: 200;
    text-decoration: underline;
    text-decoration-color: #08aaea;
}

.documentacion .ul-styless {
    list-style: none;
}

.documentacion ul li::marker {
    font-size: 20px;
}

.documentacion .footer {
    background-color: black;
    color: white;
}


.documentacion .footer a {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: 40px;
    display: inherit;
    font-family: "Myriad Pro";
    font-weight: 500;
}

@media (max-width: 400px) {
    .documentacion .footer a {
        font-size: 20px;
    }
}

.documentacion p.advertencia {
    font-weight: 400;
    color: white;
    background-color: #00aaea;
    max-width: fit-content;
    padding: 16px 19px;
    margin-top: 21px;
}

.documentacion .container-flex {
    display: flex;
}

.documentacion .seccion-forma-pago {
    margin-bottom: 100px;
}

.documentacion .container-imagen-instructivo {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

@media (max-width: 768px) {
    .documentacion .container-flex {
        flex-wrap: wrap;
    }

    .documentacion .container-imagen-instructivo {
        padding-left: 15px;
        width: 100%;
        justify-content: space-evenly;
        flex-direction: row;
    }
}

.documentacion .imagen-instructivo {
    width: 180px;
}

.documentacion .container-imagen-instructivo img {
    width: 100%;
    object-fit: contain;
    margin-top: 45px;
}

@media (max-width: 768px) {
    .documentacion .container-imagen-instructivo img {
        margin-top: 0;
    }
}

.sticky-wrapper {
    position: relative;
    height: 9rem; /* We need to change this value */
    background-color: white;
}

@media (max-width: 768px) {
    .sticky-wrapper {
        height: 4rem;
    }
}

.sticky .sticky-inner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    padding-right: 20px;
    padding-left: 20px;
}

.documentacion .indice a {
    font-size: 19px;
}

.documentacion .indice{
    min-height: 80vh;
}