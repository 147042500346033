.producto-detalle [class*="col"] {
    padding-left: 25px;
    padding-right: 25px;
}

.producto-detalle {
    padding-bottom: 70px;
}

.producto-detalle .image-gallery-slide-wrapper {
    background-color: white;
}

.producto-detalle h1 {
    font-family: "Montserrat";
    font-weight: 300;
    color: white;
    font-size: 32px;
    line-height: 43px;
    overflow: hidden;
}

.producto-detalle .codigo {
    color: white;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 18px;
}

.producto-detalle .precio {
    float: right;
}

.producto-detalle .precio p {
    color: white;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 12px;
    margin-bottom: 0px;
    position: absolute;
    right: 0;

}

@media (max-width: 769px) {
    .producto-detalle .precio h2 {
        color: #00aeef;
        background-color: #00ffea;
        font-family: "Montserrat";
        font-weight: unset;
        display: table-cell;
        padding: 6px 10px;
        position: absolute;
        right: 0px;
        top: -3px;
        min-width: 190px;
        text-align: center;
    }
}

@media (min-width: 769px) {
    .producto-detalle .precio h2 {
        color: #00aeef;
        background-color: #00ffea;
        font-family: "Montserrat";
        font-weight: unset;
        display: table-cell;
        padding: 6px 10px;
        position: absolute;
        right: 0px;
        top: -3px;
        min-width: 190px;
        text-align: center;
    }
}

.producto-detalle .instalador {
    background-color: #8ddd49;
    padding-left: 10%;
    padding-top: 17px;
    padding-bottom: 12px;
}

.producto-detalle .instalador h4 {
    color: white;
    font-family: "Myriad Pro";
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 0;
}

.producto-detalle .instalador p {
    color: #00aeef;
    font-family: "Myriad Pro";
    padding-top: 6px;
    font-size: 1.04em;
    line-height: 16px;
}

.producto-detalle .instalador sb {
    text-decoration: underline;
}

.producto-detalle .instalador img {
    margin-top: 6px;
}

.producto-detalle .descripcion {
    margin-top: 40px;
}

@media screen and (max-width: 991px) {
    .producto-detalle .descripcion {
        margin-top: 70px;
    }

    .producto-detalle h1 {
        font-size: 25px;
    }
}

.producto-detalle .descripcion p {
    color: white;
    font-family: "Myriad Pro";
    font-weight: 100;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 18px;
}

.producto-detalle .banner img {
    margin: 51px auto;
    width: 100%;
}

.producto-detalle .breadcrumb {
    padding-left: 25px;
}

@media screen and (max-width: 667px) {
    .breadcrumb {
        font-size: 12px;
    }
}

.dudas {
    background-color: #00ffea;
    padding: 100px 0;
}

.dudas h3 {
    color: #00aeef;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 30px;
    text-align: center;
}

.dudas h3 b {
    font-family: "Montserrat";
    font-weight: 500;
}

.contenedorDudas {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.contenedorDudas .item {
    align-self: center;
}

@media screen and (max-width: 991px) {
    .contenedorDudas img {
        width: 40px;
    }

    .contenedorDudas h3 {
        font-size: 24px;
    }
}

.otros-productos {
    margin-top: 70px;
}

.otros-productos .grid{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.contenedorOtros {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.otros-productos h2.titulo {
    color: white;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
    margin-left: 23px;
}

.vermas {
    text-align: center;
    margin-top: 30px;
}

.vermas a {
    color: #00ffea;
    text-decoration: underline;
    font-family: "Montserrat";
    font-weight: 100;
    font-size: 20px;
}

.producto-detalle .esIntegrador {
    bottom: 0;
    position: absolute;
}

.image-gallery-slides {
    border: 2px solid #00ffea;
    border-bottom: 4px solid #00ffea;
}

.image-gallery-thumbnail.active {
    border: 2px solid #00ffea !important;
    border-bottom: 4px solid #00ffea !important;
}

.image-gallery-thumbnail {
    border: 2px solid #00ffea !important;
}

.image-gallery-slide {
    background: transparent !important;
}

.image-gallery-slide img {
    width: 100%;
    margin: 0 auto;
    display: block;
    max-width: 400px;
    padding: 40px;
}

.producto-detalle .image-gallery-thumbnails {
    padding-top: 5px !important;
    padding-bottom: 0 !important;
}

span svg {
    max-height: 80px;
}

.marca svg path {
    fill: white;
}

.marca svg polygon {
    fill: white;
}

.padding-svg {
    max-width: 218px;
    padding-left: 30px !important;
}

.producto-detalle .esIntegrador img {
    width: 100%;
    max-height: 100px;
    cursor: pointer;
}

.producto-detalle .esIntegradorXS img {
    cursor: pointer;
}

.producto-detalle .precioTachado {
    font-size: 17px !important;
    font-weight: 200 !important;
}

.producto-detalle .itemMarca .precioVigente {
    font-size: 25px !important;
    margin-top: 23px;
}

.producto-detalle .textoPrecioFlex .precioTachado {
    text-decoration: line-through;
    white-space: nowrap;
}

/*descuento*/
.producto-detalle .descuento {
    width: 45px !important;
    background-color: #8ddd49;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    position: absolute;
    right: 45px;
    top: 20px;
}

.producto-detalle .descuento img {
    width: 20px !important;
    padding: 10px 0 10px 0;
    margin-left: 10px;
}

.producto-detalle .descuento p {
    margin-bottom: 4px;
    text-align: center;
    padding-bottom: 10px;
    color: white;
    font-weight: bold;
    z-index: 1 !important;
}

@media screen and (max-width: 991px) {
    .producto-detalle h1 {
        margin-top: 0;
    }
}

/*Titulo y codigo responsive*/
@media screen and (max-width: 650px) {
    /*titulo y codigo*/
    .producto-detalle h1 {
        font-size: 20px;
    }

    .producto-detalle .codigo {
        font-size: 12px;
    }

    .producto-detalle .breadcrumb li a, .producto-detalle .breadcrumb li span {
        font-size: 13px;
    }
}

@media screen and (max-width: 550px) {
    .producto-detalle h1 {
        font-size: 18px;
    }

    .producto-detalle .codigo {
        font-size: 11px;
    }

    .producto-detalle .breadcrumb li a, .producto-detalle .breadcrumb li span {
        font-size: 11px;
    }
}

@media screen and (max-width: 460px) {
    .producto-detalle h1 {
        font-size: 19px;
    }

    .producto-detalle .codigo {
        font-size: 11px;
    }

    .producto-detalle .breadcrumb li a, .producto-detalle .breadcrumb li span {
        font-size: 10px;
    }
}

@media screen and (max-width: 420px) {
    .producto-detalle h1 {
        font-size: 17px;
    }

    .producto-detalle .codigo {
        font-size: 10px;
    }
}

.noMostrar {
    display: none;
}

/*TABLA KIT*/
.producto-detalle .tablaResumen th {
    font-size: 20px;
}

.producto-detalle .columnaProductosKit td {
    font-size: 17px;
}

.producto-detalle .tableColumnNombre {
    padding-left: 30px;
}

.producto-detalle .tableColumnCantidad {
    padding-left: 30px;
}

@media screen and (max-width: 1150px) {
    .producto-detalle .tablaResumen th {
        font-size: 18px;
    }

    .producto-detalle .tablaResumen td {
        font-size: 15px;
    }
}

@media screen and (max-width: 767px) {
    .producto-detalle .descripcion .table-responsive {
        border: none;
    }

    .otros-productos .container-producto h2 {
        font-size: 13px !important;
    }
}

@media screen and (max-width: 570px) {
    .producto-detalle .tableColumnNombre {
        padding-left: 30px;
    }
}


/* Primer columna*/
.producto-detalle .columnaInfoProductos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.producto-detalle .itemTitulo {
    order: 1;
    flex-grow: 1;
}

.producto-detalle .itemMarca {
    order: 2;
    flex-grow: 3;
}

.producto-detalle .itemMarca .verPrecio {
    padding-right: 0;
    cursor: pointer;
}

.producto-detalle .itemMarca .verPrecio img {
    float: right;
    max-height: 40px;
}

.producto-detalle .marca h2 {
    font-size: 40px !important;
}

.producto-detalle .itemMarcaXS .verPrecioXS img {
    float: right;
}

.producto-detalle .esIntegrador {
    order: 3;
    flex-grow: 3;
    width: 90%;
    margin: 0 auto;
}

.producto-detalle .itemMarca {
    display: flex;
    flex-direction: row;
}

/*MODAL*/
.modal-content {
    border-radius: 0;
    background-color: white;
}

.modalBodyPD {
    padding: 5px !important;
}

.modalRegistroDetalle .modal-content {
    margin-top: 80px;
}

.modalRegistro {
    background-color: #00aeef;
    padding: 30px 40px;
}

.modalRegistro h3, .modalRegistro p {
    color: white;
    margin-top: 0;
    margin-bottom: 0;
    text-decoration-skip-ink: none;
    background-image: none;
    font-family: "Myriad Pro";
}

.modalRegistro p {
    font-weight: 100;
    margin-bottom: 15px;
}
.modalRegistro h3 {
    padding-bottom: 10px;
}

.modalRegistro a {
    text-align: left;
    margin-top: 10px;
    color: white;
    text-decoration: underline;
    font-family: "Myriad Pro";
    font-weight: 100;
}

.modalRegistro .textoModalRegistro {
    text-align: center;
}

.modalBodyPD input {
    font-family: "Myriad Pro";
    font-weight: 100;
    color: white;
    line-height: 56px;
    font-size: 20px;
    width: 100%;
    background-color: #00AEEF;
    background: none;
    border: none;
    border-bottom: 2px solid #00ffea;
}

.modalBodyPD input:focus {
    outline: none;
}

.modalBodyPD input::-webkit-input-placeholder, .modalBodyPD textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: white;
}
.modalBodyPD input::-moz-placeholder, .modalBodyPD textarea::-webkit-input-placeholder { /* Firefox 19+ */
    color: white;
}
.modalBodyPD input:-ms-input-placeholder, .modalBodyPD textarea::-webkit-input-placeholder { /* IE 10+ */
    color: white;
}
.modalBodyPD input:-moz-placeholder, .modalBodyPD textarea::-webkit-input-placeholder { /* Firefox 18- */
    color: white;
}

.botonEntrar {
    background: none!important;
    border: none !important;
    background-color: #8ddd49 !important;
    color: #00AEEF !important;
    text-shadow: none !important;
    width: auto;
    padding: 5px 60px;
    margin-left: 30px;
    border-radius: 50px;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1);
    transition: all 200ms ease-in;
    transform: scale(1);
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 20px;
    float: right;
    order: 2;
}

.botonRegistroModal {
    float: right;
    margin-top: 5px;
    background-color: transparent !important;
    color: #8ddd49 !important;
    border: 0 solid transparent;
    text-shadow: none !important;
    background-image: none !important;
    text-decoration: underline;
    box-shadow: none !important;
    order: 1;
    font-family: "Myriad Pro";
    font-weight: 400;
}

.botonRegistroModal:hover {
    border: none;
    text-decoration: underline;
}

.contenedorBotones {
    margin-top: 20px;
}

@media screen and (max-width: 405px) {
    .botonEntrar, .botonRegistroModal {
        display: block;
        margin: 0 auto;
    }

    .contenedorBotones {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

/* DISEÑO RESPONSIVE menor a 576*/
.producto-detalle .datosProducto {
    display: flex;
    flex-direction: row;
}

.producto-detalle .itemMarcaXS {
    display: none;
}

.producto-detalle .itemMarcaXS .verPrecioXS {
    padding-right: 0!important;
}

.producto-detalle .esIntegradorXS {
    display: none !important;
}

@media screen and (max-width: 600px) {
    .producto-detalle .itemMarca .verPrecio img {
        max-height: 35px;
    }
}

@media screen and (max-width: 576px) {
    .producto-detalle .datosProducto {
        display: inline-block;
    }

    .producto-detalle .itemMarca {
        display: none;
    }

    .producto-detalle .esIntegrador {
        display: none !important;
    }

    .producto-detalle .itemMarcaXS {
        display: block;
        margin: 20px 0px !important;
    }

    .producto-detalle .esIntegradorXS {
        display: block !important;
    }

    .producto-detalle .esIntegradorXS img {
        width: 100%;
        margin: 0 auto;
    }

    .producto-detalle .datosProducto .columnaFoto {
        margin-bottom: 0 !important;
    }

    .producto-detalle .itemMarcaXS .marca h2 {
        font-size: 35px !important;
    }
}

@media screen and (max-width: 430px) {
    .producto-detalle .itemMarcaXS .verPrecioXS img {
        width: 110px;
        height: 100%;
    }

    .producto-detalle .itemMarcaXS .marca svg {
        max-height: 35px;
        width: 100%;
    }

    .producto-detalle .itemMarcaXS .marca h2 {
        font-size: 25px !important;
    }
}