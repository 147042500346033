/*AUTOSUGGEST USUARIOS*/
/*.autosuggest-usuarios .autoSuggestUsuarios {*/
/*    margin-bottom: 25px;*/
/*    border: 1px solid #00ffeb;*/
/*    padding-top: 25px;*/
/*    padding-bottom: 30px;*/
/*}*/

/*.autosuggest-usuarios .autoSuggestUsuarios p {*/
/*    color: #00aaeb;*/
/*}*/
.autosuggest-usuarios {
    position: relative;
}

.autosuggest-usuarios .botonCancelarBusquedaUsuario {
    position: absolute;
    right: 30px;
    top: 13px;
}

/*.autosuggest-usuarios .autoSuggestUsuarios .react-autosuggest__input {*/
/*    background: transparent;*/
/*    outline: none;*/
/*    border: none;*/
/*    font-family: "Myriad Pro";*/
/*    font-weight: 100;*/
/*    font-size: 17px;*/
/*    padding-top: 10px;*/
/*    padding-bottom: 10px;*/
/*    padding-left: 20px;*/
/*    color: #00aaeb;*/
/*    width: 100%;*/
/*    -webkit-transition: all 0.5s ease;*/
/*    -moz-transition: all 0.5s ease;*/
/*    -o-transition: all 0.5s ease;*/
/*    transition: all 0.5s ease;*/
/*    margin-top: 5px;*/
/*}*/

.autosuggest-usuarios .react-autosuggest__container {
    margin-right: 0;
    float: left;
    width: 100%;
}

.autosuggest-usuarios hr {
    background-color: #00aaeb;
    border-color: #00aaeb;
    height: 1px;
    margin: 0 27% 0 20px;
}


.autosuggest-usuarios .react-autosuggest__suggestions-list {
    list-style: none;
    position: absolute;
    z-index: 5;
    background-color: white;
    height: 250px;
    overflow-y: auto;
    box-shadow: 3px 3px 10px grey;
    width: 80%;
}

.autosuggest-usuarios .usuarioAutosuggest {
    line-height: 25px;
    margin-left: 20px;
    font-size: 15px;
    cursor: pointer;
    color: #00aaeb;
}

.autosuggest-usuarios .usuarioAutosuggest .highlight {
    color: black;
}

.autosuggest-usuarios .hrUsuarios {
    margin: 0 0 0 -200px !important;
}

.autosuggest-usuarios .dniCod {
    font-size: 13px;
}
