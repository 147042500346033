.productos2 {
    /*padding-top: 70px;*/
    padding-bottom: 50px;
    border-bottom: 2px solid #00ffea;
    background-image: url("../img/bg-producto2.png");
    background-repeat: no-repeat;
    background-position: right;
    background-attachment: fixed;
}

.productos2 .item .filaProductos {
    margin-left: 20px !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.productos2 h2{
    color:white;
    font-family: "Myriad Pro";
    font-weight: 200;
    font-size: 19px;
    max-width: 230px;
}
.productos2 .subCategoria, .productos2 .marcas {
    list-style-type: none;
    padding: 0;
    color: white;
}

.productos2 ul li{
    padding-top: 5px;
    padding-bottom: 5px;
}
.productos2 .marcas a, .productos2 .subCategoria a {
    color:white !important;
}

.productos2 h3{
    padding-top: 0px;
    text-align: left;
}
.productos2 .tit-producto{
    text-align: left !important;
    margin-bottom: 30px;
    margin-top: 10px;
}

.productos2 .container-producto{
    margin-bottom: 30px;
}
.productos2 .container-producto img{
    max-width: 150px !important;
    margin:0 auto;
}

.productos2 .container-producto .container-precio p:first-child {
    padding-top: 0px;
}

.productos2 .dscPrecio{
    padding-top: 80%;
    font-size: 20px;
}
.productos2 .container-producto [class*="col"]{
    padding-left: 0;
    padding-right: 0;
}


.productos2 .owl-theme .owl-dots .owl-dot span {
    background-color: transparent;
    border: 1px solid #00ffea;
}

.productos2 .owl-theme .owl-dots .owl-dot.active span, .productos2 .owl-theme .owl-dots .owl-dot:hover span {
    background-color: #00ffea !important;
}

.entrega img{
    margin: 0 auto;
    margin-top: 90px;
    margin-bottom: 70px;
}

.panelCategoria a{
    line-height: 23px;
}

.panelSubCategoria{
    background-color: transparent;
    color: white;
    border-color: transparent;
    box-shadow: none;
}

.categoriaMenu a{
    text-decoration: none;
}

.categoriaMenu .active {
    background-image: none !important;
    background-color: transparent !important;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    border-bottom: 2px solid #00ffea;
    padding-bottom: 1px;
}

.panel-heading {
    margin-bottom: 10px;
}

.panel-heading a:hover, .panel a:hover, .panel-body a:hover{
    text-decoration: none;
}

.panel-heading a a, .panel-heading a {
    color: white;
}

.marcas .active, .panel .active {
    background-image: none !important;
    background-color: transparent !important;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    border-bottom: 3px solid #00ffea;
    padding-bottom: 2px;
}

@media(max-width: 768px){
    .productos2 .dscPrecio {
        padding-top: 2px;
    }
}

.productos2 .panel, .productos2 ul li {
    background-color: transparent !important;
}

.panel-default > .panel-heading  {
    background: none;
    border-color: transparent;
    color: white !important;
}

.productos2 .panel-group {
    margin-bottom: 0;
}

.productos2 .panel-heading {
    padding: 0;
}

.productos2 .panel {
    border-color: transparent;
    box-shadow: none;
}

.productos2 .subCategoria li a{
    color: white !important;
}

.productos2 .panel-body {
    padding: 0 0 0 20px;
}

.productos2 .panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: transparent;
}

.productos2 .panel .panel-body li {
    width: fit-content;
    margin-bottom: 5px;
    padding-bottom: 2px;
}

.productos2 .panel-body {
    max-height: 300px;
    overflow-y: auto;
}

@media screen and (max-width: 767px) {
    .productos2 .item .row {
        margin-left: 0 !important;
    }
}

/*BARRA DE CATEGORIAS*/
@media screen and (min-width: 768px) {
    .barraCategorias {
        display: none !important;
    }
}

.barraCategorias {
    min-height: 72px;
    width: 100% !important;
}


.botonCategoria img {
    margin: 20px 15px 20px 0;
}

.botonCategoria p {
    margin-right: 10px;
    padding-top: 10px;
    color: #00ffea;
}

.barraCategorias .menuCategorias {
    width: 40%;
    height: 100vh;
    padding: 40px 30px 50px 30px;
    background-color: #00aaeb;
    border-left: 2px solid #00ffea;
    float: right;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 100;
}

.barraCategorias .menuCategorias p {
    color: white;
    font-family: "Myriad Pro";
    font-weight: 200;
    font-size: 19px;
}

.barraCategorias .tituloCategorias {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.barraCategorias .tituloCategorias img {
    width: 24px;
    height: 24px;
}