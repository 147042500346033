.boton-pago {
    font-family: "Myriad Pro";
}

.boton-pago .container-presupuesto {
    width: 80vw;
    background-color: white;
    border-radius: 20px;
    margin: auto;
    border: 2px solid #00aeef;
    margin-bottom: 70px;
}

@media screen and (max-width: 550px) {
    .boton-pago .container-presupuesto {
        width: 100vw;
    }
}

.boton-pago .container-presupuesto .tags ul {
    color: #00aeef;
    list-style: none;
    display: flex;
    font-weight: 100;
    font-size: 25px;
    padding: 0px;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
}

@media (max-width: 768px) {
    .boton-pago .container-presupuesto .tags ul {
        font-size: 18px;
    }
}

.boton-pago .container-presupuesto .tags ul.disabled li {
    cursor: not-allowed;
}

.boton-pago .container-presupuesto .tags ul li {
    width: 50%;
    list-style: none;
    text-align: center;
    border-right: 2px solid #00aeef;
    border-bottom: 2px solid #00aeef;
    padding: 20px 10px;
    background-color: #ddd;
    color: #adadad;
    cursor: pointer;
}

.boton-pago .container-presupuesto .tags ul li:nth-child(1) {
    border-top-left-radius: 20px;
}

.boton-pago .container-presupuesto .tags ul li:last-child {
    border-top-right-radius: 20px;
    border-right: none;
}

.boton-pago .container-presupuesto .tags ul li.active {
    border-bottom: 2px solid white;
    background-color: white;
    color: #00aeef;
}

/*Formulario*/

.container-formulario {
    padding: 20px 45px;
}

@media screen and (max-width: 550px) {
    .container-formulario {
        padding: 10px 15px;
    }
}


.container-formulario h2 {
    color: #00aaeb;
    font-family: "Myriad Pro";
    font-weight: 200;
}

.container-formulario p {
    color: #00aaeb;
    font-family: "Myriad Pro";
    font-weight: 200;
}

.boton-pago .input-row {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.boton-pago label {
    color: #00aaeb;
    font-family: "Myriad Pro";
    font-weight: 200;
    border: none;
    font-size: 17px;
}

@media (max-width: 768px) {
    .boton-pago label {
        font-size: 12px;
    }
}

.boton-pago input, textarea, select {
    font-family: "Myriad Pro";
    font-weight: 100;
    color: #00aaeb;
    line-height: inherit;
    font-size: 20px;
    width: 100%;
    background: none;
    border: none;
    border: 1px solid #00ffea;
    padding-left: 15px;
    height: 50px;
    outline: none;
}

.boton-pago input:disabled, textarea:disabled, select:disabled {
    opacity: 0.7;
    border-color: rgba(118, 118, 118, 0.3);
}

.boton-pago textarea {
    height: 200px;
}

.boton-pago input::placeholder, textarea::placeholder, select::placeholder {
    color: #00aaeb;
}

/*.landing [type="radio"]:checked + span:before {*/
/*    background: #00aded;*/
/*    box-shadow: 0 0 0 0.13em #00ffea;*/
/*}*/

/*.landing input.invalid {*/
/*    box-shadow: 0 0 0 0.13em #ff2f0052 !important;*/
/*}*/

/*.landing [type="radio"].invalid + span:before{*/
/*    border: 0.125em solid #ff2f0052;*/
/*    box-shadow: 0 0 0 0.15em #ff2f0052;*/
/*}*/

/*.landing [type="radio"] + span:before {*/
/*    border-width: 1px;*/
/*}*/

/*.landing [type="radio"] + span:before {*/
/*    cursor: pointer;*/
/*}*/

.boton-pago input[type=checkbox] {
    transform: scale(1.5);
}

.boton-pago input[type=checkbox] {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    cursor: pointer;
    font-size: 17px;
    visibility: hidden;
    line-height: inherit;
    font-weight: 100;
}

.boton-pago input[type=checkbox]:after {
    content: " ";
    background-color: #fff;
    display: inline-block;
    margin-left: 10px;
    padding-bottom: 5px;
    color: #00BFF0;
    width: 17px;
    height: 17px;
    visibility: visible;
    border: 3px solid #00ffea;
    background-color: #00ffea;
    padding-left: 3px;
    border-radius: 5px;
}

.boton-pago input[type=checkbox]:checked:after {
    background: #00aded;
}


.boton-pago input[type=radio] {
    transform: scale(1.5);
    clip: inherit;
    position: inherit;
}

.boton-pago input[type=radio] {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    cursor: pointer;
    font-size: 17px;
    visibility: hidden;
    line-height: inherit;
    font-weight: 100;
}

.boton-pago input[type=radio].disabled {
    cursor: not-allowed;
}

.boton-pago input[type=radio]:after {
    content: " ";
    background-color: #fff;
    display: inline-block;
    margin-left: 10px;
    padding-bottom: 5px;
    color: #00BFF0;
    width: 17px;
    height: 17px;
    visibility: visible;
    border: 3px solid #00ffea;
    background-color: #00ffea;
    padding-left: 3px;
    border-radius: 100%;
}

.boton-pago input[type=radio].disabled:after {
    border: 3px solid #d8d8d8;
    background-color: #d8d8d8;
}

.boton-pago input[type=radio]:checked:after {
    background: #00aded;
}


.boton-pago .item-checkradio {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    cursor: pointer;
}

.boton-pago .item-checkradio label {
    cursor: pointer;
}

.boton-pago .item-checkradio span {
    color: #00aded;
    margin-left: 3px;
}

.boton-pago .colores {
    display: flex;
    flex-wrap: nowrap;
}

.boton-pago .colores div {
    flex-grow: 1;
}

.boton-pago .colores input {
    padding: 0;
}

/*Logo*/
.boton-pago .logo {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.boton-pago .logo img {
    height: 200px;
    width: 250px;
    border: 1px solid #00aaeb;
    margin-bottom: 15px;
    object-fit: contain;
}

.logo.personalizar {
    width: fit-content !important;
}

.boton-pago .logo.personalizar img {
    height: 180px;
    width: 180px;
    border: 1px solid #00aaeb;
    margin-bottom: 15px;
    object-fit: cover;
    border-radius: 100%;
}

.boton-pago button {
    background-color: #00ffea;
    padding: 10px 20px;
    color: #00aaeb;
    font-family: "Myriad Pro";
    border: none;
    border-radius: 10px;
    font-weight: 200;
    outline: none;

}

/*TABLA*/

.boton-pago .tableHead {
    border-top: 3px solid #00aaeb;
    border-bottom: 3px solid #00aaeb;
}

.boton-pago .tableHead th {
    font-family: Montserrat;
    font-weight: 300;
    font-size: 10px;
    border-left: 1px solid #00ffea;
    text-align: center;
    color: #00aaeb;
    text-transform: uppercase;
    padding: 10px;
}

.boton-pago .tableHead th:last-child {
    border-right: 1px solid #00ffea;
}

.boton-pago .columnaDetalles:last-child {
    border-bottom: 3px solid #00aaeb;
}

.boton-pago .columnaDetalles td {
    border-left: 1px solid #00ffea;
    border-bottom: 2px solid #00aaeb;
    border-top: 2px solid #00aaeb;
    text-align: center;
    font-size: 14px;
    color: #00aaeb;
    padding: 10px;
}

.boton-pago .columnaDetalles td:last-child {
    border-right: 1px solid #00ffeb;
}

.boton-pago .primera {
    border-top: 2px solid #00aaeb !important;
}

.boton-pago .kit td {
    border-bottom: 0px solid #00aaeb;
    border-top: 0px solid #00aaeb;
    padding-top: 5px;
    padding-bottom: 5px;
}

.boton-pago .kit td p {
    margin-bottom: 2px;
}

.boton-pago .kit td h5 {
    margin-top: 0;
    margin-bottom: 0;
}

.boton-pago .imagenTabla {
    width: 100px;
    height: 100px;
    border-top: 1px solid #00ffeb;
    border-bottom: 7px solid #00ffeb;
}

.boton-pago .verMas {
    font-size: 11px;
}

/*Precio Modificado*/
.boton-pago .precioModificadoTD {
    width: 150px !important;
}

.boton-pago .contenedorModificado {
    display: flex;
    flex-direction: row;
}

.boton-pago .contenedorModificado p {
    padding-top: 3px;
    margin-right: 3px;
}

.boton-pago .inputModificado {
    color: #00aaeb;
    text-align: center;
    border: 1px solid #00aaeb;
    border-radius: 0;
    height: 25px;
    padding-left: 20px;
    min-width: 120px;
}

.boton-pago input[type=number]::-webkit-outer-spin-button,
.boton-pago input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.boton-pago input[type=number] {
    -moz-appearance: textfield;
}

.boton-pago .moneda {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid #00aaeb;
    border-radius: 0;
    padding: 15px;
    margin-top: 20px;
}

.boton-pago .moneda p {
    color: #00aaeb;
    margin: 0;
}

.boton-pago .botonera {
    display: flex;
    justify-content: space-between;
    margin: 40px;
}

.boton-pago .botonera-izq {
    display: flex;
}

.boton-pago .botonera-der {
    display: flex;
}

.boton-pago .boton-redondo {
    border-radius: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.boton-pago .boton-redondo.outline img {
    border-radius: 100%;
    border: 1px solid #00ffea;
    height: 55px;
    width: 55px;
    padding: 10px;
}

.boton-pago .boton-redondo img {
    margin-bottom: 10px;

}

.boton-pago .boton-default {
    background-color: #00ffea;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    font-weight: 100;
    font-family: "Myriad Pro", sans-serif;
    color: #00a0ef;
    border-radius: 25px;
    margin-right: 10px;
    min-width: 81px;
}


.boton-pago button[disabled] {
    opacity: 0.7;
    border-color: rgba(118, 118, 118, 0.3);
}

.boton-pago .boton-default img {
    border-radius: 100%;
    border: 1px solid #00ffea;
    height: 20px;
    width: 20px;
}

.boton-pago .boton-default img {
    margin-bottom: 5px;
    margin-right: 3px;
}

.boton-pago .modal-token {
    background-color: white;
    position: fixed;
    top: 30%;
    left: 15%;
    width: 70vw;
    border: 2px solid #00a0ef;
    font-family: "Myriad Pro", sans-serif;
    padding: 20px;
    border-top-left-radius: 20px;
    box-shadow: 7px 7px 15px #0000004f;
}

@media (max-width: 768px) {
    .boton-pago .modal-token {
        width: 98%;
        left: 4px;
    }
}

.boton-pago .modal-token h2 {
    color: #00a0ef;
    font-weight: 100;
}

.boton-pago .modal-token input {
    background-color: #00a0ef;
    color: white;
}

.boton-pago .lineaTiempo {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
}

.boton-pago .lineaTiempo div {
    background-color: white;
    border-radius: 100%;
    width: 37px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.boton-pago .lineaTiempo div.active {
    background-color: #00ffea;
}

.boton-pago .lineaTiempo div.reject {
    background-color: #FDEDED;
}

.boton-pago .lineaTiempo div img {
    width: 70%;
    height: 70%;
}

.boton-pago .titulo {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

.boton-pago .medios-pago .header {
    padding: 10px;
    margin: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    cursor: pointer;
    align-items: center;
}

.boton-pago .medios-pago .header h3 {
    margin-left: 3px;
    color: #00a0ef;
    font-family: "Myriad Pro", sans-serif;
    font-weight: 100;
}

.boton-pago .medios-pago .header span {
    margin-left: 3px;
    color: #00a0ef;
}

.boton-pago .medios-pago .body {
    margin-left: 25px;
}

.boton-pago i {
    font-family: "Myriad Pro", sans-serif;
    font-weight: 100;
    color: #00a0ef;
}

.boton-pago .mensaje {
    font-family: "Myriad Pro", sans-serif;
    font-weight: 100;
    color: #c9302c;
    display: none;
}

.boton-pago .mensaje.visible {
    display: block;
}

.boton-pago.monitor-pagos .container-presupuesto {
    width: inherit;
    border: none;
}

.boton-pago.monitor-pagos .container-presupuesto a {
    all: unset;
}