.productos-destacados{
    border-top: 2px solid #00ffea;
    padding-top: 20px;
    text-align: center;
    background-color: #00aff2;
}

.productos-destacados .grid{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;
}

.productos-destacados h2.titulo{
    color: white;
    font-family: "Montserrat" ;
    font-weight: 300;
    font-size: 20px;
    letter-spacing: 1px;
    margin-top: 40px;
    margin-bottom: 60px;
}