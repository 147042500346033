.register-portada {
    background-image: url("../img/bg-registrar.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100vh - 150px);
    border-bottom: 2px solid #00ffea;
    min-height: 500px;
    border-top: 2px solid #00ffea;
}

@media (min-width: 769px) {
    .register-portada .recuadro {
        position: relative;
        top: 12vh;
        padding-left: 120px;
        padding-top: 50px;
    }
}

@media (max-width: 769px) {
    .register-portada .recuadro {
        position: relative;
        top: 40px;
        padding-left: 20px;
    }
}

@media (min-width: 769px) {
    .register-portada .recuadro small {
        color: white;
        font-family: "Myriad Pro";
        font-weight: 100;
        font-size: 15px;
        margin: 0;
        padding-bottom: 50px;
    }
}

@media (max-width: 769px) {
    .register-portada .recuadro small {
        color: white;
        font-family: "Myriad Pro";
        font-weight: 100;
        margin: 0;
        padding-bottom: 50px;
    }
}

@media (min-width: 769px) {
    .register-portada .recuadro h1 {
        color: white;
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 100px;
        line-height: 76px;
        margin: 0;
        padding-bottom: 30px;
        white-space: nowrap;
    }
}

@media (max-width: 769px) {
    .register-portada .recuadro h1 {
        color: white;
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 30px;
        margin: 0;
        padding-bottom: 30px;
    }
}

@media (min-width: 769px) {
    .register-portada .recuadro p {
        font-family: "Myriad Pro";
        font-weight: 100;
        font-size: 20px;
        color: white;
    }
}

@media (max-width: 769px) {
    .register-portada .recuadro p {
        font-family: "Myriad Pro";
        font-weight: 100;
        font-size: 15px;
        color: white;
    }
}

.register-tipoUsuario .recuadro{
    padding-top: 100px;
    border-bottom: 2px solid #00ffea;
    padding-bottom: 100px;
}

.register-portada .recuadro .separador-verde {
    width: 90px;
    height: 7px;
    background-color: #9ae235;
    margin-top: 60px;
}

.register-tipoUsuario .tipoUsuario{
    border: 2px solid #9ae235;
    margin-bottom: 15px;
    min-height: 711px;
}

.tipoUsuario .nombre{
    color: white;
    text-align: center;
    border-bottom: 2px solid #9ae235;
    padding: 15px 10px;
    min-height: 106px;
}

.tipoUsuario .nombre img {
    float:left;
    margin-left: 20px;
    margin-top: 7px;
}
.tipoUsuario .nombre h2{
    /*font-family: "Montserrat" !important;*/
    font-weight: 900 !important;
    font-size: 20px !important;
    text-align: left;
    float: left;
    margin-left: 30px;
}

.tipoUsuario .nombre .textoCorporativo {
    margin-top: 30px;
}

.tipoUsuario .nombre .textoInstalador {
    margin-top: 10px;
}

.tipoUsuario .nombre .textoConsumidorFinal {
    margin-top: 15px;
}

/*responsive nombres*/
@media (max-width: 1121px) {
    .tipoUsuario .nombre h2 {
        font-size: 20px!important;
    }

    .tipoUsuario .nombre img {
        width: 50px;
    }

    .tipoUsuario .nombre .textoCorporativo {
        margin-top:20px;
    }
}

@media (max-width: 977px) {
    .tipoUsuario .nombre h2 {
        font-size: 20px!important;
    }

    .tipoUsuario .nombre img {
        width: fit-content;
    }

    .tipoUsuario .nombre .textoCorporativo {
        margin-top:30px;
    }
}

@media (max-width: 400px) {
    .tipoUsuario .nombre h2 {
        font-size:15px !important;
    }

    .tipoUsuario .nombre .textoConsumidorFinal {
        margin-top: 20px;
    }

    .tipoUsuario .nombre .textoInstalador {
        margin-top: 15px;
    }
}

.tipoUsuario .descripcion{
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 17px;
    color: white;
    padding: 25px 15px 10px 10px;
    min-height: 516px;
}

.tipoUsuario .descripcion li{
    padding-bottom: 9px;
}

.tipoUsuario .soyYo{
    cursor: pointer;
    color: #9ae235;
    font-family: "Montserrat";
    font-weight: 100;
    font-size: 15px;
    text-align: center;
    border-top: 2px solid #9ae235;
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-box-shadow: 0px 0px 46px -8px rgba(74,73,74,1);
    -moz-box-shadow: 0px 0px 46px -8px rgba(74,73,74,1);
    box-shadow: 0px 0px 46px -8px rgba(74,73,74,1);
}

.tipoUsuario .soyYo h2 {
    font-weight: 900;
    font-family: sans-serif;
    font-size: 20px;
    padding: 7px;
    cursor: pointer;
}

.register-tipoUsuario .tipoUsuario.seleccionado {
    background-color: #9ae235;
}

.register-tipoUsuario .tipoUsuario.seleccionado .nombre{
     color: #00aeef;
    border-bottom: 2px solid #00aeef;
 }

.register-tipoUsuario .tipoUsuario.seleccionado .descripcion{
    color: #00aeef;
}

.register-tipoUsuario .tipoUsuario.seleccionado .soyYo{
    color: #00aeef;
    border-top: 2px solid #00aeef;
}


.register-tipoUsuario button{
    margin-top: 50px;
    background: none!important;
    border: none !important;
    background-color: #8ddd49 !important;
    color: #00AEEF !important;
    text-shadow: none !important;
    width: auto;
    padding-bottom: 36px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 50px;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1);
    transition: all 200ms ease-in;
    transform: scale(1);
    font-family: “Myriad Pro”;
    font-weight: 100;
    font-size: 20px;
}

.register-tipoUsuario button:hover{
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1.1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1.1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1.1);
    transition: all 200ms ease-in;
    transform: scale(1.1);
}

.botoneraRegistrarse{
    text-align: center;
    padding-top: 30px;
}

button.registrate{
    border: 1px solid #8ddd49;
    border-radius: 200px;
    background-color: #8ddd49;
    color: #00aeef !important;
    font-family: "Montserrat" !important;
    font-weight: 100 !important;
    height: 28px;
    padding-top: 1px;
    margin-left: 15px !important;
    margin-right: 15px !important;
}