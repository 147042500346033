@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}
.animated-background, .animated-backgroundLista {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    height: 189px;
    position: relative;
}

.animated-backgroundLista{
    height: 393px;
}

.animated-backgroundLista .text-loader{
    background-color: white;
    height: 70px;
    width: 100%;
    margin-top: 52px;
}
.loader-bg{
    position: relative;
    height: 100%;
    overflow:hidden;
}
.loader-bg::before{
    content:'';
    position:absolute;
    bottom:7%;
    left: 33%;
    width:70px;
    height:70px;
    border-radius:100%;
    box-shadow: 0px 300px 0px 600px white;
}

.text-loader{
    background-color: white;
    height: 21px;
    width: 100%;
    margin-top: 41px;
}