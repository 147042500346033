

.preguntas .header{
    padding-left: 15px;
    padding-right: 15px;
}

@media(max-width: 679px){
    .preguntas .header img:first-child{
        max-width: 138px;
    }
    .preguntas .header img:last-child{
        max-width: 80px;
    }
}



.preguntas .banner{
    margin-top: 50px;
    margin-bottom: 50px;
    border-top: 2px #8ed635 solid;
    border-bottom: 2px #8ed635 solid;

}

.preguntas .banner img{
    width: 110%;
}

.preguntas label{
    font-family: "Myriad Pro";
    font-weight: 100;
    color:white;
    font-size: 22px;
}
@media(max-width: 588px){
    .preguntas label{
     font-size: 18px;
    }
}
.preguntas input{
    background: none !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    border: none;
    border-top:1px solid #00ffeb;
    border-bottom:1px solid #00ffeb;
    box-shadow: none!important;
    color:white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-bottom: 30px;
    font-family: "Myriad Pro";
    font-size: 16px;
    font-weight: 100;
    height: 48px;
    width: 100%;
}

.preguntas .form-control:focus {
    border-color: #00ffeb !important;
    outline: 0;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
}

.preguntas span.help-block{
    color:#00ffeb;
    font-family: "Montserrat";
    font-weight: 200;
    font-size: 20px;
}

@media(max-width: 783px ){
    .preguntas span.help-block{
        font-size: 13px;
    }
}


.preguntas .boton Button{
    background: none;
    box-shadow: none;
    border: none;
    text-shadow: none;
    background-color: #8ed635;
    border-radius: 150px;
    font-size: 25px;
    font-family: "Montserrat";
    font-weight: 400;
    color:#00aaeb;
    margin-top: 70px;
    padding-left: 30px;
    padding-right: 30px;
    -webkit-transition: ease-in 100ms;
    -moz-transition: ease-in 100ms;
    -ms-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    outline: none;
}

.preguntas .boton .btn-default:hover, .preguntas .boton .btn-default:focus {
    background-color: #8ed635;
    color:#00aaeb;
    -webkit-transition: ease-in 100ms;
    -moz-transition: ease-in 100ms;
    -ms-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
    outline: none;
}

.preguntas .boton-juga Button{
    background: none;
    box-shadow: none;
    border: 1px solid #00ffeb;
    text-shadow: none;
    background-color: #00aaeb;
    border-radius: 0px;
    font-size: 50px;
    font-family: "Montserrat";
    font-weight: 400;
    color: #00ffeb;
    margin-top: 30px;
    height: 151px;
    box-shadow: 4px 4px 9px #0000006b;
    padding-left: 60px;
    padding-right: 60px;
    -webkit-transition: ease-in 100ms;
    -moz-transition: ease-in 100ms;
    -ms-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    outline: none;
}

.preguntas .boton-juga .btn-default:hover, .preguntas .boton-juga .btn-default:focus {
    background-color: #00aaeb;
    color:#00ffeb;
    -webkit-transition: ease-in 100ms;
    -moz-transition: ease-in 100ms;
    -ms-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    outline: none;
}

.preguntas .boton-juga p{
    color: white;
    text-align: center;
    max-width: 290px;
    margin: 20px auto;
    display: block;
    font-family: "Myriad Pro";
    font-weight: 100;
}

.preguntas .registro-error{
    text-align: center;
    margin:15px auto;
    display: block;
    font-family: "Montserrat";
    font-weight: 100;
    font-size: 20px;
    color:#00ffeb;

}
.preguntas .premios{
    margin-top: 90px;
}
.preguntas .premios h3{
    text-align: left;
    margin: 10px 6px;
}

.preguntas h3{
    color: #00ffeb;
    font-family: "Montserrat";
    font-weight: 200;
    font-size: 25px;
    text-align: center;
    margin: 25px auto;
    display: block;

}

@media(max-width: 588px) {
    .preguntas h3{
        font-size: 15px;
    }
}


.preguntas .select-tema{
    margin-top: 50px;
    padding-bottom: 40px;
}
@media(max-width: 500px){
    .preguntas .select-tema{
        margin-top: 0px;
    }
}
.preguntas .select-tema p{
    font-family: "Myriad Pro" !important;
    font-weight: 100 !important;
    padding-left: 15px;
}
.preguntas .select-tema button{
    margin-left: 0px !important;
    margin-right: 0px !important;
    background: none !important;
    color: #00FFEA !important;
    font-family: "Myriad Pro"!important;
    border-radius: 0px !important;
    font-weight: 100;
    text-shadow: none!important;
    font-size: 20px;
    /*padding-top: 13px;*/
    padding-bottom: 0px;
    width: 100%;
    border: 1px solid #00FFEA;
    text-align: left;
    height: 57px;
    display: flex;
    align-items: center;
    padding-right: 69px;
    white-space: normal;
}
@media(max-width: 1276px){
    .preguntas .select-tema button{
        font-size: 17px;
    }
}
@media(max-width: 1134px){
    .preguntas .select-tema button{
        font-size: 15px;
    }
}
@media(max-width: 1042px){
    .preguntas .select-tema button{
        font-size: 14px;
    }
}
@media(max-width: 889px){
    .preguntas .select-tema button{
        font-size: 16px;
    }
}
@media(max-width: 380px){
    .preguntas .select-tema button{
        font-size: 12px;
    }
}
.preguntas .select-tema .dropdown-menu{
    width: 100%;
    position: absolute;
    top: -1px;
    border-radius: 0px !important;
    margin: 0!important;
    padding: 0 !important;
    box-shadow: none;
    border: 1px solid #00FFEA;
    height: 100%;
    background-color: #00aaeb !important;
    top: 56px;
}
.preguntas .select-tema .btn-default:hover{
    border: 1px solid #00FFEA !important;
    box-shadow: none !important;
}
.preguntas .select-tema .btn-group.open .dropdown-toggle{
    box-shadow: none !important;
    border: 1px solid #00FFEA !important;
}
.preguntas .select-tema .open > .dropdown-menu{
    display: inline-table !important;
}
.preguntas .select-tema ul{
    background: #00aaeb !important;
}

.preguntas .select-tema li{
    border-bottom: 1px solid #00FFEA;
}

.preguntas .select-tema li a{
    color: #00FFEA !important;
    font-family: "Myriad Pro" !important;
    font-weight: 100 !important;
    font-size: 20px;
    padding-top: 8px;
    padding-bottom: 8px;

}
.preguntas .dropdown-menu > li > a{
    white-space: normal;
    min-height: 59px;
    vertical-align: middle;
    display: flex;
    align-items: center;
}
.preguntas .select-tema .dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus{
    background: none !important;
    background-color: transparent !important;
}

.preguntas .select-tema .caret{
    background-image: url("../img/iconLista/expand.png");
    background-repeat: no-repeat;
    height: 48px;
    width: 35px;
    transform: scale(0.6);
    border: none;
    float: right!important;
    position: absolute;
    top: 13px;
    right: 4px;
}

@-moz-document url-prefix() {
    .preguntas .select-tema .caret{
        position: absolute;
        right: 7px;
        top: 13px;
    }
}

.preguntas .select-tema .caret::before{
    position: absolute;
    content: '';
    height: 92px;
    top: -37px;
    margin-left: -28px;
    width: 3px;
    border-left: 2px solid #00FFEA;
}




@media(max-width: 1276px){
    .preguntas .select-tema li a{
        font-size: 17px;
    }
}
@media(max-width: 1134px){
    .preguntas .select-tema li a{
        font-size: 15px;
    }
}
@media(max-width: 1042px){
    .preguntas .select-tema li a{
        font-size: 14px;
    }
}
@media(max-width: 889px){
    .preguntas .select-tema li a{
        font-size: 14px;
    }
}
@media(max-width: 380px){
    .preguntas .select-tema li a{
        font-size: 12px;
    }
}
.preguntas .select-tema .btn-group, .preguntas .select-tema .btn-group-vertical
{
    width: 100%;
    display: block;
}

.preguntas .select-tema .btn-default:focus, .preguntas .select-tema .btn-default.focus{
    border: 1px solid #00FFEA;
    box-shadow: none;
}

.linea-verde{
    height: 2px;
    background-color: #8ed635;
    width: 100%;
    margin: 70px auto;
}

.preguntas .footer{
    margin-top: 90px;
    background-color: #8ed635;

}
.preguntas .footer img{
    display: block;
    margin: 0 auto;
}

.preguntas .list-group-item{
    background-color: transparent;
    color: white;
    font-size: 20px;
    font-family: "Myriad Pro";
    font-weight: 100;
    border: 1px solid #9ae235;
}

.preguntas .list-group-item .usuario{
    color: #00ffeb;
    font-size: 14px;
}