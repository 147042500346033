/*WIFI*/
.signalStrength{
    margin:0 auto;
}
.signalStrength div {
    width: 10px;
    display: inline-flex;
    background-color: #00aaeb;
    margin-left: 1px;
    margin-right: 1px;
    border: 1px solid #00aaeb;
}

.signalStrength .strong-signal {
    height: 26px;

}
.signalStrength .weak {
    height: 18px;
}
.signalStrength .very-weak {
    height: 10px;
}


.ocultarBarrita {
    background-color: rgba(0, 184, 255, 0.16) !important;
}

.mostrarBarrita {
    background-color: #00aaeb;
}