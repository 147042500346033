.monitor-pagos .container-monitor-pagos {
    background-color: white;
    margin-bottom: 100px;
}

.monitor-pagos table {
    margin-top: 20px;
    min-height: 200px;
}

.monitor-pagos table thead {
    margin-top: 20px;
    border-top: 3px solid #00aaeb;
    border-bottom: 3px solid #00aaeb;
}

.monitor-pagos table th {
    padding: 20px 5px 20px 5px !important;
    color: #00aaeb;
}

.monitor-pagos table td {
    padding: 10px 5px 10px 5px !important;
    vertical-align: middle !important;
    color: #00aaeb;
}

.monitor-pagos .parrafoInvisible {
    background-color: #E2EAED;
    color: #E2EAED;
    height: 5px;
}

.monitor-pagos .lineaTiempo {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
}

.monitor-pagos .lineaTiempo div {
    background-color: white;
    border-radius: 100%;
    width: 37px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    cursor: pointer;
}

.monitor-pagos .lineaTiempo div.active {
    background-color: #00ffea;
}

.monitor-pagos .lineaTiempo div.reject {
    background-color: #FDEDED;
}

.monitor-pagos .lineaTiempo div img {
    width: 70%;
    height: 70%;
}

.monitor-pagos .lineaTiempo div img.active {
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: pink;
    z-index: 1;
}

.monitor-pagos .botonera {
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    margin: 10px;
    z-index: 3;
}

.monitor-pagos .botonera-izq {
    display: flex;
}

.monitor-pagos .botonera-der {
    display: flex;
}

.monitor-pagos .botonera-der a {
    all: unset;
}

.monitor-pagos .boton-default {
    border-radius: 30px;
    background-color: #00ffea;
    box-shadow: 3px 3px 10px #00000038;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    font-weight: 100;
    font-family: "Myriad Pro", sans-serif;
    color: #1c2226;
    margin-right: 13px;
    padding: 9px 16px;
    outline: none;
}

.monitor-pagos .boton-default img {
    border-radius: 100%;
    border: 1px solid #00ffea;
    height: 20px;
    width: 20px;
    margin: 4px 3px;
}

.monitor-pagos .paginas {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
    max-width: 800px;
    flex-wrap: wrap;
    text-align: center;
    margin: 20px auto;
}

.monitor-pagos .numeroPagina {
    width: 35px;
    height: 35px;
    color: #333;
    font-weight: 400;
    align-items: center;
    display: flex;
    vertical-align: center;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;
}

.monitor-pagos .numeroPagina.active {
    color: rgba(0, 0, 0, .8);
    background-color: rgba(0, 0, 0, .04);
    font-weight: 600;
}

.monitor-pagos .herramientas {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 15px;
    align-items: center;
}

@media (max-width: 768px) {
    .monitor-pagos .herramientas {
        flex-wrap: wrap-reverse;
        margin-bottom: 0px;
    }

    .monitor-pagos .herramientas .btn-toolbar {
        margin-top: 15px;
    }
}

.monitor-pagos input, textarea, select {
    font-family: "Myriad Pro";
    font-weight: 100;
    color: #00aaeb;
    line-height: inherit;
    font-size: 20px;
    background: none;
    border: none;
    border: 1px solid #00ffea;
    padding-left: 15px;
    height: 50px;
    outline: none;
}

.monitor-pagos .btn-group .btn.btn-default {
    font-family: "Myriad Pro";
    font-weight: 100;
    color: #00aaeb;
    line-height: inherit;
    font-size: 20px;
    background: none;
    border: none;
    border: 1px solid #00ffea;
    padding-left: 15px;
    outline: none;
}

@media (max-width: 768px) {
    .monitor-pagos .btn-group .btn.btn-default {
        font-size: 13px;
    }
}

.monitor-pagos .btn-group .btn.btn-default.active {
    font-family: "Myriad Pro";
    font-weight: 100;
    background-color: #00aaeb;
    color: white;
}

.monitor-pagos input::placeholder, textarea::placeholder, select::placeholder {
    color: #00aaeb;
}

.semi-circulo2 {
    display: inherit !important;
    justify-content: inherit !important;
    align-items: center !important;
    margin-right: 0px !important;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 50% !important;
    height: 100% !important;
    background: #00ffea !important;
    border-radius: 0 100px 100px 0 !important;
    z-index: 0;
}

.monitor-pagos .table-responsive {
    border: none;
}

.monitor-pagos .verLinkPago a {
    all: unset;
    color: white;
    background-color: #00aaee;
    padding: 5px 10px 3px 10px;
    border-radius: 30px;
    cursor: pointer;
    font-family: "Myriad Pro", sans-serif;
    font-weight: 200;
    margin: 10px 0;
}

.monitor-pagos .verLinkPago a:hover {
    background-color: #00aaee;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.12);
}

.imgProducto{
    max-width: 100px;
}