.monitorPedidos {
    background-color: white;
    width: 100%;
    min-height: 1000px;
    height: fit-content;
    padding: 20px 50px 0 50px;
}

@media (max-width: 587px) {
    .monitorPedidos {
        padding: 20px 15px 0 15px;
    }
}

/*SECTION MONITOR*/
.monitorPedidos .sectionMonitor {
    margin-top: 60px;
}

/*TABLA MONITOREO*/
/*Bordes superiores de la tabla*/
.monitorPedidos .tableHead {
    border-top: 3px solid #00aaeb;
    border-bottom: 3px solid #00aaeb;
}

/*Títulos de la tabla*/
.monitorPedidos .tableHead th {
    padding: 20px 0 20px 0;
    color: #00aaeb;
    text-align: center;
}

.monitorPedidos th p {
    /*margin-bottom:0;*/
}

/*Línea celeste de la columna tu precio*/
.monitorPedidos .lineaCeleste {
    width: 20px;
    height: 1px;
    background-color: #00aaeb;
}

/* TH de Precio Clioente*/
.monitorPedidos .precioCliente {
    text-align: left !important;
    padding-left: 10px !important;
}

/*TH de estado*/
.monitorPedidos .tableHead .estado {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80px;
}

.monitorPedidos .tableHead .estado p {
    border-top: 2px solid #00ffea;
    border-bottom: 2px solid #00ffea;
    /*margin: 0 auto;*/
    padding: 3px 0 3px 0;
    margin-right: 2px;
    margin-top: 10px;
    margin-left: 20px;
}

/*Columna de seguimiento*/
.monitorPedidos .tableHead .seguimiento {
    background-color: #00aaeb;
    color: white;
    min-width: 190px;
    border-bottom: 3px solid white;
}

.monitorPedidos .seguimiento span {
    margin-right: 10px;
}

.monitorPedidos .seguimiento span:last-child {
    margin-right: 0;
}

/*Seguimiento XS*/
.monitorPedidos .XS {
    display: none;
}

@media screen and (max-width: 767px) {
    .monitorPedidos .XS {
        display: table-cell;
    }

    .monitorPedidos .LG {
        display: none;
    }
}


/*Tooltip de los datos de seguimiento*/
.tooltip {
    background-color: #00ffeb;
    font-family: "Myriad Pro";
    font-size: 13px;
    font-weight: 100;
    width: fit-content;
    margin-top: -58px;
    margin-left: -23px;
    height: 70px;
    outline: 10px solid #00ffeb;
    border: 1px solid #00aaeb;
}

.tooltip .tooltip-inner {
    background: none !important;
    border-radius: 0;
    color: #00aaeb;
    font-weight: bold;
    margin: 0 !important;
    max-width: 150px !important;
}

.tooltip .tooltip-arrow {
    display: none;
}

.tooltip::after {
    content: '';
    position: absolute;
    bottom: -17px;
    right: -32px;
    background-color: transparent;
    width: 0;
    height: 0;
    border-left: 30px solid #00ffeb;
    border-top: 30px solid transparent;
    transform: rotate(20deg);
}

@media screen and (max-width: 767px) {
    .tooltip {
        margin-top: -68px;
    }

    .tooltip::after {
        border-left: 20px solid #00ffeb;
        border-top: 25px solid transparent;
        left: -25px;
        bottom: -16px;
        transform: rotate(260deg);
    }
}

.tooltip p {
    padding-top: 13px;
    font-family: "Myriad Pro";
    font-weight: 300;
    text-align: left;
    margin-bottom: 0;
    padding-top: 0px;
}

/*Imagenes del seguimiento*/
.monitorPedidos .seguimiento img {
    width: 37px;
}

/*Datos de la tabla*/
.monitorPedidos .parrafoInvisible {
    background-color: #E2EAED;
    color: #E2EAED;
}

.monitorPedidos .tablaMonitor td {
    text-align: center;
    vertical-align: middle;
    padding-left: 5px;
    padding-right: 5px;
}

.monitorPedidos td.razon-social {
    text-align: left !important;
}

.monitorPedidos td.razon-social b {
    max-width: 300px;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    display: inline-block;
    vertical-align: middle;
}

/*Checkbox*/
/* the basic, unchecked style */
.monitorPedidos [type="radio"] + span:before {
    content: '';
    display: inline-block;
    width: 0.7em;
    height: 0.7em;
    vertical-align: -0.25em;
    border-radius: 2.4em;
    border: none;
    background-color: #00ffeb;
    box-shadow: 0 0 0 0.30em #00ffea;
    /*margin-right: 0.75em;*/
    transition: 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28) all;
}

/* the checked style using the :checked pseudo class */
.monitorPedidos [type="radio"]:checked + span:before {
    background: #00aaeb;
    box-shadow: 0 0 0 0.35em #00ffea;
}

/*Boton modificar*/
.monitorPedidos button {
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    text-shadow: none;
    background-color: #00ffeb;
    border-radius: 150px;
    font-size: 11px;
    font-family: "Montserrat";
    font-weight: bold !important;
    text-transform: uppercase;
    color: #00aaeb;
    /*margin-top: 50px;*/
    margin-left: 10px;
    padding: 5px 7px 5px 7px;
    -webkit-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    outline: none;
    font-family: "Myriad Pro";
    opacity: .9;
}

.monitorPedidos .button:hover {
    -webkit-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    outline: none;
}

/*Total y total modificado*/
.monitorPedidos .precio {
    font-weight: 500;
    text-align: left !important;
}

.monitorPedidos td.precio button {
    float: right !important;
}

/*Columna proceso de facturacion*/
.monitorPedidos .procesoFacturacion {
    font-size: 11px;
}

/*Borde celeste de las filas*/
.monitorPedidos td {
    border-bottom: 1px solid #00ffeb;
}

.monitorPedidos .filaVacia td {
    border-bottom: 2px solid #00aaeb;
}

/*Borde blanco de la columna de seguimiento*/
.monitorPedidos tr .seguimiento {
    border-bottom-color: white;
    background-color: #00aaeb;
    color: white;
}

/*Titulo de contacto*/
.monitorPedidos h5 {
    text-align: center;
    color: #00aaeb;
}

.monitorPedidos .verPedido {
    width: 80px;
}

.monitorPedidos .verPedido button {
    width: 50px;
}

@media screen and (max-width: 550px) {
    /*Título de la tabla*/
    .monitorPedidos .tableHead th {
        font-size: 12px;
    }

    .monitorPedidos .tableHead .estado p {
        margin-top: 13px !important;
    }

    /*Info de la tabla*/
    .monitorPedidos tbody tr td {
        font-size: 12px;
    }
}

/*TH de "Estado" de la Tabla*/
@media (max-width: 1026px) {
    .monitorPedidos .tableHead .estado p {
        margin-left: 15px;
        margin-top: 15px;
    }

    .monitorPedidos .tableHead .estado img {
        margin-top: 5px;
    }
}

@media (max-width: 1388px) {
    .monitorPedidos .clientePrecio div {
        text-align: center;
    }
    .monitorPedidos .clientePrecio div button {
        float: none !important;
    }
}

.monitorPedidos .half-circle-spinner {
    width: 30px;
    height: 30px;
}

.monitorPedidos .seguimiento .half-circle-spinner .circle.circle-1 {
    border-top-color: white;
}

.monitorPedidos .seguimiento .half-circle-spinner .circle.circle-2 {
    border-bottom-color: white;
}