/*SECTION ENCABEZADO*/
/*Contenedor de imagen kit e inicio*/
.headerPlataforma {
    padding: 15px 50px 30px 50px;
    background-color: white;
}

.headerPlataforma .inicioKit {
    float: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 25px;
}

.headerPlataforma .contenedorTitulo {
    display: flex;
    flex-direction: row;
    margin: 100px 0 0 25px !important;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.headerPlataforma .contenedorTitulo h1 {
    margin-left: 20px;
    font-family: "Myriad Pro" !important;
    font-weight: 200 !important;
    color: #00aaeb !important;
}

/*Imagenes de kit e inicio*/
.headerPlataforma .inicioKit img {
    width: fit-content;
}

/*Imagen de la palabra inicio*/
.headerPlataforma .inicioKit .inicio {
    margin-top: 40px;
}

/*RESPONSIVE*/
/*Sector superior*/
@media screen and (max-width: 450px) {
    .headerPlataforma .inicioKit {
        margin-left: 0;
    }
    .headerPlataforma .inicioKit img {
        max-width: 50px;
    }
    .headerPlataforma .inicioKit img:last-child {
        max-width: 14px;
    }
}

@media screen and (max-width: 550px) {
    /*Título de Monitor de Pedidos*/
    .headerPlataforma .contenedorTitulo img {
        max-width: 60px;
        max-height: 60px;
    }

    .headerPlataforma .contenedorTitulo h1 {
        font-size: 20px;
    }
}

@media screen and (max-width: 370px) {
    .headerPlataforma {
        padding: 20px 35px 30px 35px;
    }
    .headerPlataforma .contenedorTitulo {
        margin: 100px 0 0 0 !important;
    }
}