@media screen and (min-width: 768px) {
    .barraCategorias {
        display: none !important;
    }
}

.barraCategorias {
    min-height: 72px;
    width: 100% !important;
}

.botonCategoria {
    /*padding-top: 10px;*/
    height: 72px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.barraCategorias .botonCategoria img {
    margin: 20px 15px 20px 0;
    cursor: pointer;
    max-width: 19px;
}

.barraCategorias .botonCategoria p {
    margin-right: 10px;
    padding-top: 10px;
    color: white !important;
    font-weight: bold;
    cursor: pointer;
}

.barraCategorias .menuCategorias {
    width: 60% !important;
    height: 100%;
    padding-top: 40px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #00aaeb;
    border-left: 2px solid #00ffea;
    float: right;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 100;
    overflow-y: scroll;
}

.barraCategorias .menuCategorias p {
    color: white;
    font-family: "Myriad Pro";
    font-weight: 200;
    font-size: 19px;
}

.barraCategorias .tituloCategorias {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.barraCategorias .tituloCategorias img {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.barraCategorias .panel-heading {
    margin-bottom: 0;
    padding: 0;
}

.barraCategorias .panel {
    margin-bottom: 2px;
    background-color: transparent;
    color: white;
    border-color: transparent;
    box-shadow: none;
}

.barraCategorias .panel-collapse {
    padding-top: 10px;
}