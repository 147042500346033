.catalogo-marca {
    background-image: url("../img/bg-Cam.png");
    background-repeat: no-repeat;
    background-position: right top;
    background-attachment: fixed;
}

.catalogo-marca .grid{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-bottom: 30px;
}

.sinMarca {
    font-size: 342% !important;
    color: #00FFEA !important;
}

.container-marca{
    width: 330px !important;
    min-height: 256px;
    background-color: white;
    border:1px solid #00ffea;
    border-bottom: 4px solid #00ffea;
    font-family: "Myriad Pro";
    font-weight: 700;
    color: #00aeef;
    cursor: pointer;
    height: 100%;
    background-color: #00ffea;
    padding: 80px 10px 10px 10px;
    margin: 15px;
}

@media screen and (max-width: 767px) {
    .container-marca {
        width: 300px !important;
        min-height: 250px;
    }
}