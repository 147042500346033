.evento-pss{
    background-color: #ccebfb;
    height: 100%;
    padding-left: 15px;
    padding-right: 15px;
    min-height: 100vh;
}
.evento-pss .copete{
    color: #00aff2;
    padding-top: 50px;
    border-bottom: 1px dashed #66cdf7;
    font-family: "Myriad Pro";
    font-weight: 100;
}

.evento-pss .hash-container h1{
    font-family: "Myriad Pro", sans-serif;
    color: #00aff2 !important;
    font-weight: 100 !important;
}
.evento-pss .hash-container h2 {
    font-family: "Myriad Pro", sans-serif;
    color: #00aff2 !important;
    font-weight: 200 !important;
    font-size: 23px;
}

.kitAzul{
    max-width: 113px !important;
}

.hash-container{
    display: inline-block;
}
.hash-container h1, .hash-container h2{
    margin: 0;
}
@media(max-width: 679px){
    .kitAzul{
        max-width: 80px !important;
    }
    .evento-pss .hash-container h2{
        font-size: 17px;
    }
}

.evento-pss .banner{
    margin-top: 80px;
    border-top: 2px solid #00aff2;
    border-bottom: 2px solid #00aff2;
    padding-bottom: 40px;
}
.evento-pss .banner .dahua-logo{
    margin-top: 30px;
    margin-bottom: 50px;
}
.evento-pss .banner [class*="col-"]{
    padding: 0;
}
.evento-pss .banner p{
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 99px;
    color: #00aff2;
    max-width: 500px;
    line-height: 111px;
}

.evento-pss .banner h4{
    font-size: 26px;
}
.evento-pss .banner h4, .evento-pss .banner h5 {
    font-family: "Montserrat";
    font-weight: 200;
    color: #00aff2;
}


.dashed{
    margin-top: 15px;
    border-top: 1px dashed #66cdf7;
}

@media(max-width: 790px){
    .evento-pss .banner p{
        font-size: 80px;
    }
}

@media(max-width: 636px){
    .evento-pss .banner p{
        font-size: 66px;
        line-height: 77px;
    }
}

@media(max-width: 530px){
    .evento-pss .banner p{
        font-size: 50px;
        line-height: 50px;

    }
}

@media(max-width: 414px){
    .evento-pss .banner p{
        font-size: 30px;
        line-height: 30px;
    }
    .evento-pss .banner h5{
        font-size: 12px;
    }
}

.evento-pss .seccion-formulario{
    margin-top: 50px;
}

.evento-pss label{
    font-family: "Myriad Pro";
    font-weight: 200;
    color:#00aff2;
    font-size: 20px;
}
.evento-pss label::before{
    content: url("../img/evento/trian.png");
    font-size: 12px;
    vertical-align: middle;
    padding-left: 10px;
    padding-right: 10px;
}

.evento-pss input, .evento-pss select, .evento-pss textarea{
    background: none !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    border: none;
    border-top:1px solid #66cdf7;
    border-bottom:2px solid #00aff2;
    box-shadow: none!important;
    color:#00aff2;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-bottom: 30px;
    font-family: "Myriad Pro";
    font-size: 16px;
    font-weight: 100;
    height: 48px;
    width: 100%;
}

.evento-pss select option{
    color: black;
}
.evento-pss .form-control:focus {
    border-color: #00aff2 !important;
    border-top-color: #66cdf7 !important;
    outline: 0;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
}

.evento-pss span.help-block{
    color:#00aff2;
    font-family: "Montserrat";
    font-weight: 200;
    font-size: 20px;
}

@media(max-width: 783px ){
    .evento-pss span.help-block{
        font-size: 13px;
    }
}

.evento-pss .datos{
    margin-top: 30px;
}

.evento-pss Button.boton{
    background: none;
    box-shadow: none;
    border: none;
    text-shadow: none;
    background-color: #00aff2;
    border-radius: 150px;
    font-size: 25px;
    font-family: "Montserrat";
    font-weight: 100;
    color:#ccebfa;
    margin-top: 50px;
    padding-left: 40px;
    padding-right: 40px;
    -webkit-transition: ease-in 100ms;
    -moz-transition: ease-in 100ms;
    -ms-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    outline: none;
}

.evento-pss .btn-default.boton:hover, .evento-pss .btn-default.boton:focus {
    background-color: #00aff2;
    color:#ccebfa;
    -webkit-transition: ease-in 100ms;
    -moz-transition: ease-in 100ms;
    -ms-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
    outline: none;
}

.evento-pss .registro-error{
    text-align: center;
    margin:15px auto;
    display: block;
    font-family: "Montserrat";
    font-weight: 100;
    font-size: 20px;
    color:#00aff2;

}

.fake-footer{
    border-top: 1px dashed #00aff2;
    margin-top: 30px;
    padding-top: 15px;
    padding-bottom: 40px;
}
.fake-footer a{
    font-family: "Myriad Pro";
    font-weight: 100;
    color:#00aff2;
    font-size: 23px;
}

.evento-pss .gracias h1{
    margin: 20px auto;
    text-align: center;
    display: block;
    color: #00aff2;
    font-size: 60px;
}

.evento-pss .gracias h4{
    margin: 20px auto;
    text-align: center;
    display: block;
    color: white;
    font-size: 30px;
}

.evento-pss .gracias p{
    margin: 20px auto;
    text-align: center;
    display: block;
    color: #00aff2;
    font-size: 15px;
}

.evento-pss .gracias .lineaCorta{
    height: 4px;
    width: 60px;
    background-color: white;
    display: block;
    margin: 30px auto;
}

@media(max-width: 500px){
    .evento-pss .gracias h1{
        font-size: 50px;
    }

    .evento-pss .gracias h4{
        font-size: 20px;
    }

    .evento-pss .gracias p{
        font-size: 12px;
    }


}



.evento-pss .select-tema{
    margin-top: 50px;
    padding-bottom: 40px;
}
@media(max-width: 500px){
    .evento-pss .select-tema{
        margin-top: 0px;
    }
}
.evento-pss .select-tema p{
    font-family: "Myriad Pro" !important;
    font-weight: 100 !important;
    padding-left: 15px;
}
.evento-pss .select-tema button{
    margin-left: 0px !important;
    margin-right: 0px !important;
    background: none !important;
    color: #00aff2 !important;
    font-family: "Myriad Pro"!important;
    border-radius: 0px !important;
    font-weight: 100;
    text-shadow: none!important;
    font-size: 20px;
    /*padding-top: 13px;*/
    padding-bottom: 0px;
    width: 100%;
    border: 1px solid #00aff2;
    text-align: left;
    height: 57px;
    display: flex;
    align-items: center;
    padding-right: 69px;
    white-space: normal;
}
@media(max-width: 1276px){
    .evento-pss .select-tema button{
        font-size: 17px;
    }
}
@media(max-width: 1134px){
    .evento-pss .select-tema button{
        font-size: 15px;
    }
}
@media(max-width: 1042px){
    .evento-pss .select-tema button{
        font-size: 14px;
    }
}
@media(max-width: 889px){
    .evento-pss .select-tema button{
        font-size: 16px;
    }
}
@media(max-width: 380px){
    .evento-pss .select-tema button{
        font-size: 12px;
    }
}
.evento-pss .select-tema .dropdown-menu{
    width: 100%;
    position: absolute;
    top: -1px;
    border-radius: 0px !important;
    margin: 0!important;
    padding: 0 !important;
    box-shadow: none;
    border: 1px solid #00aff2;
    height: 100%;
    background-color: #ccebfb !important;
    top: 56px;
}
.evento-pss .select-tema .btn-default:hover{
    border: 1px solid #00aff2 !important;
    box-shadow: none !important;
}
.evento-pss .select-tema .btn-group.open .dropdown-toggle{
    box-shadow: none !important;
    border: 1px solid #00aff2 !important;
}
.evento-pss .select-tema .open > .dropdown-menu{
    display: inline-table !important;
}
.evento-pss .select-tema ul{
    background: #ccebfb !important;
}

.evento-pss .select-tema li{
    border-bottom: 1px solid #00aff2;
}

.evento-pss .select-tema li a{
    color: #00aff2 !important;
    font-family: "Myriad Pro" !important;
    font-weight: 100 !important;
    font-size: 20px;
    padding-top: 8px;
    padding-bottom: 8px;

}
.evento-pss .dropdown-menu > li > a{
    white-space: normal;
    min-height: 59px;
    vertical-align: middle;
    display: flex;
    align-items: center;
}
.evento-pss .select-tema .dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus{
    background: none !important;
    background-color: transparent !important;
}

.evento-pss .select-tema .caret{
    background-image: url("../img/evento/down.png");
    background-repeat: no-repeat;
    height: 48px;
    width: 35px;
    transform: scale(0.6);
    border: none;
    float: right!important;
    position: absolute;
    top: 13px;
    right: 4px;
}

@-moz-document url-prefix() {
    .evento-pss .select-tema .caret{
        position: absolute;
        right: 7px;
        top: 13px;
    }
}

.evento-pss .select-tema .caret::before{
    position: absolute;
    content: '';
    height: 92px;
    top: -37px;
    margin-left: -28px;
    width: 3px;
    border-left: 2px solid #00aff2;
}




@media(max-width: 1276px){
    .evento-pss .select-tema li a{
        font-size: 17px;
    }
}
@media(max-width: 1134px){
    .evento-pss .select-tema li a{
        font-size: 15px;
    }
}
@media(max-width: 1042px){
    .evento-pss .select-tema li a{
        font-size: 14px;
    }
}
@media(max-width: 889px){
    .evento-pss .select-tema li a{
        font-size: 14px;
    }
}
@media(max-width: 380px){
    .evento-pss .select-tema li a{
        font-size: 12px;
    }
}
.evento-pss .select-tema .btn-group, .evento-pss .select-tema .btn-group-vertical
{
    width: 100%;
    display: block;
}

.evento-pss .select-tema .btn-default:focus, .evento-pss .select-tema .btn-default.focus{
    border: 1px solid #00aff2;
    box-shadow: none;
}


.evento-pss h3{
    color: #00aff2;
    font-family: "Montserrat";
    font-weight: 200;
    font-size: 25px;
    text-align: center;
    margin: 50px auto;
    display: block;
}

@media(max-width: 588px) {
    .evento-pss h3{
        font-size: 15px;
    }
}

.footer-preguntas{
    height: 200px;
    width: 100%;
    background-image: url("../img/evento/mas.png");
    background-position: bottom;
    background-repeat:repeat-x ;
    background-color: #ccebfb;
    padding-top: 50px;

}


.evento-pss .boton-juga Button{
    background: none;
    box-shadow: none;
    border: 1px solid white;
    text-shadow: none;
    background-color: #00aaeb;
    border-radius: 0px;
    font-size: 50px;
    font-family: "Montserrat";
    font-weight: 400;
    color:#ccebfb;
    margin-top: 30px;
    height: 151px;
    box-shadow: 4px 4px 9px #0000006b;
    padding-left: 60px;
    padding-right: 60px;
    -webkit-transition: ease-in 100ms;
    -moz-transition: ease-in 100ms;
    -ms-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    outline: none;
}

.evento-pss .boton-juga .btn-default:hover, .evento-pss .boton-juga .btn-default:focus {
    background-color: #00aaeb;
    color:#ccebfb;
    -webkit-transition: ease-in 100ms;
    -moz-transition: ease-in 100ms;
    -ms-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    outline: none;
}

.evento-pss .boton-juga p{
    color: white;
    text-align: center;
    max-width: 290px;
    margin: 20px auto;
    display: block;
    font-family: "Myriad Pro";
    font-weight: 100;
}

.evento-pss .registro-error{
    text-align: center;
    margin:15px auto;
    display: block;
    font-family: "Montserrat";
    font-weight: 100;
    font-size: 20px;
    color:#00ffeb;

}