.carritoPrincipal {
    background-color: white;
    height: 100vh;
}

.carrito {
    padding-bottom: 50px;
    background-color: white !important;
}

.carrito h1 {
    font-family: "Myriad Pro";
    font-weight: 100;
    color: #00ffea;
    font-size: 44px;
    margin-top: 20px;
}

.carrito .mleft9 {
    margin-left: 9% !important;
    margin-right: 0 !important;
    margin-top: 0px;
}

.carrito .lesspadin {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

/*header*/
.carrito .headerCarrito {
    margin-bottom: 30px;
}

.carrito .headerCarrito img {
    cursor: pointer;
}

.carrito .headerIzq {
    float: left;
    margin: 45px 0 0 75px;
    width: 50px;
}

.carrito .descripcionListaPrecio p, .carrito .descripcionListaPrecio h1 {
    float: right;
    color: white;
    text-align: right;
}

.carrito .textoBienvenida {
    display: flex;
    flex-direction: column;
}

.carrito .textoBienvenida p {
    margin-bottom: 0;
    font-size: 12px;
}

@media screen and (max-width: 712px) {
    .carrito .textoBienvenida p {
        font-size: 11px;
    }

    .carrito h1 {
        font-size: 40px !important;
    }
}

@media screen and (max-width: 566px) {
    .carrito .textoBienvenida p {
        font-size: 9px;
    }

    .carrito h1 {
        font-size: 35px !important;
    }
}

@media screen and (max-width: 566px) {
    .carrito .textoBienvenida p {
        font-size: 13px;
    }
}

/*responsive header*/
@media screen and (max-width: 1000px) {
    .carrito .descripcionListaPrecio h1 {
        font-size: 50px;
    }
}

@media screen and (max-width: 600px) {
    .carrito .descripcionListaPrecio h1 {
        font-size: 40px;
    }
}

@media screen and (max-width: 500px) {
    .carrito .headerIzq {
        margin: 45px 0 0 45px;
        width: 40px;

    }
}

/*CIRCULOS*/
.carrito .circulo-azul {
    height: 156%;
    background-size: 31px;
    background-image: url("../img/carrito/circle-blue.png");
    background-repeat: repeat-y;
    width: 100%;
}

.carrito .circulo-blanco {
    height: 100%;
    background-size: 31px;
    background-image: url("../img/carrito/circle-white.png");
    background-repeat: repeat-y;
    width: 100%;
}

@media screen and (max-width: 665px) {
    .circulo {
        display: none;
    }
}

@media screen and (min-width: 1522px) {
    .circulo {
        display: none;
    }
}

/*BUSQUEDA RAPIDA*/
/*columna que contiene toda la busqueda rapida*/
.carrito .contenedor-busqueda-rapida {
    background-color: #00ffea;
    padding: 40px 0 80px 0;
    border-top-left-radius: 15px;
    z-index: 2;
    height: auto;
    display: flex;
}

.carrito .contenedor-busqueda-rapida h3 {
    color: #00aaeb;
    margin: 0 20% 20px 0;
    font-weight: 100;
    font-family: "Myriad Pro" !important;
    padding-left: 17px;
}

.carrito .contenedor-busqueda-rapida hr {
    background-color: #00aaeb;
    border-color: #00aaeb;
    margin: 0 7% 0 20px;
}

.carrito .contenedor-busqueda-rapida input {
    background: transparent;
    outline: none;
    border: none;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 17px;
    padding: 15px 0 15px 20px;
    color: #00aaeb;
    width: 100%;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.contenedor-busqueda-rapida ::placeholder {
    color: #00aaeb;
    font-style: italic;
}

.carrito .imagenCruzCancelarBusqueda {
    float: right;
    padding: 10px 5px 10px 0;
    margin-top: 3px;
    order: 2;
    width: 30px !important;
    height: 45px;
}

.carrito .flexBusquedaRapida {
    display: flex;
    flex-direction: row;
    margin-right: 7% !important;
}

.carrito .autoSuggestProductos {
    order: 1;
    flex-grow: 2;
}

/*Lista de autosuggest*/
.carrito .contenedor-busqueda-rapida .react-autosuggest__suggestions-list {
    list-style: none;
    padding-left: 0;
    background-color: #00aaeb;
    opacity: 0.7;
    color: #00ffeb;
    z-index: 3;
    border-bottom-right-radius: 15px;
    overflow-y: scroll;
    height: 250px;
    width: 102%;
    margin-left: 4%;
}

.carrito .contenedor-busqueda-rapida .react-autosuggest__suggestions-list li:hover {
    background-color: #00a0ef !important;
}

.highlight {
    color: white;
    font-weight: bold;
}

/*RESPONSIVE h3 busqueda-rapida y busqueda-categoria*/
@media screen and (max-width: 600px) {
    .carrito .contenedor-busqueda-rapida h3 {
        font-size: 22px;
    }

    .carrito .contenedor-busqueda-categoria h3 {
        font-size: 22px;
    }
}

@media screen and (max-width: 450px) {
    .carrito .contenedor-busqueda-rapida h3 {
        font-size: 20px;
    }

    .carrito .contenedor-busqueda-categoria h3 {
        font-size: 20px;
    }
}

/*RESPONSIVE busqueda rapida*/
@media screen and (max-width: 400px) {
    .carrito .contenedor-busqueda-rapida input::placeholder {
        font-size: 17px;
    }
}

@media screen and (max-width: 991px) {
    .carrito .contenedor-busqueda-rapida h3 {
        padding-left: 0 !important;
    }

    .carrito .contenedor-busqueda-rapida hr {
        margin: 0;
    }

    .carrito .contenedor-busqueda-rapida input {
        padding-left: 0 !important;
    }

    .carrito .flexBusquedaRapida {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    /*Ancho de lista de sugerencias de productos*/
    .carrito .contenedor-busqueda-rapida .react-autosuggest__suggestions-list {
        width: 104%;
        margin-left: 0;
    }
}

/*lista sugerencias de productos mobile responsive*/

/*BUSQUEDA CATEGORIA*/
/*columna que contiene toda la busqueda categoria*/
.carrito .contenedor-busqueda-categoria {
    background-color: #00aaeb;
    padding: 40px 0 60px 0;
    height: 100%;
    border-top-left-radius: 15px;
    /*margin-left: 50px;*/
    z-index: 2;
    bottom: 15px;
}

.carrito .contenedor-busqueda-categoria h3 {
    font-weight: 100;
    color: white;
    margin: 10px 0 32px 0;
    font-family: "Myriad Pro";
    padding-left: 17px;
}

.carrito .contenedor-busqueda-categoria ul {
    list-style: none;
    margin-right: 7%;
    padding-left: 20px;
}

/*Contenedor de categoria con panel desplegable (PanelGroup)*/
.carrito .categoriaMenuCarrito {
    border-left: 1px solid white;
    border-bottom: 1px solid white;
}

.carrito .panel-group {
    margin-bottom: 0;
}

.carrito .panel-body {
    padding: 0;
    color: #00ffea;
    font-size: 1vw;
}

/*Panel de categoria desplegable (Panel)*/
.carrito .panelCarrito {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
}

.carrito .panel {
    margin-bottom: 0;
    border: none;
}

.carrito .panel-default {
    background: none;
}

.carrito .panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: white;
}

/*Estilo de nombres categorias*/
.carrito .categoriaMenuCarrito a {
    text-decoration: none;
    font-size: 17px;
    color: white;
    font-family: "Myriad Pro";
    font-weight: 100;
}

.carrito .panel-heading {
    padding-left: 17px;
}

/*Subcategoria Todas*/
.carrito .subCategoriaTodas {
    font-size: 14px;
    border: none;

    /*Mismos estilos que .subCategoria*/
    border-bottom: 1px dotted #00ffea;
    padding: 5px 0 5px 40px;
}

.carrito .panelTodas {
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    padding: 10px 0 10px 0;
}

.carrito .flexSeleccionarCategoria {
    display: flex;
}

.carrito .seleccionarCategoria {
    color: white !important;
    margin-bottom: 0px;
    font-size: 17px;
    font-family: "Myriad Pro" !important;
    font-weight: 100;
    padding: 0 0 0 17px;
    order: 1;
    flex-grow: 2;
}

/*Flechas de panel y panel group*/
.carrito .flechaAbajoCelesteTodas {
    float: right;
    width: 20px;
    height: 20px;
    order: 2;
    margin-right: 3px;
}

/*Subcategorias de una categoria*/
.carrito .subCategoria {
    border-bottom: 1px dotted #00ffea;
    padding: 5px 0 5px 40px;
}

/*Estilo de Subcategorias*/
.carrito .categoriaMenuCarrito .panel-body a {
    font-size: 14px;
}

.carrito .panel-default > .panel-heading {
    background: none;
    border-color: transparent;
}

.carrito .flechaAbajoCelesteCategoria {
    float: right;
    width: 20px;
}

/*RESPONSIVE contenedor categoria*/
@media screen and (max-width: 991px) {
    .carrito .contenedor-busqueda-categoria h3 {
        padding-left: 0 !important;
    }

    .carrito .contenedor-busqueda-categoria hr {
        margin: 0;
    }

    .carrito .contenedor-busqueda-categoria ul {
        padding-left: 0 !important;
        margin-right: 0;
    }
}

@media screen and (max-width: 600px) {
    .carrito .contenedor-busqueda-categoria .seleccionarCategoria {
        font-size: 15px;
    }

    .carrito .categoriaMenuCarrito a {
        font-size: 15px;
    }
}

/*Resultados de suggestions de Autosuggest */
.codigoAutosuggest {
    line-height: 25px;
    margin-left: 20px;
    font-size: 12px;
    cursor: pointer;
    color: white;
    text-decoration: none;
}

.nombreAutosuggest {
    line-height: 25px;
    margin-left: 20px;
    font-size: 15px;
    cursor: pointer;
    color: white;
    text-decoration: none;
}

@media screen and (max-width: 549px) {
    .codigoAutosuggest {
        font-size: 10px;
    }

    .nombreAutosuggest {
        font-size: 12px !important;
    }
}

.scrollProducto:link {
    text-decoration: none;
}

.categoriaAutosuggest {
    line-height: 25px;
    margin-left: 20px;
    font-size: 15px;
    cursor: pointer;
}

.nav-busqueda-rapida .codigoAutosuggest {
    color: #00ffeb;
    font-weight: bold;
}

.nav-busqueda-categoria .codigoAutosuggest {
    color: #00aaeb;
    font-weight: bold;
}

.nav-busqueda-rapida .nombreAutosuggest {
    color: #00ffeb;
    font-weight: bold;
}

.nav-busqueda-categoria .nombreAutosuggest {
    color: #00aaeb;
    font-weight: bold;
}

.lineaPunteadaListaAutosuggestProductos {
    border-bottom: 0.1px solid #00ffeb !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.lineaPunteadaListaAutosuggestCategorias {
    border-bottom: 0.1px solid #00aaeb !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
}

/*--------------CARRITO COMPRA---------------*/
#carrito-compra {
    background-color: white;
}

.carrito .aclaracionPrecios {
    color: #00aaeb;
    text-align: right;
    font-size: 10px;
    font-weight: bold;
    margin-top: 5px;
    float: left;
}

/*Ver resumen responsive para xs y sm*/
.verResumenXsMd {
    padding-left: 0;
    color: #00ffeb;
    margin-bottom: 20px;
}

.carrito .buttonResumen {
    position: fixed;
    padding-top: 8px;
    bottom: 10px;
    right: 10px;
    z-index: 100;
    font-family: "Myriad Pro";
    background: none !important;
    border: none !important;
    background-color: #00aaeb !important;
    color: white !important;
    text-shadow: none !important;
    width: auto;
    border-radius: 50px;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1);
    transition: all 200ms ease-in;
    transform: scale(1);
    font-weight: 100;
    font-size: 14px;
}

.carrito .mostrarBoton {
    display: block !important;
}

.carrito .ocultarBoton {
    display: none !important;
}

@media screen and (max-width: 991px) {
    #botonResumen {
        display: block !important;
    }
}

/*Ordenar por precio*/
.carrito .contenedorCombos {
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;

}

.carrito .ordenarPor {
    padding-left: 0;
    height: 30px;
    display: flex;
    order: 1;
    float: left;
    flex-grow: 1;
    flex-wrap: nowrap;
}

.carrito .textoOrdenar {
    color: #00aaeb;
    width: 85px;
    float: left;
    padding-top: 5px;
    order: 1;
}

.carrito .comboOrdenarPor {
    background-color: transparent;
    border: 1px solid #00aaeb;
    border-radius: 150px;
    padding: 0 15px 0 20px;
    text-align: center;
    float: left;
    color: #00aaeb;
    width: fit-content;
    height: 28px;
    order: 2;
}

/*Lista de precios*/
.carrito .listaPrecios {
    padding-left: 0;
    height: 30px;
    display: flex;
    order: 2;
    float: right;
    flex-grow: 1;
    flex-wrap: nowrap;
}

.carrito .textoListaPrecios {
    color: #00aaeb;
    width: 85px;
    font-size: 14px;
    padding-top: 5px;
    float: right;
    margin-right: 5px;
    order: 1;
}

.carrito .comboListaPrecios {
    background-color: transparent;
    border: 1px solid #00aaeb;
    border-radius: 150px;
    padding: 0 15px 0 20px;
    float: right;
    color: #00aaeb;
    width: fit-content;
    height: 28px;
    order: 2;
}

/*ORDENAMIENTO Y LISTA PRECIO RESPONSIVE*/
@media screen and (max-width: 1185px) {
    .carrito .textoListaPrecios {
        font-size: 13px;
    }

    .carrito .textoOrdenar {
        font-size: 13px;
    }

    .carrito .comboListaPrecios {
        font-size: 13px;
    }

    .carrito .comboOrdenarPor {
        font-size: 13px;
    }
}

@media screen and (max-width: 495px) {
    .carrito .listaPrecios {
        float: left;
        margin-top: 20px;

    }

    .carrito .ordenarPor {
        margin-right: 20px;
    }

    .carrito .contenedorCombos {
        display: block;
    }

    .carrito .comboListaPrecios {
        width: 176px;
    }
}

/*MOSTRAR STOCK*/
.carrito .contenedorMostrarStock {
    margin-bottom: 10px;
}

.carrito .contenedorMostrarStock p {
    margin: 0 auto;
    margin-left: 0 !important;
    margin-right: 10px !important;
    color: #00aaeb;
    font-weight: 100;
    float: left;
}

.carrito [type="radio"] + span:before {
    border: none;
    order: 2;
    margin: 0 auto;
    margin-left: 0 !important;
    float: left;
    margin-top: 3px;
}

/*TABLA PRODUCTOS*/
.carrito .tablaProductos {
    /*margin-left: 50px;*/
}

.carrito .tableHead {
    border-top: 3px solid #00aaeb;
    border-bottom: 3px solid #00aaeb;
}

.carrito .tableHead th {
    font-family: Montserrat;
    font-weight: 300;
    font-size: 10px;
    border-left: 1px solid #00ffea;
    text-align: center;
    color: #00aaeb;
    text-transform: uppercase;
    padding: 5px;
}

.carrito .tableHead th:last-child {
    border-right: 1px solid #00ffea;
}

.carrito .imagenTabla {
    width: 100px;
    height: 100px;
    border-top: 1px solid #00ffeb;
    border-bottom: 7px solid #00ffeb;
}

.carrito .table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
    padding: 15px 0 15px 0;
}

.carrito .table-responsive > .table > tbody > tr > td {
    white-space: unset;
}

.carrito .columnaProductos:last-child {
    border-bottom: 3px solid #00aaeb;
}

.carrito .columnaProductos td {
    border-left: 1px solid #00ffea;
    border-bottom: 2px solid #00aaeb;
    text-align: center;
    color: #00aaeb;
    padding: 10px;
}

.carrito .columnaProductos td:first-child {
    padding-left: 0;
    padding-right: 0;
    padding-top: 20px;
}

.carrito .columnaProductos td:last-child {
    border-right: 1px solid #00ffea;
}

.carrito .stock {
    white-space: nowrap;
    margin-bottom: 0px;
}

/*Input cantidad en la tabla*/
.carrito .formCantidad {
    color: #00aaeb;
    text-align: center;
    border: 1px solid #00aaeb;
    border-radius: 0;
    height: 25px;
}

.carrito input[type=number]::-webkit-outer-spin-button,
.carrito input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.carrito input[type=number] {
    -moz-appearance: textfield;
}

.carrito .columnaDescripcionArticulos {
    width: 900px;
}

@media screen and (max-width: 1260px) {
    .carrito .columnaDescripcionArticulos {
        max-width: 200px;
    }
}

.carrito .verMas {
    font-size: 11px;
}

.carrito .columnaDescripcionArticulos h5 {
    margin-bottom: 1px;
}

/*CARRITO DE COMPRAS*/
.carritoCompra {
    background-color: #00ffea;
    padding-top: 15px;
    margin: 0 30px 0 30px;
}

.nombreCarrito {
    color: #00aaeb;
    font-weight: bold;
    float: left;
    font-size: 18px;
    padding-top: 10px;
}

.filasCarritoIzq {
    /*margin-left:8%;*/
    color: #00aaeb;
    font-weight: normal;
    font-size: 10px;
    margin-top: 10px;
    margin-left: 6%;
    padding-left: 10px;
}

.precioUnitarioCarrito {
    font-size: 16px;
    margin-top: 20px;
    font-weight: normal;
}

@media (max-width: 1134px) {
    .carrito .filasCarritoIzq {
        font-size: 9px;
    }

    .carrito .precioUnitarioCarrito {
        font-size: 15px;
    }
}

@media (max-width: 1042px) {
    .carrito .filasCarritoIzq {
        font-size: 8px;
    }

    .carrito .precioUnitarioCarrito {
        font-size: 14px;
    }
}

.filasCarritoDer {
    color: #00aaeb;
    font-weight: normal;
    font-size: 20px;
    margin-top: 10px;
    margin-right: 8%;
}

.totalTotal {
    color: #00aaeb;
    font-weight: bold;
    margin-top: 15px;
    margin-left: 6%;
    padding-left: 10px;
}

.verResumen {
    float: right;
    font-weight: 100;
    margin-right: 8%;
    margin-top: 20px;
    color: #00aaeb;
    padding-bottom: 20px;
}

.lineaAzulCarrito {
    background-color: #00aaeb;
    border-color: #00aaeb;
    height: 1px;
    margin: 0 2% 0 2%;
}

.botonCancelarCarrito {
    margin-top: 20px;
    float: right;
    margin-right: 8%;
}

.imgCarrito {
    width: 65px;
    height: 65px;
    padding: 3px;
    float: right;
}

/*Lineas punteadas del mini carrito*/
.lineaCelesteDottedCarrito {
    border-bottom: 0.1px solid #00aaeb;
    border-color: #00aaeb;
    margin: 0 2% 0 2%;
}

.itemsDetalle:last-child {
    border-bottom: none;
    margin-bottom: 50px;
    background-color: black;
}

/*BARRA DE NAVEGACION FLOTANTE*/

.cantidadComprasCarrito {
    color: #00ffeb;
    float: left;
    font-size: 2vw;
    padding-top: 10px;
}

.imgCarritoCeleste {
    float: left;
}

@media screen and (max-width: 1200px) {
    .cantidadComprasCarrito {
        padding-top: 15px;
    }
}

/*Busqueda rapida*/

.barraNavegacionBusqueda {
    z-index: 5;
    display: inline !important;
}

.ocultarBarra {
    display: none !important;
}

.nav-busqueda-rapida {
    background-color: #00ffeb;
    height: 101px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 7%;
}

.nav-busqueda-rapida hr {
    background-color: #00aaeb;
    border-color: #00aaeb;
    margin: 0px;
    height: 1px;
    padding: 0px;
}

.nav-busqueda-rapida ::placeholder {
    color: #00aaeb;
    font-style: italic;
}

.botonCancelarBusquedaRapidaNav {
    float: right;
    padding: 15px 0 15px 0;
}

.barraNavegacionBusqueda .container {
    padding-left: 0;
    width: 100%;
}

/*hover suggestions*/
.barraNavegacionBusqueda .nav-busqueda-rapida .react-autosuggest__suggestion:hover {
    background-color: #293780;
    opacity: 1;
}

.barraNavegacionBusqueda .nav-busqueda-categoria .react-autosuggest__suggestion:hover {
    background-color: #a9fcff;
    opacity: 1;
}

.barraNavegacionBusqueda input {
    border: none;
    padding-left: 5px;
    background: transparent;
    outline: none;
    /*color: #00aaeb;*/
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 15px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    padding-top: 17px;
    padding-bottom: 15px;
    width: 100%;
}

.barraNavegacionBusqueda .nav-busqueda-rapida input {
    color: #00aaeb;
}

.barraNavegacionBusqueda .nav-busqueda-categoria input {
    color: #00ffeb;
}

.barraNavegacionBusqueda .react-autosuggest__suggestions-list {
    list-style: none;
    padding-left: 0;
    color: #00ffeb;
    z-index: 3;
    border-bottom-left-radius: 30px;
    position: fixed;
    overflow: auto;
    height: 85%;
}

.barraNavegacionBusqueda .nav-busqueda-categoria .react-autosuggest__suggestions-list {
    width: 30%;
}

.barraNavegacionBusqueda .nav-busqueda-rapida .react-autosuggest__suggestions-list {
    width: 35%;
}

.barraNavegacionBusqueda .nav-busqueda-rapida .react-autosuggest__suggestions-list {
    background-color: #00aaeb;
    color: #00ffeb;
}

.barraNavegacionBusqueda .nav-busqueda-categoria .react-autosuggest__suggestions-list {
    background-color: #00ffeb;
    color: #00aaeb;
}

.imgCancelarNav {
    background-color: transparent;
    color: #00aaeb;
    border: none;
    font-size: 30px;
    outline: none;
    float: left;
    padding: 0;
    margin-top: 18px;
}

.buscadorRapidoNav {
    margin-left: 40px;
}

/*Busqueda categoria*/

.nav-busqueda-categoria {
    background-color: #00aaeb;
    height: 101px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.nav-busqueda-categoria hr {
    background-color: #00ffeb;
    border-color: #00ffeb;
    margin: 0px;
    height: 1px;
    padding: 0px;
}

.nav-busqueda-categoria ::placeholder {
    color: #00ffeb;
    font-style: italic;
}

.flechitaCelesteDerechaNav {
    float: right;
    padding: 15px 0 15px 0;
}

/*COTIZACION DOLAR*/
.carrito .select-cotizacion {
    margin-top: 0;
    padding-bottom: 10px;
}

@media (max-width: 500px) {
    .carrito .select-cotizacion {
        margin-top: 0px;
    }
}

.carrito .select-cotizacion p {
    font-family: "Myriad Pro" !important;
    font-weight: 100 !important;
    padding-left: 15px;
}

.carrito .select-cotizacion button {
    margin-left: 0px !important;
    margin-right: 0px !important;
    background: none !important;
    color: white !important;
    font-family: "Myriad Pro" !important;
    border-radius: 0px !important;
    font-weight: 100;
    text-shadow: none !important;
    font-size: 20px;
    padding-top: 13px;
    padding-bottom: 0px;
    width: 100%;
    border: 2px solid white;
    text-align: left;
    height: 57px;
}

.carrito .select-cotizacion .dropdown-menu {
    width: 100%;
    position: relative;
    top: -1px;
    border-radius: 0px !important;
    margin: 0 !important;
    padding: 0 !important;
    box-shadow: none;
    border: 1px solid white;
    height: 100%;
}

.carrito .select-cotizacion .btn-default:hover {
    border: 2px solid white !important;
    box-shadow: none !important;
}

.carrito .select-cotizacion .btn-group.open .dropdown-toggle {
    box-shadow: none !important;
    border: 2px solid white !important;
}

.carrito .select-cotizacion ul {
    background: #00aaeb !important;
}

.carrito .select-cotizacion li {
    border-bottom: 1px solid white;
}

.carrito .select-cotizacion li a {
    color: white !important;
    font-family: "Myriad Pro" !important;
    font-weight: 100 !important;
    font-size: 20px;
    padding-top: 8px;
    padding-bottom: 8px;

}

.carrito .select-cotizacion .dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
    background: none !important;
    background-color: transparent !important;
}

.carrito .select-cotizacion .caret {
    background-image: url("../img/iconLista/expand.png");
    background-repeat: no-repeat;
    height: 48px;
    width: 35px;
    transform: scale(0.6);
    border: none;
    float: right !important;
}

@-moz-document url-prefix() {
    .carrito .select-cotizacion .caret {
        position: absolute;
        right: 7px;
        top: 13px;
    }
}

.carrito .select-cotizacion .caret::before {
    position: absolute;
    content: '';
    height: 90px;
    top: -39px;
    margin-left: -28px;
    width: 3px;
    border-left: 3px solid white;
}

@media (max-width: 1276px) {
    .carrito .select-cotizacion li a {
        font-size: 17px;
    }
}

@media (max-width: 1134px) {
    .carrito .select-cotizacion li a {
        font-size: 15px;
    }
}

@media (max-width: 1042px) {
    .carrito .select-cotizacion li a {
        font-size: 14px;
    }
}

@media (max-width: 889px) {
    .carrito .select-cotizacion li a {
        font-size: 16px;
    }
}

@media (max-width: 380px) {
    .carrito .select-cotizacion li a {
        font-size: 12px;
    }
}

.carrito .select-cotizacion .btn-group, .btn-group-vertical {
    width: 100%;
    display: block;
}

.carrito .select-cotizacion .btn-default:focus, .btn-default.focus {
    border: 2px solid #00FFEA;
}

.precio-dolar {
    background: transparent;
    border: 2px solid white;
    color: white;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 20px;
    padding-top: 15px;
    padding-bottom: 9px;
    width: 100%;
    padding-left: 13px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.precio-selected {
    background-color: white !important;
    color: #00aaeb !important;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

/*AUTOSUGGEST USUARIOS*/
.carrito .autoSuggestUsuarios {
    margin-bottom: 25px;
    border: 1px solid #00ffeb;
    padding-top: 25px;
    padding-bottom: 30px;
}

.carrito .autoSuggestUsuarios p {
    color: #00aaeb;
}

.carrito .botonCancelarBusquedaUsuario {
    float: right;
    padding: 15px 0 15px 0;
}

.carrito .autoSuggestUsuarios .react-autosuggest__input {
    background: transparent;
    outline: none;
    border: none;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 17px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    color: #00aaeb;
    width: 100%;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    margin-top: 5px;
}

.carrito .autoSuggestUsuarios .react-autosuggest__container {
    margin-right: 0;
    float: left;
    width: 100%;
}

.carrito .autoSuggestUsuarios hr {
    background-color: #00aaeb;
    border-color: #00aaeb;
    height: 1px;
    margin: 0 27% 0 20px;
}

.carrito .autoSuggestUsuarios ul {
    list-style: none;
    position: absolute;
    z-index: 5;
    background-color: white;
    height: 250px;
    overflow-y: auto;
    box-shadow: 3px 3px 10px grey;
    width: 80%;
}

.carrito .usuarioAutosuggest {
    line-height: 25px;
    margin-left: 20px;
    font-size: 15px;
    cursor: pointer;
    color: #00aaeb;
}

.carrito .usuarioAutosuggest .highlight {
    color: black;
}

.carrito .hrUsuarios {
    margin: 0 0 0 -200px !important;
}

.carrito .dniCod {
    font-size: 13px;
}

/*LOADER*/
.carrito .half-circle-spinner {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    position: relative;
}

.carrito .half-circle-spinner .circle.circle-1 {
    border-top-color: #00aaeb;
    animation: half-circle-spinner-animation 1s infinite;
}

.carrito .half-circle-spinner .circle.circle-2 {
    border-bottom-color: #00aaeb;
    animation: half-circle-spinner-animation 1s infinite alternate;
}

.carrito .half-circle-spinner .circle {
    border: calc(40px / 10) solid transparent;
}

.carrito .totalCarritoCompra .half-circle-spinner {
    margin-top: 13px;
    margin-right: 25px;
}

.carrito .itemsDetalle .half-circle-spinner {
    margin-top: 20px;
}

.carrito .spinnerPrecios {
    width: 25px;
}