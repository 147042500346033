.evento-pss-road {
    background-color: #0095da;
    height: 100%;
    padding-left: 15px;
    padding-right: 15px;
    min-height: 100vh;
}
.evento-pss-road .checkboxSection{
    display: flex;
    flex-wrap: wrap;
}

.evento-pss-road .checkboxSection input{
    width: 1px !important;
}
.evento-pss-road .checkboxSection span {
    padding: 5px;
}
/*HEADER*/

.evento-pss-road .headerRoad {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    margin-top: 70px;
    flex-direction: row;
    flex-wrap: wrap;
}

.evento-pss-road .headerRoad h2 {
    color: white;
    margin: 90px 0 0 30px;
}

.evento-pss-road .headerRoad h4 {
    color: white;
    margin: 5px 0 0 30px;
    font-size: 20px;
}

.evento-pss-road .headerRoad img {
    order: 0;
}

.evento-pss-road .headerRoad .textoTitulo {
    order: 1;
}

/*HEADER RESPONSIVE*/

@media screen and (max-width: 900px) {
    .evento-pss-road .headerRoad h2 {
        font-size: 23px;
    }

    .evento-pss-road .headerRoad h4 {
        font-size: 17px;
    }

    .evento-pss-road .headerRoad img {
        height: 235px;
        width: 230px;
    }
}

@media screen and (max-width: 750px) {
    .evento-pss-road .headerRoad h2 {
        font-size: 20px;
    }

    .evento-pss-road .headerRoad h4 {
        font-size: 14px;
    }

    .evento-pss-road .headerRoad img {
        height: 205px;
        width: 200px;
    }
}

@media screen and (max-width: 542px) {
    .evento-pss-road .headerRoad h2 {
        margin: 30px 0 0 30px;
    }

    .evento-pss-road .headerRoad h4 {
        margin: 5px 0 0 30px;
    }
}

@media screen and (max-width: 450px) {
    .evento-pss-road .headerRoad img {
        height: 175px;
        width: 170px;
    }

    .evento-pss-road .headerRoad h2 {
        font-size: 17px;
    }

    .evento-pss-road .headerRoad h4 {
        font-size: 11px;
    }

}

/*SECCION FORMULARIO*/
.evento-pss-road .seccion-formulario {
    margin-top: 50px;
}

/*placeholder inputs*/
.evento-pss-road .seccion-formulario ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    font-weight: 300;
}

.evento-pss-road .seccion-formulario :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
}

.evento-pss-road .seccion-formulario ::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
}


/*quitar flechas input*/
.evento-pss-road .seccion-formulario input {
    border: none;
    background-color: #00a5e5 !important;
    color: white;
    font-weight: 300;
}

.evento-pss-road .seccion-formulario input[type=number]::-webkit-inner-spin-button,
.evento-pss-road .seccion-formulario input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.evento-pss-road .seccion-formulario input[type=number] {
    -moz-appearance: textfield;
}

/*estilo select*/
.evento-pss-road .seccion-formulario select {
    border: none;
    background-color: #00a5e5 !important;
    color: white;
    font-weight: 300;

}

.evento-pss-road .seccion-formulario option {
    color: white;
    font-weight: 300;
}

/*estilo checkbox*/

.evento-pss-road .seccion-formulario input[type="radio"] {
    font-family: "Myriad Pro";
    font-weight: 100;
    color: white;
    line-height: 56px;
    font-size: 20px;
    width: 100%;
    background-color: #00AEEF;
    background: none;
    border: none;
    border-bottom: 2px solid #00ffea;
}

.evento-pss-road .seccion-formulario [type="radio"] + span:before {
    border-radius: unset;
}

.evento-pss-road .seccion-formulario .checkboxSection span {
    /*margin-left: 50px;*/
    /*margin-right: 50px;*/
}

.evento-pss-road .seccion-formulario .checkboxSection label {
    color:white;
    font-weight: lighter;
    display: flex;
}

.evento-pss-road .checkboxSection p{
    margin-bottom: 0;
    align-self: center;
    padding-left: 10px;
    padding-right: 20px;
}

.evento-pss-road input, .evento-pss-road select, .evento-pss-road textarea {
    background: none !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    border: none;
    border-top: 1px solid #66cdf7;
    border-bottom: 2px solid #00aff2;
    box-shadow: none !important;
    color: #00aff2;
    /*-webkit-appearance: none;*/
    /*-moz-appearance: none;*/
    /*appearance: none;*/
    margin-bottom: 30px;
    font-family: "Myriad Pro";
    font-size: 16px;
    font-weight: 100;
    height: 48px;
    width: 100%;
}

.evento-pss-road select option {
    color: black;
}

.evento-pss-road .form-control:focus {
    border-color: #00aff2 !important;
    border-top-color: #66cdf7 !important;
    outline: 0;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.evento-pss-road span.help-block {
    color: #00aff2;
    font-family: "Montserrat";
    font-weight: 200;
    font-size: 20px;
}

@media (max-width: 783px ) {
    .evento-pss-road span.help-block {
        font-size: 13px;
    }
}

.evento-pss-road .datos {
    margin-top: 30px;
}

/*boton enviar*/
.evento-pss-road Button.boton {
    background: none;
    box-shadow: none;
    border: none;
    text-shadow: none;
    background-color: #00aff2;
    border-radius: 150px;
    font-size: 25px;
    font-family: "Montserrat";
    font-weight: 100;
    color: #ccebfa;
    margin-top: 50px;
    padding-left: 40px;
    padding-right: 40px;
    -webkit-transition: ease-in 100ms;
    -moz-transition: ease-in 100ms;
    -ms-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    outline: none;
}

.evento-pss-road .btn-default.boton:hover, .evento-pss-road .btn-default.boton:focus {
    background-color: #00aff2;
    color: #ccebfa;
    -webkit-transition: ease-in 100ms;
    -moz-transition: ease-in 100ms;
    -ms-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
    outline: none;
}

/*registro error*/
.evento-pss-road .registro-error {
    text-align: center;
    margin: 15px auto;
    display: block;
    font-family: "Montserrat";
    font-weight: 100;
    font-size: 20px;
    color: #00aff2;

}

.evento-pss-road .registro-error {
    text-align: center;
    margin: 15px auto;
    display: block;
    font-family: "Montserrat";
    font-weight: 100;
    font-size: 20px;
    color: #00ffeb;
}

