.sorteo {
    background-color: #0095da;
    height: 100%;
    padding-left: 15px;
    padding-right: 15px;
    min-height: 100vh;
}

.sorteo .headerRoad {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
    flex-direction: row;
    flex-wrap: wrap;
}

.sorteo .headerRoad img {
    height: 180px;
}

.sorteo .textoTitulo h2{
    color: white!important;
    margin: 65px 0 0 30px;
}


/*estilo select*/
.sorteo .start-sorteo select {
    margin-top: 30px;
    border: none;
    background-color: #00a5e5 !important;
    color: white;
    font-weight: 300;

}

.sorteo .start-sorteo option {
    color: white;
    font-weight: 300;
}


/*botones sorteo*/
.sorteo .botonesSorteo {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
}

.sorteo .botonesSorteo .half-circle-spinner{
    margin-top: 55px;
}

.sorteo .participante {
    background-color: #00a5e5;
}

.sorteo .participante p {
    font-size: 20px;
}

/*registro error*/
.sorteo .registro-error {
    text-align: center;
    margin: 15px auto;
    display: block;
    font-family: "Montserrat";
    font-weight: 100;
    font-size: 20px;
    color: #00aff2;

}

.sorteo .registro-error {
    text-align: center;
    margin: 15px auto;
    display: block;
    font-family: "Montserrat";
    font-weight: 100;
    font-size: 20px;
    color: #00ffeb;
}







@media (max-width: 998px) {
    .sorteo {
        background-size: cover;

    }
}

.sorteo .container-limit {
    max-width: 1000px;
    padding-right: 25px;
    padding-left: 25px;
    padding-top: 0px;
}

.sorteo h1, .sorteo h4 {
    color: #5cffe2;
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 110px;
    text-align: center;
    display: block;
    margin: 0px auto;
}

.sorteo h1 {
    margin-top: 90px;
}

.sorteo h4 {
    font-size: 37px;
}

.sorteo .lineaCorta {
    background-color: #00ffeb;
    width: 100px;
    height: 10px;
    display: block;
    margin: 15px auto;
}

.sorteo .numero {
    background-color: #00ffeb;
    color: #00aaeb;
    font-size: 55px;
    font-family: "Montserrat";
    font-weight: 500;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 2px solid #00aaeb;
}

.sorteo .border {
    border-bottom: 2px solid #00ffeb;
    display: flex;
}

.sorteo .border:first-child {
    border-top: none
}

.sorteo .border:last-child {
    border-bottom: 2px solid #00ffeb;
}

.sorteo .img-container {
    display: flex;
    align-items: center;
    width: 100%;
    /*border-right: 2px solid #00ffeb;*/
}

.sorteo .img-container::after {
    content: '';
    position: absolute;
    right: 49px;
    z-index: 1;
    height: 100%;
    width: 1px;
    border-right: 2px solid #00ffeb;

}

.sorteo .container-premio {
    display: flex;
}

.sorteo .img-container img {
    max-height: 78px;
    margin: 0 auto;
    z-index: 10;
}

/*imagenes intersec*/
.container-premio .premio1 {
    height:45px;
}
.container-premio .premio2 {
    height:55px;
}
.container-premio .premio3 {
    height:55px;
    margin-left: 95px!important;
}


.sorteo .img-container p {
    max-width: 204px;
    color: white;
    font-family: "Montserrat";
    font-weight: 100;
    font-size: 17px;
}

.sorteo h3 {
    color: #00ffeb;
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 29px;

}

.sorteo .winner-field{
    display: flex;
}
.sorteo .nombre-participante{
    color: white;
    font-size: 30px;
    font-family: Montserrat;
    font-weight: 200;
    display: flex;
    align-items: center;
}

.start-sorteo p{
    color: white;
    font-family: "Montserrat";
    font-weight: 100;
    font-size: 30px;
    text-align: center;
    margin: 20px auto;
}
.start-sorteo h1{
    font-size: 60px;
}

.start-sorteo Button{
    background: none;
    box-shadow: none;
    border: none;
    text-shadow: none;
    background-color: #00aff2;
    border-radius: 10px;
    font-size: 25px;
    font-family: "Montserrat";
    font-weight: 200;
    color:white;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 50px;
    padding-right: 50px;
    -webkit-transition: ease-in 100ms;
    -moz-transition: ease-in 100ms;
    -ms-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    outline: none;
    margin: 50px auto;
    display: block;
}

.start-sorteo .btn-default:hover, .start-sorteo .btn-default:focus {
    background-color: #00aff2;
    color:white;
    -webkit-transition: ease-in 100ms;
    -moz-transition: ease-in 100ms;
    -ms-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
    outline: none;
}