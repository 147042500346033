.cuentaYOrden {
    width: 100%;
    background-color:white;
    padding: 0 50px 50px 50px;
    color: #00aaeb;
    min-height: 57vh;
}

.cuentaYOrden h3 {
    color: #00aaeb;
    margin-bottom: 20px;
}

.cuentaYOrden select {
    font-family: "Myriad Pro";
    font-weight: 100;
    line-height: 44px;
    font-size: 20px;
    width: 100%;
    color: #555;
    /*background-color: #00AEEF;*/
    background: none;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    border-top: 1px solid #00ffeb;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #00aaea;
    margin-bottom: 20px;
    padding: 0px 0 0px 20px;
    height: fit-content;
    -webkit-appearance: none;
    border-top: 1px solid #00ffeb;
}
/*TABLA*/

.cuentaYOrden .tableHead {
    border-top:3px solid #00aaeb;
    border-bottom:3px solid #00aaeb;
}

.cuentaYOrden .tableHead th {
    font-family: Montserrat;
    font-weight: 300;
    font-size: 10px;
    border-left: 1px solid #00ffea;
    text-align: center;
    color: #00aaeb;
    text-transform: uppercase;
    padding: 10px;
}

.cuentaYOrden .tableHead th:last-child {
    border-right: 1px solid #00ffea;
}

.cuentaYOrden .columnaDetalles:last-child {
    border-bottom: 3px solid #00aaeb;
}

.cuentaYOrden .columnaDetalles td {
    border-left: 1px solid #00ffea;
    border-bottom: 2px solid #00aaeb;
    border-top: 2px solid #00aaeb;
    text-align: center;
    font-size: 14px;
    color: #00aaeb;
    padding: 10px;
}

.cuentaYOrden .columnaDetalles td:last-child {
    border-right: 1px solid #00ffeb;
}

.cuentaYOrden .primera {
    border-top: 2px solid #00aaeb !important;
}

.cuentaYOrden .kit td {
    border-bottom: 0px solid #00aaeb;
    border-top: 0px solid #00aaeb;
    padding-top: 5px;
    padding-bottom: 5px;
}

.cuentaYOrden .kit td p {
    margin-bottom: 2px;
}
.cuentaYOrden .kit td h5 {
    margin-top: 0;
    margin-bottom: 0;
}

.cuentaYOrden .imagenTabla {
    width:100px;
    height: 100px;
    border-top: 1px solid #00ffeb;
    border-bottom: 7px solid #00ffeb;
}

.cuentaYOrden .verMas {
    font-size: 11px;
}

/*Precio Modificado*/
.cuentaYOrden .precioModificadoTD {
    width: 150px !important;
}

.cuentaYOrden .contenedorModificado {
    display: flex;
    flex-direction: row;
}

.cuentaYOrden .contenedorModificado p {
    padding-top: 3px;
    margin-right: 3px;
}

.cuentaYOrden .inputModificado {
    color: #00aaeb;
    text-align: center;
    border: 1px solid #00aaeb;
    border-radius: 0;
    height: 25px;
    padding-left: 20px;
    min-width: 120px;
}

.cuentaYOrden input[type=number]::-webkit-outer-spin-button,
.cuentaYOrden input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.cuentaYOrden input[type=number] {
    -moz-appearance: textfield;
}

/*Boton enviar*/
.cuentaYOrden .btn-default {
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    text-shadow: none;
    background-color: #00aaeb;
    border-radius: 150px;
    font-size: 25px;
    font-family: "Montserrat";
    font-weight: 400;
    color: white;
    /*margin-top: 50px;*/
    padding-left: 60px;
    padding-right: 60px;
    -webkit-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    outline: none;
    font-family: "Myriad Pro";
    font-weight: 100;
}

.cuentaYOrden .btn-default:hover {
    background-color: #00aaeb;
    color: white;
    -webkit-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    outline: none;
}

.cuentaYOrden .flexTitulo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.cuentaYOrden .titulo {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cuentaYOrden .titulo h3 {
    color: #00aaeb;
    margin-bottom: 20px;
    float: left;
}

.cuentaYOrden .titulo p {
    float: left;
    padding-top: 10px;
    cursor: pointer;
    margin-left: 5px;
}

/*Loader*/
.cuentaYOrden .buscandoDolar {
    margin-bottom: 80px;
}
.cuentaYOrden .buscandoDolar p {
    float:left;
    margin-top: 4px;
    margin-right: 10px;
}

.cuentaYOrden .buscandoDolar .half-circle-spinner {
    float: left;
    width:30px;
    height: 30px;
}

.cuentaYOrden .botones {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.cuentaYOrden .botones button {
    width: 160px;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

@media screen and (max-width: 500px) {
    .cuentaYOrden .botones button{
        width: 100px;
        font-size: 20px;
    }
}

@media screen and (max-width: 600px) {
    .cuentaYOrden {
        padding-right: 10px;
        padding-left: 10px;
    }
}