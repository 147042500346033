.institucional-portada {
    background-image: url("../img/bg-institucional.png");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: calc(100vh - 150px);
    border-bottom: 2px solid #00ffea;
}

.institucional-portada .recuadro {
    position: relative;
    margin-top: 12%;
    text-align: center;
    border: 2px solid #00ffea;
    padding-top: 40px;
    padding-bottom: 45px;
    padding-left: 40px;
    padding-right: 40px;
}

.institucional-portada .recuadro h1 {
    color: white;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 19px;
    line-height: 26px;
    margin: 0;
}

@media(max-width: 500px){
    .institucional-portada .recuadro h1{
        font-size: 13px;
    }
}

.mision-vision {
    margin-top: 90px;
}

@media(max-width: 994px){
    .mision-vision{
        display: none;
    }
}


.mision-vision .mision {
    background-image: url("../img/recuadroMV.png");
    background-repeat: no-repeat;
    background-position-x: 70px;
    background-size: auto;
}

.mision-vision .calidad {
    background-image: url("../img/recuadroCalidad.png") !important;
    background-repeat: no-repeat;
    background-position-x: 70px;
    background-size: auto;
    min-height: 600px;
}

.mision-vision .calidad  h3 {
    padding-top: 60px;
    color: white;
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 58px;
}

.mision-vision .calidad span {
    color: white;
    max-width: 500px;
    font-family: "Myriad Pro";
    font-weight: 300;
    font-size: 16px;
}

.mision-vision .objetivos {
    background-image: url("../img/recuadroObjetivos.png") !important;
    background-repeat: no-repeat;
    background-position-x: 70px;
    background-size: auto;
    min-height: 600px;
}

.mision-vision h2 {
    padding-top: 63px;
    color: white;
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 85px;
}

.mision-vision p {
    color: white;
    max-width: 500px;
    font-family: "Myriad Pro";
    font-weight: 100;
    padding-bottom: 72px;
    font-size: 16px;
    padding-top: 15px;
}

.mision-vision li {
    color: white;
    max-width: 600px;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 16px;
    padding-top: 6px;
}

.mision-vision .linea-bottom {
    height: 205px;
    border-bottom: 2px solid #00ffea;
}

.mision-vision .vision {
    background-image: url("../img/recuadroMV.png");
    background-repeat: no-repeat;
    background-size: auto;
    background-position-x: 70px;
}

.mision-vision .vision h2 {
    text-align: right;
}

.mision-vision .vision p {
    text-align: right;
    max-width: none;
    font-size: 15px;
    padding-top: 5px;
}

.especialistas {
    border-top: 2px solid #00ffea;
    background-image: url("../img/equipo.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: -100px;
    background-color: #00adfe;
    min-height: 322px;
}

@media (max-width:622px) {
    .especialistas{
        background-position-y: -50px;
    }
}
.especialistas h2 {
    color: white;
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 10rem;
    text-align: center;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.58);
    margin-top: 20%;
    margin-bottom: 10px;
}


.especialistas .clave {
    background-color: #00adfe;

}

.especialistas .clave p {
    color: white;
    text-align: center;
    font-family: "Myriad Pro";
    font-weight: 100;
    padding: 20px;
}

.especialistas p {
    font-family: "Myriad Pro";
    font-weight: 200;
    font-size: 15px;
    color: white;
    text-align: center;
    margin-top: 60px;
    padding-bottom: 35px;
    margin-bottom: 0px;
}

@media(max-width: 684px){
    .especialistas h2{
        font-size: 5rem;
    }


    .especialistas p {
        padding-bottom: 25px;
    }
}

@media(max-width: 354px){
    .especialistas h2{
        font-size: 2rem;
    }
}
.especialistas p a {
    color: white;
    text-decoration: underline;
    text-decoration-color: #00ffea;
}

.nuestros-aliados {
    background-color: #9ae235;
    padding-bottom: 70px;
}

.nuestros-aliados h2 {
    color: white;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 36px;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;
}

.nuestros-aliados img {
    max-width: 140px;
    margin: 0 auto;
    display: block;
}

.nuestros-aliados [class*="col-"] {
    padding: 20px;
}

.mapa {
    border-top: 3px solid #00ffea;
    border-bottom: 3px solid #00ffea;
}

.mapa [class*="col-"] {
    padding: 0px;
}

.marker-map {
    width: 30px;
    position: relative;
    left: -18px;
    top: -14px;;
}

.mapa .vcar {
    margin-top: 15%;
    padding-left: 20%;
}

.mapa .vcar img {
    margin-bottom: 30px;
}

.mapa .vcar p {
    color: white;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 20px;
    margin-bottom: 0px;
    padding-bottom: 1px;
}


