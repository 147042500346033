
.slider .owl-theme {
    border-bottom: 2px solid #00ffea;
}

.slider .owl-nav {
    width: 100%;
    position: absolute;
    top: 38%;
    height: 0px;
}

@media (max-width: 768px) {
    .slider .owl-nav {
        display: none;
    }
}

.slider .owl-theme .owl-nav [class*=owl-] {
    width: 30px; /*or your image's width*/
    height: 100px; /*or your image's height*/
    background-repeat: no-repeat !important;
    background-color: transparent !important;
    border-radius: none;
    margin-right: 30px !important;
    margin-left: 30px !important;
}

.slider .owl-theme .owl-next {
    background-image: url("../img/slider/next.png") !important;
    float: right;
}

.slider .owl-theme .owl-prev {
    background-image: url("../img/slider/prev.png") !important;
    float: left;
}

.owl-theme .owl-dots {
    position: relative;
    margin-top: -25px;
}

.sliderHome .owl-theme .owl-dots {
    display: none;
}

.sliderHome .owl-carousel .owl-stage{
    background-color: #00bef7;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background-color: white !important;
}

.owl-theme .owl-dots .owl-dot span {
    background-color: transparent !important;
    border: 1px solid white;
}

.slider .owl-theme .item img {
    position: relative;
    z-index: 2;
}

.titSlider {
    position: absolute;
    top: 38%;
    left: 7%;
    z-index: 4;
}

.titSlider h2 {
    color: white;
    font-family: "Myriad Pro";
    font-weight: 100;
}

.titSlider h2 span {
    color: #00aeef;
    font-family: "Myriad Pro";
    font-weight: 700;
}

@media (max-width: 768px) {
    .slider .owl-nav {
        top: 24% !important;
    }

    .slider .owl-theme .owl-nav [class*=owl-] {
        margin-right: 10px !important;
        margin-left: 10px !important;
    }

    .slider .owl-theme .owl-next {
        transform: scale(0.7) !important;
    }

    .slider .owl-theme .owl-prev {
        transform: scale(0.7) !important;
    }

    .titSlider {
        top: 26%;
    }

    .titSlider h2 {
        font-size: 23px !important;
    }
}

.container-boton-banner {
    position: absolute;
    bottom: 15px;
    z-index: 9;
    left: 15%;
}

.container-boton-banner button {
    background: none;
    background-color: #9ae235;
    border: none;
    color: white;
    max-width: 150px;
    margin: 0 15px;
    padding-top: 7px;
    padding-bottom: 3px;
    padding-left: 10px;
    padding-right: 10px;
    font-family: "Myriad Pro";
    font-weight: 400;
    line-height: normal;
    text-align: center;
    outline: none;
}

@media (max-width: 891px) {
    .container-boton-banner {
        position: relative;
        bottom: 0;
        left: inherit;
        display: flex;
        justify-content: center;
        background-color: #00bef7;
    }

    .container-boton-banner button {
        margin: 0 5px;
    }
}