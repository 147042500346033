.transferencia-bancaria .fotos {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.transferencia-bancaria .cuadroAgregarFoto {
    width: 130px;
    height: 130px;
    border: 1px dashed;
    border-color: #0b97c4;
    margin-right: 15px;
    margin-top: 15px;
    text-align: center;
}

.transferencia-bancaria .cuadroAgregarFoto .glyphicon {
    font-size: 30px;
    color: #0b97c4;
    margin-top: 40px;
}

.transferencia-bancaria .cuadroAgregarFoto p {
    font-size: 10px;
    color: #0b97c4;
}

.transferencia-bancaria .cuadroFotoLoading {
    width: 130px;
    height: 130px;
    margin-top: 15px;
    margin-right: 15px;
    padding-top: 40px;
    text-align: center;
    border: 1px dashed;
    border-color: #0b97c4;

}

.transferencia-bancaria .agregarFoto {
    visibility: hidden
}

.transferencia-bancaria .cuadroFoto {
    height: 130px;
    margin-right: 15px;
    margin-top: 15px;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
}

.transferencia-bancaria .cuadroFoto img {
    max-width: 100%;
    max-height: 100%;
}

.transferencia-bancaria .cuadroFoto button {
    padding: 2px
}

.transferencia-bancaria .cuadroFoto .cruz {
    border-radius: 100%;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.54);
    width: 30px;
    height: 30px;
    margin-right: 3px;
}

.transferencia-bancaria .cuadroFoto .herramientas {
    position: absolute;
    top: 5px;
    justify-content: space-between;
    display: none;
    flex-wrap: nowrap;
}

.transferencia-bancaria .cuadroFoto .cruz .glyphicon {
    margin-top: 5px;
}

.transferencia-bancaria .cuadroFoto:hover > .herramientas {
    display: flex;
}

