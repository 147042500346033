@import url('./fonts.css');
html{
    overflow-x: hidden;
}
body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background-color: #00aeef;
}

a{
    cursor: pointer;
}

.sin-padding{
    padding-right: 0 !important;
    padding-left: 0 !important;
}

sb{
    font-weight: 500!important;
}

.container-limit{
    max-width: 1100px; /*Limite del container general para centrar el contenido segun el diseño*/
    margin: 0 auto;
}
.container-fluid{
    padding-right: 0px;
    padding-left: 0px;
}

.greenBorder{
    border-top:2px solid #00ffea;
    border-bottom:2px solid #00ffea;
}

.separador{
    height: 110px;
}

.sombra{
    -webkit-box-shadow: 0px 5px 17px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 5px 17px -3px rgba(0,0,0,0.75);
    box-shadow: 0px 5px 17px -3px rgba(0,0,0,0.75);
}

.center-block{
    float: none;
    margin:0 auto;
}

.center-thing{
    text-align: center !important;
}

/*SUPER CROTADA NECESARIA PARA EL RESPONSIVE,
NO SE SI REACTBOOTSTRAP VINO FALLADO PERO ME METIA UNOS MARGIN NEGATIVOS HORRIBLES Y CALCULABA MAL EL WIDTH
DE LA PAGINA*/
.row{
    margin-left: 0 !important;
    margin-right: 0 !important;
}

/*.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {*/
     /*padding-right: 0px !important;*/
     /*padding-left: 0px !important;*/
/*}*/


.breadcrumb{
    background: none!important;
}

.breadcrumb > li + li:before{
    content: ">\A0";
    color: #00ffea;
}

.breadcrumb a{
    color: #00ffea;
    font-family: "Myriad Pro";
    font-weight: 100;
    text-transform: uppercase;
}

.breadcrumb > .active {
    color: #00ffea;
    text-transform: uppercase;
    font-family: "Myriad Pro";
    font-weight: 500;
}

.entrega img{
    margin: 0 auto;
    margin-top: 90px;
    margin-bottom: 70px;
    width: 100%;
}

@media(min-width: 769px) {
    .row.is-flex {
        display: flex;
        /*flex-wrap: wrap;*/
    }

    .row.is-flex > [class*='col-'] {
        display: flex;
        flex-direction: column;
    }

    /*
    * And with max cross-browser enabled.
    * Nobody should ever write this by hand.
    * Use a preprocesser with autoprefixing.
    */
    .row.is-flex {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        /*-webkit-flex-wrap: wrap;*/
        /*-ms-flex-wrap: wrap;*/
        /*flex-wrap: wrap.c*/
    }

    .row.is-flex > [class*='col-'] {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.topBorder{
    border-top: 2px solid #00ffea;
}

.owl-nav{
    height: 0px!important;
}

.owl-nav button {
    outline: none !important;
}

.custom-error{
    color: white;
    position: fixed;
    width: 411px;
    top: 80px;
    right: 125px;
    text-align: left;
    padding: 12px 9px 9px 15px;
    font-family: "Myriad Pro";
    font-weight: 100;
    border-radius: 8px;
    box-shadow: 1px 1px 7px black;
    background-color: #e22f50;
    z-index: 3333888;
}
a.poweredby{
    display: none;
}