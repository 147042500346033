.login-encuesta {
    background-image: url("../img/bg-encuesta.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #00b2fb;
    height: calc(100vh - 150px);
    border-bottom: 2px solid #00ffea;
    border-top: 2px solid #00ffea;
    min-height: 550px;
}

@media (min-width: 769px) {
    .login-encuesta .recuadro {
        position: relative;
        top: 12vh;
        padding-left: 120px;
    }
}

@media (max-width: 769px) {
    .login-encuesta .recuadro {
        position: relative;
        top: 40px;
        padding-left: 20px;
    }
}

@media (min-width: 769px) {
    .login-encuesta .recuadro h2 {
        color: white;
        font-family: "Myriad Pro";
        font-weight: 100;
        font-size: 40px;
        margin: 0;
        padding-bottom: 50px;
        padding-left: 0px;
    }
}

@media (max-width: 769px) {
    .login-encuesta .recuadro h2 {
        color: white;
        font-family: "Myriad Pro";
        font-weight: 100;
        font-size: 20px;
        margin: 0;
        padding-bottom: 50px;
        padding-left: 45px;
    }
}

@media (min-width: 769px) {
    .login-encuesta .recuadro h1 {
        color: white;
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 66px;
        line-height: 76px;
        margin: 0;
        padding-bottom: 30px;
    }
}

@media (max-width: 769px) {
    .login-encuesta .recuadro h1 {
        color: white;
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 30px;
        margin: 0;
        padding-bottom: 30px;
    }
}

@media (min-width: 769px) {
    .login-encuesta .recuadro p {
        font-family: "Myriad Pro";
        font-weight: 100;
        font-size: 20px;
        color: white;
    }
}

@media (max-width: 769px) {
    .login-encuesta .recuadro p {
        font-family: "Myriad Pro";
        font-weight: 100;
        font-size: 15px;
        color: white;
    }
}

.login-encuesta .recuadro .separador-verde {
    width: 90px;
    height: 7px;
    background-color: #9ae235;
    margin-top: 60px;
}

.encuesta .login-ingreso .titulo {
    color: white;
}

.encuesta .login-ingreso input {
    border-bottom: 1px solid #00ffea !important;
}

.encuesta .login-ingreso select {
    border-bottom: 1px solid #00ffea !important;
}

.encuesta .login-ingreso .subtitulo {
    color: white;
    font-weight: 700;
    margin-top: 80px;
    margin-bottom: 30px;
}

.encuesta .recuadro {
    padding-top: 0 !important;
}

.encuesta .rowFechaNacimiento {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #00FFEA;
}

.encuesta .rowFechaNacimiento p {
    white-space: nowrap;
    height: fit-content;
    margin-bottom: 0;
    margin-right: 10px;
}

.encuesta .rowFechaNacimiento input {
    border-bottom: none !important;
    height: 27px;
}

.encuesta form {
    margin-top: 0 !important;
    border-top: 0 !important;
}

.encuesta .borderCeleste {
    border-top: 2px solid #00FFEA;
    border-bottom: 1px solid #00FFEA;
}

.encuesta .encuestaTipoTexto {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #00ffea;
    padding: 10px;
}

.encuesta .encuestaTipoTexto p {
    order: 1;
    flex-grow: 1;
    white-space: nowrap !important;
    height: fit-content;
    margin-bottom: 0 !important;
    padding-top: 10px;
}

.encuesta .encuestaTipoTexto img {
    order: 2;
    width: 15px;
    height: 15px;
    margin-top: 15px;
    margin-left: 15px;
}

.encuesta .encuestaTipoTexto input {
    order: 3;
    flex-grow: 2;
    height: 40px;
    border-bottom: none !important;
    margin-left: 10px;
    padding-top: 8px;
}

.encuesta .encuestaTipoSiNo {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #00ffea;
    padding: 10px;
}

.encuesta .encuestaTipoSiNo p {
    padding-top: 10px;
    margin-bottom: 0 !important;
}

.encuesta .encuestaTipoSiNo div {
    width: fit-content;
    padding-top: 7px;
}

.encuesta .encuestaTipoSiNo label {
    color: white;
    font-weight: 100;
    flex-grow: 1;
    padding-top: 3px;
    margin-left: 10px;
    margin-right: 10px;
}

.encuesta .encuestaTipoSiNo span {
    margin-left: 10px;
    margin-right: 10px;
    vertical-align: 0.1em !important;
}

.encuesta .encuestaTipoSiNo input {
    order: 2;
    flex-grow: 2;
}

.encuesta .encuestaTipoAutoSuggest {
    padding-left: 10px;

}

.encuesta .campoYaExiste {
    color: red !important;
    font-size: 8px;
    margin-bottom: 0 !important;
    margin-top: 10px !important;
}

/*CONTACTO*/
/*quitar flechas*/
.formularioContacto input[type="number"]::-webkit-outer-spin-button, .formularioContacto input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.formularioContacto input[type="number"] {
    -moz-appearance: textfield;
}

/*color de fondo input*/
.formularioContacto input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #00AEEF inset;
}

/*color letra input*/
.formularioContacto input, .formularioContacto select, .formularioContacto textarea {
    color: white !important;
    -webkit-text-fill-color: white !important;
}

/*Autosuggest usuarios*/

.encuesta .encuestaTipoAutoSuggest ul {
    list-style: none;
    position: absolute;
    z-index: 5;
    background-color: white;
    height: 250px;
    overflow-y: auto;
    box-shadow: 3px 3px 10px grey;
    width: 50%;
    padding-left: 10px;
}

.encuesta .encuestaTipoAutoSuggest {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #00ffea;
}

.encuesta .encuestaTipoAutoSuggest p {
    order: 1;
    margin-bottom: 0;
    width: fit-content;
    padding-right: 20px;
}

.encuesta .react-autosuggest__container {
    order: 2;
    width: 50%;
}

.encuesta .encuestaTipoAutoSuggest input {
    border-bottom: none !important;
}

.encuesta .usuarioAutosuggest {
    line-height: 25px;
    font-size: 15px;
    cursor: pointer;
    color: #00aaeb;
    padding: 10px;
}

.encuesta .usuarioAutosuggest .highlight {
    color: black;
}

.encuesta .codCliente {
    font-size: 13px;
}

.encuesta .hrUsuarios {
    margin: 0 0 0 -200px !important;
    border-color: #00aaeb;
}

.login-ingreso input[type="radio"] {
    width: 0 !important;
}

.login-ingreso .subtitulo .label {
    font-size: 13px;
    font-weight: 200;
    padding-top: 7px;
    margin-left: 10px;
}

.login-ingreso textarea {
    border-top: 1px solid #00ffea !important;
    outline: none !important;
}

.login-ingreso input[type="file"] {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}

.login-ingreso .botoneraIngreso .vistaPrevia {
    background-color: transparent !important;
    color: #8edd49 !important;
    border: 2px solid #8edd49 !important;
}

.login-ingreso .textoContacto {
    line-height: 32px;
    padding-top: 10px;
    padding-left: 10px;
    font-size: 18px;
}

.login-ingreso .inputImagen {
    display: flex;
}

.login-ingreso .inputImagen input {
    width: auto !important;
    border: none !important;
}

.login-ingreso .inputImagen img {
    max-height: 45px;
}