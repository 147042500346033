@media screen and (max-width: 767px) {
    .barraCatalogo {
        display: none;
    }
}
.catalogo .titulo{
    text-align: center;
    margin-top: 42px;
    margin-bottom: 20px;
}

.catalogo .titulo h3{
    color:white;
    font-family: "Montserrat";
    font-weight: 300;
    font-size: 16px;
    letter-spacing: 1px;
}

.catalogo .centerNav nav{
    margin-bottom: 0px !important;
    padding-bottom: 18px;
    border-bottom: 1px solid #00ffea !important;
}
.catalogo .centerNav a{
    font-size: 14px;
}

.catalogo .bg-productosHome{
    background-image: url('../img/bg-productosHome.png');
    background-repeat: no-repeat;
    background-position: top right;
    background-attachment: fixed;
}

.catBarraCentro a{
    padding-right: 10px !important;
}