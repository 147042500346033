.container-producto {
    min-height: 272px;
    width: 300px;
    margin: 10px;

    padding: 20px;
    background-color: white;
    border: 1px solid #00ffea;
    border-bottom: 5px solid #00ffea;
    font-family: "Myriad Pro";
    font-weight: 700;
    color: #00aeef;
    cursor: pointer;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1);
    -o-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
    transform: scale(1);
}

.productos-destacados .container-producto{
    max-width: 255px !important;
}

.catalogo-marca .container-producto{
    max-width: 330px !important;
    min-height: 250px;
}

.productos2 .container-producto{
    width: 258px;
}

.container-producto:hover {
    transform: scale(1.015);
}

.container-producto img {
    max-width: 140px;
    width: 100%;
    height: 100%;
    margin-top: 20px;
}

.productoComun {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.productoComun h2 {
    margin: 0;
    -ms-flex-positive: 4;
    color: #00aaeb !important;
    font-size: 19px;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-height: 22px;
    height: 65px;
}

.tituloYDescuento{
    display: contents;
}

.productoComun .contenedorDetalles {
    text-align: center;
}


.sin-decoration{
    text-decoration: none!important;
}

/*RESPONSIVE*/
@media screen and (max-width: 900px) {
    .productoComun h2 {
        font-size: 18px !important;
        max-height: 45px;
    }
}

@media screen and (max-width: 767px) {
    .productoSolo {
        width: 75%;
        margin: 0 auto;
    }

    /*.container-producto {*/
        /*width: 100% !important;*/
    /*}*/

    .productoComun h2 {
        font-size: 13px !important;
    }

    .productoSolo .container-producto {
        margin: 20px;
    }

    /*.productoSolo .contenedorDetalles img {*/
        /*margin-top: 50px !important;*/
    /*}*/
}

@media screen and (max-width: 500px) {
    /*.container-producto {*/
        /*width: 65%;*/
    /*}*/

    .productoComun h2 {
        font-size: 15px !important;
    }
}

@media screen and (max-width: 400px) {
    .productoSolo .container-producto {
        min-height: 190px;
    }

    /*.contenedorDetalles img {*/
        /*max-width: 80px;*/
    /*}*/
}