.navbar-default {
    background-color: transparent !important;
    background-image: none !important;
    color: white !important;
    border: none !important;
    box-shadow: none !important;
    width: 100vw;

}

.navbar .nav,
.navbar .nav > li {
    float:none;
    display: flex;
    vertical-align: top;
    margin: 0 auto;
    max-width: 1260px; /*limite de la barra*/
}

.container > .navbar-header, .container-fluid > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-collapse {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.centerNav .active {
    background-image: none !important;
    background-color: transparent !important;
    box-shadow: none!important;
    border-bottom: 3px solid #00ffea;
    padding-bottom: 2px;
}

.navbar-default .navbar-nav a {
    padding-right: 0px;
    padding-left: 0px;
    height: 40px;
}


.navbar a{
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 18px;
    color:white !important;
    cursor: pointer;
}
.navbar a:hover{
    color: #00ffea !important;
}
.centerNav {
    text-align:center;
}
.centerNav .container{
    width: 100vw;
    padding: 0;
    margin:0;
}

.navbar-toggle{
    padding: 6px !important;
    width: 37px !important;
    height: 32px !important;
}
.navbar-default .navbar-toggle .icon-bar {
    background-color: #00ffea !important;
}
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background-color: transparent !important;
}
.navbar-default .navbar-toggle {
    border-color: white;
}
@media(max-width: 767px){
    .navbar .nav,
    .navbar .nav > li {
        display: list-item;
    }
    .centerNav {
        text-align:left;
    }
    .navbar-default .navbar-nav a {
        height: 35px;
        width: 174px;
    }
    .centerNav .active {
        border-bottom: 3px solid #00ffea;
        padding-bottom: 2px;
    }
}