.checkin{
    background-image: url("../img/evento/bg-cheking.png");
    background-repeat: no-repeat;
    background-position-y: 0px;
    background-position-x: 100%;
    height: 100vh;
    background-size: contain;
}
@media(max-width: 998px) {
    .checkin{
    background-size: cover;

    }
}

.checkin .container-limit{
    max-width: 1320px;
    padding-right: 25px;
    padding-left: 25px;
    padding-top: 90px;
}


.checkin .form-group label{
    text-align: center;
    color: white;
    font-family: "Montserrat";
    font-size: 45px;
    margin: 0px auto;
    margin-top: 100px;
    margin-bottom: 50px;
    display: block;
}

.checkin .form-group input{
    background: none;
    background-color: #00aeef;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #00ffeb;
    border-top: 2px solid #00ffeb;
    color: white;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 30px;
    height: 70px;
    box-shadow: none;
    text-align: center;
    margin-bottom: 90px;
}

.checkin Button{
    background: none;
    box-shadow: none;
    border: none;
    text-shadow: none;
    background-color: #9ae235;
    border-radius: 150px;
    font-size: 25px;
    font-family: "Montserrat";
    font-weight: 400;
    color:#00aaeb;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 50px;
    padding-right: 50px;
    -webkit-transition: ease-in 100ms;
    -moz-transition: ease-in 100ms;
    -ms-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    outline: none;
}

.checkin .btn-default:hover, .checkin .btn-default:focus {
    background-color: #9ae235;
    color:#00aaeb;
    -webkit-transition: ease-in 100ms;
    -moz-transition: ease-in 100ms;
    -ms-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
    outline: none;
}


.checkin h1, .checkin h4{
    color:#5cffe2;
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 110px;
    text-align: center;
    display: block;
    margin: 0px auto;
}
.checkin h1{
    margin-top: 90px;
}
.checkin h4{
    font-size: 37px;
}

.checkin p{
    color: white;
    font-family: "Montserrat";
    font-weight: 200;
    font-size: 25px;
    margin: 60px auto;
    display: block;
    text-align: center;
}

@media(max-width:588px){
    .checkin h1{
        font-size: 40px;
    }
    .checkin h4{
        font-size: 18px;
    }
    .checkin p{
        font-size: 16px;
    }
}

.checkin .lineaCorta{
    background-color: #00ffeb;
    width: 100px;
    height: 10px;
    display: block;
    margin: 15px auto;
}