.soluciones .mejores-proyectos.notebook {
    background-image: url("../img/bg-notebook.png");
    background-repeat: no-repeat;
    background-attachment: scroll;
    height: 100vh;
    background-size: cover;
    background-position: center 40px;
    text-align: center;
    border-bottom: 3px solid #00ffea;
}

.soluciones .mejores-proyectos.notebook h2 {
    color: white;
    font-family: "Montserrat";
    font-weight: 300;
    font-size: 83px;
}

@media (max-width: 460px) {
    .soluciones .mejores-proyectos.notebook h2 {
        font-size: 40px !important;
        margin-top: 50%;
    }
}

.soluciones .mejores-proyectos {
    background-image: url("../img/bg-soluciones.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    height: 100vh;
    text-align: center;
    border-bottom: 2px solid #00ffea;
}

.soluciones .mejores-proyectos h2 {
    color: #9ae235;
    font-family: "Montserrat";
    font-weight: 300;
}

.como-ayudamos {
    background-color: #8ddd49;
    margin-top: 0;
    padding-bottom: 40px;
    border-bottom: 3px solid #00ffea;
}

.como-ayudamos h2 {
    color: white;
    font-family: "Montserrat";
    font-weight: 300;
    font-size: 45px;
    margin-top: 55px;
    text-align: center;
}

.como-ayudamos .owl-text {
    margin-top: 35px;
}

.como-ayudamos .owl-text p {
    color: rgba(255, 255, 255, 0.42);
    font-family: "Myriad Pro";
    font-weight: 100;
    text-align: center;
    font-size: 14px;
}

.como-ayudamos .slider {
    margin-top: 76px;
}

.como-ayudamos .slider .item img {
    width: auto;
    display: block;
    margin: 0 auto;
}

.como-ayudamos .owl-theme .owl-dots {
    position: relative;
    margin-top: 25px
}

.como-ayudamos .slider .owl-theme {
    border-bottom: none;
}

.como-ayudamos .slider .owl-nav {
    width: 100%;
    position: absolute;
    top: 30%;
}

.como-ayudamos .slider .owl-theme .owl-nav [class*=owl-] {
    margin-right: 100px !important;
    margin-left: 100px !important;
}

.como-ayudamos .xy-grafico {
    display: flex;
    margin: 0 auto;
    max-width: 530px;
    margin-top: 40px;
}

.como-ayudamos .xy-grafico p {
    color: #00aeef;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 20px;
    margin-left: 20px;
    position: relative;
    top: 20px;
}

.compra-infalible {
    background-image: url("../img/instalador.png");
    padding-top: 80px;
    padding-bottom: 80px;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #97e059;
    color: #00aeef;
    border-bottom: 3px solid #00ffea;

}

.compra-infalible img {
    margin-top: 60px;
    margin-left: 40px;
}

@media(max-width: 768px){
    .compra-infalible img{
        max-width: 200px;
    }
    .compra-infalible {
        background-size: cover;
    }
}

@media (max-width: 400px) {
    .compra-infalible img {
        margin-left: 0px;
    }
}

.compra-infalible h2 {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 52px;
    margin-top: 42px;
    max-width: 300px;
}

@media (max-width: 400px) {
    .compra-infalible h2 {
        font-size: 30px;
    }
}

.compra-infalible p {
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 15px;
    max-width: 300px;
    margin-top: 40px;
}

.compra-infalible a {
    color: #00aeef;
    text-decoration: underline;
    position: relative;
    top: 30px
}

.soluciones-registrate {
    background-image: url("../img/bg-registrate.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    padding-bottom: 50px;
    border-bottom: 3px solid #00ffea;
}

.soluciones-registrate h2 {
    color: white;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 50px;
    text-align: center;
    margin-top: 90px;
    margin-bottom: 30px;
}

.soluciones-registrate p {
    color: white;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 17px;
    text-align: center;
}

.soluciones-registrate .features {
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
}

.soluciones-registrate .features p {
    text-align: left;
    padding-left: 30px;
    margin-top: 35px;
    line-height: 18px;
}

.soluciones-registrate button {
    margin-top: 50px;
    background: none !important;
    border: none !important;
    background-color: #8ddd49 !important;
    color: white !important;
    text-shadow: none !important;
    width: auto;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 50px;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1);
    transition: all 200ms ease-in;
    transform: scale(1);
    font-family: "Myriad Pro";
    font-weight: 200;
    font-size: 20px;
}

.soluciones-registrate button:hover {
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1.1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1.1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1.1);
    transition: all 200ms ease-in;
    transform: scale(1.1);
}

.soluciones .owl-text .owl-item.active.center p {
    color: #00aeef;
    border-bottom: 2px solid #00aeef;
    padding-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.como-ayudamos .slider img {
    height: 250px;
}

.soluciones .slider .item p {
    color: white;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 18px;
    text-align: center;
    margin-bottom: 36px;

}
@media(min-width: 768px){
    .soluciones .slider .item p{
        display: none;
    }
}
/*.marcaSlider svg path {*/
/*fill: white;*/
/*}*/

/*.marcaSlider svg polyline {*/
/*fill: white;*/
/*}*/

/*.marcaSlider svg polygon {*/
/*fill: white;*/
/*}*/

/*.marcaSlider svg circle {*/
/*fill: white;*/
/*}*/

/*.marcaSlider svg title {*/
/*fill:white;*/
/*}*/