.header{
    padding-top: 3%;
    margin-bottom: 5px;
}
.header img:first-child{
    max-width: 200px;
}
.logo{
    margin-left: 20px;
    margin-bottom: 20px;
}

.header ul, .header a{
    text-align: right;
    margin-top: 26px;
    color:#00ffea;
}
.header li{
    display: inline-block;
    text-align: right;
    margin-left: 40px;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 14px;
    cursor: pointer;
}

.header li span{
    padding-right: 4px;
}

.header .icon-responsive li{
    margin-left: 10px;
}

@media(max-width: 1280px){
    .logo{
        width: 70px;
    }
}

.header .img-institucional{
    content: url("../img/home.png");
}

.header .active .img-institucional {
    content: url("../img/home-selected.png");
}

.header a:hover, a:focus {
    text-decoration: none;
}

.header .img-login{
    content: url("../img/tool.png");
}

.header .active .img-login {
    content: url("../img/login-active.png");
}