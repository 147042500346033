.preguntas {
    background-color: #0095da;
    height: 100%;
    padding-left: 15px;
    padding-right: 15px;
    min-height: 100vh;
}

.preguntas p {
    color:white;
}

/*HEADER*/

.preguntas .headerRoad {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    margin-top: 70px;
    flex-direction: row;
    flex-wrap: wrap;
}

.preguntas .headerRoad h1 {
    color: white;
    margin: 100px 0 0 30px;
}


.preguntas .headerRoad img {
    order: 0;
}

.preguntas .headerRoad h1 {
    order: 1;
}

/*HEADER RESPONSIVE*/

@media screen and (max-width: 1050px) {
    .preguntas .headerRoad h1 {
        font-size: 30px;
    }

    .preguntas .headerRoad img {
        height: 235px;
        width: 230px;
    }
}

@media screen and (max-width: 900px) {
    .preguntas .headerRoad h1 {
        font-size: 27px;
    }

    .preguntas .headerRoad img {
        height: 215px;
        width: 210px;
    }
}

@media screen and (max-width: 750px) {
    .preguntas .headerRoad h1 {
        font-size: 20px;
    }

    .preguntas .headerRoad img {
        height: 205px;
        width: 200px;
    }
}

@media screen and (max-width: 560px) {
    .preguntas .headerRoad h1 {
        font-size: 17px;
    }

    .preguntas .headerRoad img {
        height: 195px;
        width: 190px;
    }
}

@media screen and (max-width: 462px) {
    .preguntas .headerRoad h1 {
        margin: 30px 0 0 30px;
    }
}

@media screen and (max-width: 447px) {
    .preguntas .headerRoad h1 {
        margin: 30px 0 0 15px;
    }
}

/*SECCION JUGAR*/

.preguntas .seccion-jugar {
    margin-top: 50px;
}

/*placeholder inputs*/
.preguntas .seccion-jugar ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    font-weight: 300;
}
.preguntas .seccion-jugar :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
}

.preguntas .seccion-jugar ::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
}



/*quitar flechas input*/
.preguntas .seccion-jugar input {
    border: none;
    background-color: #00a5e5 !important;
    color: white;
    font-weight: 300;
}


.preguntas .seccion-jugar input[type=number]::-webkit-inner-spin-button,
.preguntas .seccion-jugar input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.preguntas .seccion-jugar input[type=number] {
    -moz-appearance: textfield;
}

.preguntas .cartelCorrecto {
    font-size: 30px;
    text-align: center;
}

/*JUGAR*/
/*boton enviar*/
.preguntas Button.boton {
    background: none;
    box-shadow: none;
    border: none;
    text-shadow: none;
    background-color: #00aff2;
    font-size: 25px;
    font-family: "Montserrat";
    font-weight: 200;
    color: #ccebfa;
    margin-top: 50px;
    padding-left: 40px;
    padding-right: 40px;
    -webkit-transition: ease-in 100ms;
    -moz-transition: ease-in 100ms;
    -ms-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    outline: none;
}

.preguntas .btn-default.boton:hover, .preguntas .btn-default.boton:focus {
    background-color: #00aff2;
    color: #ccebfa;
    -webkit-transition: ease-in 100ms;
    -moz-transition: ease-in 100ms;
    -ms-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
    outline: none;
}

.preguntas .textoInscripcion {
    text-align: center;
    margin-top: 10px;
    font-size: 12px;
}

/*PREMIOS*/
.preguntas .titulo-premios {
    margin-bottom: 20px;
    font-size: 20px;
}

.preguntas .premio {
    background-color: #00a5e5;
    min-height: 30px;
    margin-bottom: 35px;
    padding-left: 5px;
    display: flow-root;
}

.preguntas .premio p {
    font-size: 20px;
    float:left;
}

.preguntas .premio img {
    float:right;
}

.preguntas .imgPremio1 {
    width:73px;
    margin-top: -10px;
    margin-right: 14px;
}

.preguntas .imgPremio2 {
    width:45px;
    margin-top: -10px;
    margin-right: 28px;
}

.preguntas .imgPremio3 {
    width:45px;
    margin-top: -10px;
    margin-right: 28px;
}

/*RESPONSIVE PREMIOS*/
@media screen and (max-width: 600px) {
    .preguntas .titulo-premios {
        font-size: 16px;
    }

    .preguntas .premio p {
        font-size: 15px;
        padding-top:3px;
    }
}

@media screen and (max-width:450px) {
    .preguntas .imgPremio1 {
        width:60px;
        margin-top: -8px;
        margin-right: 14px;
    }

    .preguntas .imgPremio2 {
        width:35px;
        margin-top: -6px;
        margin-right: 28px;
    }

    .preguntas .imgPremio3 {
        width:35px;
        margin-top: -6px;
        margin-right: 28px;
    }
}

@media screen and (max-width:390px) {
    .preguntas .titulo-premios {
        font-size: 14px;
    }

    .preguntas .premio p {
        font-size: 12px;
        padding-top:6px;
    }
}


/*registro error*/
.preguntas .registro-error {
    text-align: center;
    margin: 15px auto;
    display: block;
    font-family: "Montserrat";
    font-weight: 100;
    font-size: 20px;
    color: #00aff2;

}

.preguntas .registro-error {
    text-align: center;
    margin: 15px auto;
    display: block;
    font-family: "Montserrat";
    font-weight: 100;
    font-size: 20px;
    color: #00ffeb;
}