.integrador {
    border-top: 5px solid #00ffea;
    border-bottom: 5px solid #00ffea;
    background-color: #8ddd49;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
}

.integrador h4 {
    color: white;
    display: inline-flex;
    font-family: "Myriad Pro";
    font-weight: 200;
    font-size: 2.5rem;

}

.integrador span {
    border-bottom: 2px solid #00aeef;
    margin-right: 20px;
}