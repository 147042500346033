.FieldGroupButton .input-group-addon {
    background: none;
    color: #00aff2;
    background-color: #00a5e5;
    border: none;
    border-radius: 0;
    /* border-top: 1px solid #66cdf7; */
    /* border-bottom: 2px solid #00aff2; */
}

.FieldGroupButton button {
    margin: inherit !important;
    background: none !important;
    color: #00aff2 !important;
    padding: 0 !important;
    border: none;
}