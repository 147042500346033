.bultos {
    background-color: white;
    width: 100%;
    padding: 0 50px 50px 50px;
    color: #00aaeb;
    min-height: 57vh;
}

.bultos .titulo {
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
}

.bultos .titulo h3 {
    color: #00aaeb;
    margin-bottom: 20px;
    float: left;
}

.bultos .titulo p {
    float: left;
    padding-top: 10px;
    cursor: pointer;
    margin-left: 5px;
}

/*TABLA*/
.bultos table {
    border-right: 1px solid #00ffea;
}
.bultos .tableHead {
    border-top: 3px solid #00aaeb;
    border-bottom: 3px solid #00aaeb;
}

.bultos .tableHead th {
    font-family: Montserrat;
    font-weight: 300;
    font-size: 10px;
    border-left: 1px solid #00ffea;
    text-align: center;
    color: #00aaeb;
    text-transform: uppercase;
    padding: 10px;
}

.bultos .columnaProductos:last-child {
    border-bottom: 3px solid #00aaeb;
}

.bultos .columnaProductos td {
    border-left: 1px solid #00ffea;
    border-bottom: 2px solid #00aaeb;
    border-top: 2px solid #00aaeb;
    text-align: center;
    font-size: 14px;
    color: #00aaeb;
    padding: 10px;
}

.bultos .imagenTabla {
    width: 100px;
    height: 100px;
    border-top: 1px solid #00ffeb;
    border-bottom: 7px solid #00ffeb;
}

.bultos .kit td {
    border-bottom: 0 !important;
    border-top: 0 !important;
}

.bultos .kit td h5 {
    margin-top: 0;
    margin-bottom: 0;
}

.bultos .verMas {
    font-size: 11px;
}

.bultos .primera {
    border-top: 2px solid #00aaeb !important;
}


.bultos .columnaProductos:last-child {
    border-bottom: 3px solid #00aaeb;
}

.bultos .columnaProductos td {
    border-left: 1px solid #00ffea;
    border-bottom: 2px solid #00aaeb;
    border-top: 2px solid #00aaeb;
    text-align: center;
    font-size: 14px;
    color: #00aaeb;
    padding: 10px;
}

.bultos .columnaProductos td:first-child {
    padding-left: 0;
    padding-right: 0;
    padding-top: 20px;
}

.bultos .columnaProductos td:last-child {
    border-right: 1px solid #00ffea;
}

.bultos .columnaDetalles td {
    border-bottom: 3px solid #00aaeb;
    border-right: 1px solid #00ffea;
    border-left: 1px solid #00ffea;
    text-align: center;
}

.bultos .btn-default {
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    text-shadow: none;
    background-color: #00aaeb;
    border-radius: 150px;
    font-size: 25px;
    font-family: "Montserrat";
    font-weight: 400;
    color: white;
    /*margin-top: 50px;*/
    padding-left: 60px;
    padding-right: 60px;
    -webkit-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    outline: none;
    font-family: "Myriad Pro";
    font-weight: 100;
}

.bultos .btn-default:hover {
    background-color: #00aaeb;
    color: white;
    -webkit-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    outline: none;
}
