.tecnico{
    background-color: #8ddd49;
    background-image: url('../img/tecnico.png');
    background-repeat: no-repeat;
    background-position: right bottom;
    width: 100%;
    padding-top: 100px;
    border-bottom: 4px solid #00ffea;
    padding-bottom: 80px;
    background-size: 52%;
}


.tecnico h2{
    font-family: "Montserrat";
    font-weight: 400;
    color:white;
    font-size: 100px;
    letter-spacing: -3px;
    margin-top: 37px;
}

.tecnico h2 span{
    color:#00aeef;
}

.tecnico p{
    color: white;
    font-size: 22px;
    line-height: 46px;
    font-family: "Myriad Pro";
    font-weight: 100;
    margin-top: 60px;
}

.tecnico a{
    color: white;
    font-family: "Myriad Pro";
    font-family: 700;
    font-size: 22px;
    text-decoration: underline;
}

.tecnico Button {
    background-image: none;
    border: none;
    border-radius: 50px;
    width: 223px;
    text-shadow: none !important;
    color: white !important;
    padding-bottom: 2px;
    height: 50px;
    background-color: #00aeef !important;
    font-family: "Myriad Pro" !important;
    font-weight: 500 !important;
    font-size: 18px;
    float: right;
    margin-top: 13px;
    pointer:cursor;
}
.tecnico Button:hover{
    background-color: #0084b9 !important;
}

.tecnico h3{
    font-family: "Montserrat";
    font-weight: 400;
    color:#00aeef;
    font-size: 50px;
    letter-spacing: -1px;
    margin-top: 68px;
}

.tecnico h3 span{
    color:white;
}

.sliderFeatures{
    padding-left: 20px;
    padding-right: 20px;
}

.sliderFeatures .owl-theme{
margin-top: 50px;

}

.sliderFeatures .owl-nav{
    width: 100%;
    position: absolute;
    top:20%;
}

.sliderFeatures .owl-theme .owl-nav [class*=owl-] {
    width: 30px; /*or your image's width*/
    height: 100px; /*or your image's height*/
    background-repeat: no-repeat !important;
    background-color: transparent !important;
    border-radius: none;
    margin-right: -30px !important;
    margin-left: -30px !important;
}

.sliderFeatures .owl-theme .owl-next{
    background-image: url("../img/next.png") !important;
    float: right;
}

.sliderFeatures .owl-theme .owl-prev{
    background-image: url("../img/prev.png") !important;
    float:left;
}
.sliderFeatures .owl-theme .item div{
    max-width: 220px;
    max-height: 220px;
    text-align: center;
    margin: 0 auto;
    background-color: #00aeef;
    padding-top: 20px;
}

.sliderFeatures .owl-theme .item div img{
    width: auto;
    height: auto;
    text-align: center;
    float: none;
    margin: 0 auto;
    background-color: #00aeef;
}



.sliderFeatures .owl-theme .owl-dots{
    position: relative !important;
    margin-top: 60px !important;
}

.sliderFeatures .owl-theme .item div p{
    margin-top: 35px;
    padding-bottom: 34px;
    line-height: 15px;
    margin-right: 20px;
    margin-left: 20px;
    font-size: 14px;
}

.bigRegistro{
    text-align: center !important;
}

.bigRegistro Button{
    width: 300px;
    height: 58px;
    margin-top: 80px;
    float: none;
    letter-spacing: 2px;
}

@media(max-width: 768px){
    .sliderFeatures .owl-theme .item div{
        max-width: 160px;
        max-height: 160px;
        padding-top: 10px;
    }

    .sliderFeatures .owl-theme .item div img{
        max-width: 100px;
        max-height: 100px;
    }

    .sliderFeatures .owl-theme .item div p{
        margin-top: 15px;
        padding-bottom: 14px;
        font-size: 10px;
    }


    .tecnico h2{
        font-size: 60px;
        letter-spacing: -3px;
        margin-top: 37px;
    }

    .tecnico h3{
        font-size: 30px;
    }


}

@media (max-width: 568px){
          .tecnico h2 {
              font-size: 40px;
              letter-spacing: -3px;
              margin-top: 37px;
          }
    .tecnico h3{
        font-size: 20px;
    }

    .bigRegistro Button{
        width: 200px;
        font-size: 12px;
    }
    .tecnico p {
        line-height: normal;
    }
}

@media(max-width: 1210px){
    .sliderFeatures .owl-theme .owl-nav [class*=owl-] {
        margin-right: -10px !important;
        margin-left: -10px !important;
    }

}

@media(max-width: 1170px){
    .sliderFeatures .owl-theme .owl-nav [class*=owl-] {
        display: none;
    }

}