.lista-monitor-pagos {

}

.lista-monitor-pagos .container-tags {
    background-color: white;
    border-radius: 20px;
    margin: auto;
    width: 100%;
}

.lista-monitor-pagos .container-tags.sticky {
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 9;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.18);
}

.lista-monitor-pagos .container-tags .tags ul {
    color: #00aeef;
    list-style: none;
    display: flex;
    font-weight: 100;
    font-size: 25px;
    padding: 0px;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    margin-bottom: 0;
}

@media (max-width: 768px) {
    .lista-monitor-pagos .container-tags .tags ul {
        font-size: 18px;
    }
}

.lista-monitor-pagos .container-tags .tags ul.disabled li {
    cursor: not-allowed;
}

.lista-monitor-pagos .container-tags .tags ul li {
    width: 50%;
    list-style: none;
    text-align: center;
    border-right: 2px solid #00aeef;
    border-bottom: 2px solid #00aeef;
    background-color: #ddd;
    color: #adadad;
    cursor: pointer;
}

.lista-monitor-pagos .container-tags .tags ul li:nth-child(1) {
    border-top-left-radius: 20px;
}

.lista-monitor-pagos .container-tags .tags ul li:last-child {
    border-top-right-radius: 20px;
    border-right: none;
}

.lista-monitor-pagos .container-tags .tags ul li.active {
    border-bottom: 2px solid white;
    background-color: white;
    color: #00aeef;
}

.lista-monitor-pagos .container-tags .tags ul li a {
    all: unset;
    width: 100%;
    height: 100%;
    padding: 20px 10px;
    display: block;
}

.lista-monitor-pagos .ajustes{
    text-align: end;
    color: white;
    font-size: 15px;
    font-family: "Myriad Pro", sans-serif;
    font-weight: 100;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    vertical-align: center;
    align-items: baseline;
    cursor: pointer;
}

.lista-monitor-pagos .ajustes span{
    margin-right: 5px;
}