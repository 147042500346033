.footer{
    color: white;
}

.footer h2{
    color: #ccebfb;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 70px;
    margin-bottom: 0px;
    letter-spacing: 2px;
}

.footer h3{
    color: white;
    font-family: "Myriad Pro";
    font-weight: 200;
    font-size: 16px;
    border: 1px solid #00ffea;
    padding-bottom: 10px;
    padding-top: 15px;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 50px;
}

.footer .fecha{
    font-family: "Myriad Pro";
    font-weight: 200;
    font-size: 12px;
    text-align: center;
    padding-top: 13px;
}

.footer .evento{
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 15px;
    max-width: 180px;
    border-left:1px solid #00ffea;
    padding-left: 30px;
}

.footer .spacing-table{
    border-bottom: 1px solid #00ffea;
    margin-top: 20px;
    padding-bottom: 20px;
    margin-left: 4px;
}

.footer .spacing-table:last-child{
    border-bottom: none;
}

.footer h4{
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 30px;
    margin-top: 72px;
}

.footer h4 span {
    color:#8ddd49;
}
.footer a{
    display: flex;
    color: white;

}

.footer a:hover{
    color: #00ffea !important;
}
.footer a img{
    width: 32px;
    height: 32px;

}

.footer a p{
    padding-left: 16px;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 13px;
    margin-top: 6px;
    margin-bottom: 15px;
}

.footer .table-sociales{
    max-width: 160px;
}

.center-foot{
    float: none;
    margin: 0 auto;
    max-width: 172px;
}

.footer .table-ayuda {
    max-width: 180px !important;
}
.footer .table-ayuda .spacing-table{
    margin-left: 0px;
    padding-bottom: 0px;

}

.footer .table-ayuda a p{
    padding-left: 0px;
    margin-top: 0px;
    margin-bottom: 19px;
}


.footer .primer-col img{
    margin-top: 72px;
    max-width: 220px;
}

.footer .primer-col p{
    padding-top: 60px;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 20px;
    line-height: 32px;
}
@media(max-width: 600px) {

    .center-foot {
        float: none;
        max-width: 272px;
    }

    .footer .table-ayuda {
        max-width: 272px !important;
    }

    .footer .foot-uno{
        max-width: 262px !important;
        margin:0 auto;
    }
}