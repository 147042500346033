@font-face {
    font-family: "Myriad Pro";
    font-weight: 700;
    src: local("?"),
    url("../../assets/font/MyriadPro-Bold.woff") format("woff"),
    url("../../assets/font/MyriadPro-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Myriad Pro";
    font-weight: 500;
    src: local("?"),
    url("../../assets/font/MyriadPro-Semibold.woff") format("woff"),
    url("../../assets/font/MyriadPro-Semibold.ttf") format("truetype");
}

@font-face {
    font-family: "Myriad Pro";
    font-weight: 200;
    src: local("?"),
    url("../../assets/font/MyriadPro-Regular.woff") format("woff"),
    url("../../assets/font/MyriadPro-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "Myriad Pro";
    font-weight: 100;
    src: local("?"),
    url("../../assets/font/MyriadPro-Light.woff") format("woff"),
    url("../../assets/font/MyriadPro-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat";
    font-weight: 200;
    src: local("?"),
    url("../../assets/font/Montserrat-Regular.woff") format("woff"),
    url("../../assets/font/Montserrat-Regular.ttf") format("truetype"),
    url("../../assets/font/Montserrat-Regular.otf") format("otf");


}
@font-face {
    font-family: "Montserrat";
    font-weight: 500;
    src: local("?"),
    url("../../assets/font/montserrat-hair.woff") format("woff"),
    url("../../assets/font/montserrat-hair.ttf") format("truetype"),
    url("../../assets/font/Montserrat-Hairline.otf") format("otf");
}
@font-face {
    font-family: "Montserrat";
    font-weight: 100;
    src: local("?"),
    url("../../assets/font/Montserrat-Light.woff") format("woff"),
    url("../../assets/font/Montserrat-Light.ttf") format("truetype"),
    url("../../assets/font/Montserrat-Light.otf") format("otf");
}
@font-face {
    font-family: "Montserrat";
    font-weight: 400;
    src: local("?"),
    url("../../assets/font/Montserrat-SemiBold.woff") format("woff"),
    url("../../assets/font/Montserrat-SemiBold.ttf") format("truetype"),
    url("../../assets/font/Montserrat-SemiBold.otf") format("otf");
}

@font-face {
    font-family: "Montserrat";
    font-weight: 300;
    src: local("?"),
    url("../../assets/font/Montserrat-Bold.woff") format("woff"),
    url("../../assets/font/Montserrat-Bold.ttf") format("truetype"),
    url("../../assets/font/Montserrat-Bold.otf") format("otf");
}