.login-portada {
    background-image: url("../img/bg_login.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100vh - 150px);
    border-bottom: 2px solid #00ffea;
    border-top: 2px solid #00ffea;
    min-height: 500px;
}

@media (min-width: 769px) {
    .login-portada .recuadro {
        position: relative;
        top: 12vh;
        padding-left: 120px;
    }
}

@media (max-width: 769px) {
    .login-portada .recuadro {
        position: relative;
        top: 40px;
        padding-left: 20px;
    }
}

@media (min-width: 769px) {
    .login-portada .recuadro h2 {
        color: white;
        font-family: "Myriad Pro";
        font-weight: 100;
        font-size: 40px;
        line-height: 26px;
        margin: 0;
        padding-bottom: 50px;
        padding-left: 0px;
    }
}

@media (max-width: 769px) {
    .login-portada .recuadro h2 {
        color: white;
        font-family: "Myriad Pro";
        font-weight: 100;
        font-size: 20px;
        margin: 0;
        padding-bottom: 50px;
        padding-left: 45px;
    }
}

@media (min-width: 769px) {
    .login-portada .recuadro h1 {
        color: white;
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 70px;
        line-height: 76px;
        margin: 0;
        padding-bottom: 30px;
    }
}

@media (max-width: 769px) {
    .login-portada .recuadro h1 {
        color: white;
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 30px;
        margin: 0;
        padding-bottom: 30px;
    }
}

@media (min-width: 769px) {
    .login-portada .recuadro p {
        font-family: "Myriad Pro";
        font-weight: 100;
        font-size: 20px;
        color: white;
    }
}

@media (max-width: 769px) {
    .login-portada .recuadro p {
        font-family: "Myriad Pro";
        font-weight: 100;
        font-size: 15px;
        color: white;
    }
}

.login-portada .recuadro .separador-verde {
    width: 90px;
    height: 7px;
    background-color: #9ae235;
    margin-top: 60px;
}

.login-ingreso{
    border-bottom: 4px solid #00ffea;
    padding-bottom: 60px;
}

@media (min-width: 769px) {
    .login-ingreso .recuadro {
        position: relative;
        padding-top: 60px;
        padding-left: 72px;
    }
}

@media (max-width: 769px) {
    .login-ingreso .recuadro {
        position: relative;
        padding-top: 40px;
    }
}

.login-ingreso .recuadro p {
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 20px;
    color: white;
}

.login-ingreso .recuadro form{
    border-top: 2px solid #00ffea;
    margin-top: 30px;
    margin-bottom: 30px;
}


.login-ingreso .recuadro input, .login-ingreso textarea{
    font-family: "Myriad Pro";
    font-weight: 100;
    color: white;
    line-height: 56px;
    font-size: 20px;
    width: 100%;
    background-color: #00AEEF;
    background: none;
    border: none;
    border-bottom: 2px solid #00ffea;
}

.login-ingreso .recuadro input:focus {
    outline: none;
}

.login-ingreso .recuadro input::-webkit-input-placeholder, .login-ingreso textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: white;
}
.login-ingreso .recuadro input::-moz-placeholder, .login-ingreso textarea::-webkit-input-placeholder { /* Firefox 19+ */
    color: white;
}
.login-ingreso .recuadro input:-ms-input-placeholder, .login-ingreso textarea::-webkit-input-placeholder { /* IE 10+ */
    color: white;
}
.login-ingreso .recuadro input:-moz-placeholder, .login-ingreso textarea::-webkit-input-placeholder { /* Firefox 18- */
    color: white;
}


.login-ingreso .recuadro select{
    font-family: "Myriad Pro";
    font-weight: 100;
    color: white;
    line-height: 56px;
    font-size: 20px;
    width: 100%;
    background-color: #00AEEF;
    border: none;
    border-bottom: 2px solid #00ffea;
    height: 60px;
    -webkit-appearance: none !important;
    border-radius: 0!important;
    background-image: url("../img/iconLista/expand.png");
    background-repeat: no-repeat;
    background-position-x: 99%;
    background-position-y: 50%;
    background-size: 29px;
}

.login-ingreso .recuadro select:focus {
    outline: none;
}

.login-ingreso .recuadro select::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: white;
}
.login-ingreso .recuadro select::-moz-placeholder { /* Firefox 19+ */
    color: white;
}
.login-ingreso .recuadro select:-ms-input-placeholder { /* IE 10+ */
    color: white;
}
.login-ingreso .recuadro select:-moz-placeholder { /* Firefox 18- */
    color: white;
}

.login-ingreso .recuadro a{
    font-family: "Myriad Pro";
    font-weight: 100;
    color: white;
    line-height: 56px;
    font-size: 17px;
}

.login-ingreso button{
    margin-top: 50px;
    background: none!important;
    border: none !important;
    background-color: #8ddd49 !important;
    color: #00AEEF !important;
    text-shadow: none !important;
    width: auto;
    padding-bottom: 36px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 50px;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1);
    transition: all 200ms ease-in;
    transform: scale(1);
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 20px;
}

.login-ingreso button:hover{
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1.1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1.1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1.1);
    transition: all 200ms ease-in;
    transform: scale(1.1);
}

.botoneraIngreso{
    text-align: center;
}

.separador-celeste {
    width: 250px;
    height: 4px;
    background-color: #00ffeb;
    margin:auto;
    margin-top: 60px;
    margin-bottom: 50px;
}

.encuesta .table>thead>tr>th{
    border:none
}


.encuesta table{
    border-collapse: separate;
}

.encuesta table thead tr th, .encuesta td{
    padding-left: 15px !important;
}
.encuesta thead th { border: solid 2px #00ffea !important;  }
.encuesta th:first-child { border-left-color: transparent!important; }
.encuesta th:last-child { border-right-color: transparent!important; }
.encuesta .noBorder-left{
    border-left: none!important;
}
.encuesta .noBorder-right{
    border-right: none !important;
}

.encuesta  .table>tbody>tr>td{
    border:none;
}
.encuesta .spacer{
    height: 15px;
}
.encuesta table th{
    width: 200px;
    color:white;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 1.6rem !important;
}
.encuesta table th:first-child{
    font-size: 1.4rem;
}

.encuesta table td, .encuesta table th{
    text-align: center;
}
.encuesta table td:first-child {
    text-align: left;
    width: 520px !important;
    color:white;
    font-family: "Myriad Pro", sans-serif;
    font-weight: 100;
    font-size: 1.4rem;

}
.encuesta .table>tbody>tr>td{
    padding: 4px;
}
.encuesta .table-responsive{
    border:none;
}
.encuesta .no-center {
    text-align: left;
}

[type="radio"] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px; margin: 5px;
    overflow: hidden;
    padding: 0;
/*visibility: hidden;*/
    position: absolute;
    width:0;
}


/* the basic, unchecked style */
[type="radio"] + span:before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: -0.25em;
    border-radius: 2.4em;
    border: 0.125em solid #00adee;
    box-shadow: 0 0 0 0.15em #00ffea;
    /*margin-right: 0.75em;*/
    transition: 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28) all;
}

/* the checked style using the :checked pseudo class */
[type="radio"]:checked + span:before {
    background: #00ffea;
    box-shadow: 0 0 0 0.25em #00ffea;
}