.checkout {
    background-color: white;
    padding-bottom: 50px;
}

.checkout .inicioKit {
    margin-top: 35px;
    float: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

/*Imagenes de kit e inicio*/
.checkout .inicioKit img {
    width: fit-content;
}

/*Imagen de la palabra inicio*/
.checkout .inicioKit .inicio {
    margin: 40px 0 60px 0;
}

@media screen and (max-width: 600px) {
    .checkout .inicioKit img {
        max-width: 50px;
    }

    .checkout .inicioKit img:last-child {
        max-width: 14px;
    }
}

.checkout .tituloCarrito {
    color: #00aaeb;
}