.progreso-compra {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 33.333333% 33.333333% 33.333333%;
    grid-template-columns: 33.333333% 33.333333% 33.333333%;
    position: relative;
}

.progreso-compra .paso {
    text-align: center;
    position: relative;
    z-index: 2;
}

.progreso-compra::after {
    content: '';
    background-color: #CCC;
    position: absolute;
    width: 100%;
    top: 14px;
    height: 3px;
    left: 0;
    z-index: 1;
}

.progreso-compra .paso.active::after, .progreso-compra .paso.done::after {
    content: '';
    background-color: #5CFFF3;
    position: absolute;
    width: 50%;
    top: 14px;
    height: 3px;
    left: 0;
    z-index: -1;
}

.progreso-compra .paso.done::after {
    width: 100%;
}

.progreso-compra .paso .bullet {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    color: #002B50;
    background-color: #CCC;
    text-align: center;
    margin: 0 auto;
    cursor: pointer;
}

.progreso-compra .paso.active .bullet {
    background-color: #5CFFF3;
}

.progreso-compra .paso.done .bullet {
    background-color:#5CFFF3;
}

.progreso-compra .paso span {
    font-size: 14px;
    text-transform: uppercase;
    color: #CCC;
    font-weight: 300;
    padding-top: 10px;
    font-family: "Gill Sans";
}

.progreso-compra .paso.active span {
    color: #002B50;
}

.progreso-compra .paso.done span {
    color: #CCC;
}

@media screen and (max-width: 600px) {
    .progreso-compra span {
        font-size: 8px !important;
    }

    .progreso-compra .paso .bullet {
        width: 20px;
        height: 20px;
        font-size: 16px;
        line-height: 21px;
    }

    .progreso-compra::after {
        top: 9px;
    }

    .progreso-compra .paso.active::after, .progreso-compra .paso.done::after {
        top: 9px;
    }
}

@media screen and (max-width: 300px) {
    .progreso-compra span {
        font-size: 6px !important;
    }
}
