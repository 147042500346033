.resumenCarrito {
    background-color: white;
    padding-bottom: 50px;
}

.resumenCarrito .inicioKit {
    margin-top: 35px;
    float: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

/*Imagenes de kit e inicio*/
.resumenCarrito .inicioKit img {
    width: fit-content;
}

/*Imagen de la palabra inicio*/
.resumenCarrito .inicioKit .inicio {
    margin: 40px 0 60px 0;
}

@media screen and (max-width: 600px) {
    .resumenCarrito .inicioKit img {
        max-width: 50px;
    }

    .resumenCarrito .inicioKit img:last-child {
        max-width: 14px;
    }
}

.resumenCarrito .tituloCarrito {
    color: #00aaeb;
}

/*Tabla resumen*/
.resumenCarrito .tableHead {
    border-top: 3px solid #00aaeb;
    border-bottom: 3px solid #00aaeb;
}

.resumenCarrito .tableHead th {
    font-family: Montserrat;
    font-weight: 300;
    font-size: 10px;
    border-left: 1px solid #00ffea;
    text-align: center;
    color: #00aaeb;
    text-transform: uppercase;
    padding: 10px;
}

@media screen and (max-width: 767px) {
    .resumenCarrito .tableHead th {
        font-size: 8px;
    }

    .resumenCarrito .columnaProductos td {
        font-size: 12px !important;
    }

    .resumenCarrito .nombreProducto {
        font-size: 12px !important;
    }

    .resumenCarrito .verMas {
        font-size: 10px !important;
    }

    .resumenCarrito .download-table-xls-button {
        font-size: 14px !important;
        background-position-y: 10px !important;
        margin-top: 3px;
    }

    .resumenCarrito .buttonVolver {
        font-size: 14px !important;
    }
}

@media screen and (max-width: 652px) {
    .resumenCarrito .tableHead th {
        font-size: 7px;
    }

    .resumenCarrito .columnaProductos td {
        font-size: 11px !important;
    }

    .resumenCarrito .nombreProducto {
        font-size: 11px !important;
    }

    .resumenCarrito .verMas {
        font-size: 9px !important;
    }

    .resumenCarrito .textoListaPrecios {
        font-size: 12px !important;
    }

    .resumenCarrito .comboListaPrecios {
        padding: 0 15px 0 15px !important;
        margin-left: 0 !important;
        font-size: 12px;
    }
}

@media screen and (max-width: 476px) {
    .resumenCarrito .download-table-xls-button {
        float: left !important;
        margin-right: 200px;
    }
}

@media screen and (max-width: 403px) {
    .resumenCarrito .botonInferiorVolver {
        margin-top: 20px;
    }
}

@media screen and (max-width: 256px) {
    .resumenCarrito .botonInferiorEnviar {
        margin-right: 0;
    }
}

@media screen and (max-width: 444px) {
    .resumenCarrito .download-table-xls-button {
        font-size: 11px !important;
        background-position-y: 8px !important;
        width: 140px !important;
    }

    .resumenCarrito .buttonVolver {
        font-size: 11px !important;
    }
}

.resumenCarrito .tableHead th:last-child {
    border-right: 1px solid #00ffea;
}

.resumenCarrito .columnaProductos:last-child {
    border-bottom: 3px solid #00aaeb;
}

.resumenCarrito .columnaProductos td {
    border-left: 1px solid #00ffea;
    border-bottom: 2px solid #00aaeb;
    border-top: 2px solid #00aaeb;
    text-align: center;
    font-size: 14px;
    color: #00aaeb;
    padding: 10px;
}

.resumenCarrito .kit td {
    border-bottom: 0px solid #00aaeb;
    border-top: 0px solid #00aaeb;
}

.resumenCarrito .kit td h5 {
    margin-top: 0;
    margin-bottom: 0;
}

.resumenCarrito .botonMasMenos {
    width: 30px;
    margin: 0 5px 0 5px;
}

.resumenCarrito .primera {
    border-top: 2px solid #00aaeb !important;
}

.resumenCarrito .verMas {
    font-size: 11px;
}

.resumenCarrito .columnaProductos td:first-child {
    padding-left: 0;
    padding-right: 0;
    padding-top: 20px;
}

.resumenCarrito .columnaProductos td:last-child {
    border-right: 1px solid #00ffea;
}

.resumenCarrito .tdCantidad {
    min-width: 120px;
}

.resumenCarrito .imagenTabla {
    width: 100px;
    height: 100px;
    border-top: 1px solid #00ffeb;
    border-bottom: 7px solid #00ffeb;
}

.resumenCarrito .table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
    padding: 15px 0 15px 0;
}

.resumenCarrito .contenedorTotal {
    color: #00aaeb;
    margin-bottom: 20px;
}

.resumenCarrito .textoTotalPedido {
    text-align: center;
    padding: 10px 0 0 0;
    border: 1px solid #00aaeb;
    border-right: none;
}

.resumenCarrito .totalPedido {
    text-align: center;
    padding: 10px 0 0 0;
    border: 1px solid #00aaeb;
}

/*LISTA PRECIOS*/
.resumenCarrito .listaPrecios {
    padding-left: 0;
    padding-top: 9px;
    margin-left: 0;
    width: fit-content;
}

.resumenCarrito .textoListaPrecios {
    color: #00aaeb;
    width: 85px;
    font-size: 14px;
    padding-top: 5px;
    float: left;
    height: 28px;
}

.resumenCarrito .comboListaPrecios {
    background-color: transparent;
    border: 1px solid #00aaeb;
    border-radius: 150px;
    padding: 0 15px 0 20px;
    margin-left: 10px;
    float: left;
    color: #00aaeb;
    width: fit-content;
    height: 28px;
}

.resumenCarrito .download-table-xls-button {
    border: none !important;
    background-color: #00aaeb !important;
    font-family: "Myriad Pro";
    color: white !important;
    text-shadow: none !important;
    width: auto;
    border-radius: 50px;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1);
    transition: all 200ms ease-in;
    transform: scale(1);
    font-family: "Myriad Pro";
    font-weight: 200;
    font-size: 17px;
    padding-left: 34px;
    padding-right: 15px;
    padding-top: 9px;
    padding-bottom: 7px;
    float: right;
    background-image: url("../img/carrito/iconoExcel.png");
    background-repeat: no-repeat;
    background-position-x: 12px;
    background-position-y: 12px;
}

.resumenCarrito .botonInferiorVolver, .resumenCarrito .botonInferiorEnviar {
    background: none !important;
    border: none !important;
    background-color: #00aaeb !important;
    font-family: "Myriad Pro";
    color: white !important;
    text-shadow: none !important;
    width: auto;
    border-radius: 50px;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1);
    transition: all 200ms ease-in;
    transform: scale(1);
    font-family: "Myriad Pro";
    font-weight: 200;
    font-size: 20px;
}

.resumenCarrito .botonInferiorEnviar {
    margin-right: 20px;
}

/*DATOS DEL PEDIDO*/
.resumenCarrito .cuadroDatosPedido {
    border: 1px solid #00a0ef;
    padding: 20px;
    margin-top: 20px;
}

.resumenCarrito .datosPedido {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
}

.resumenCarrito .datosPedido .form-group {
    margin-right: 20px;
}

.resumenCarrito select {
    background-color: transparent;
    border: 1px solid #00aaeb;
    border-radius: 150px;
    padding: 5px 20px;
    color: #00aaeb;
    width: 100%;
    height: fit-content;
}

.resumenCarrito input {
    background-color: transparent;
    border: 1px solid #00aaeb;
    border-radius: 150px;
    padding: 15px 10px;
    color: #00aaeb;
    height: 20px;
    width: 100%;
}

.resumenCarrito textarea {
    width: 530px;
    height: 100px;
    border: 1px solid #00aaeb;
}

.resumenCarrito .datosPedido .direccionEntrega {
    width: 533px;
}

.resumenCarrito .datosPedido .celularDomicilio {
    width: 150px;
}

.resumenCarrito .transporte {
    width: 250px;
}

.resumenCarrito .datosPedido .observacion {
    height: 120px;
}

.resumenCarrito .textoTipoEntrega {
    color: #00a0ef;
}

.resumenCarrito input, .resumenCarrito select, .resumenCarrito .datosPedido label, .resumenCarrito textarea {
    color: #00a0ef;
}

.resumenCarrito input::-webkit-input-placeholder, .resumenCarrito textarea::-webkit-input-placeholder {
    color: #00a0ef;
}

.resumenCarrito input:-ms-input-placeholder, .resumenCarrito textarea:-ms-input-placeholder {
    color: #00a0ef;
    font-style: italic;
}

.resumenCarrito input::placeholder, .resumenCarrito textarea::placeholder {
    color: #00a0ef;
    font-style: italic;
}

.resumenCarrito input[type=date]::-webkit-inner-spin-button,
.resumenCarrito input[type=date]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.resumenCarrito input[type=date] {
    -moz-appearance: textfield;
}

.resumenCarrito input[type="number"]::-webkit-outer-spin-button, .resumenCarrito input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

/*DATOS DEL PEDIDO RESPONSIVE*/
@media (max-width: 730px) {
    .resumenCarrito select, .resumenCarrito input, .resumenCarrito textarea {
        font-size: 11px;
    }

    .resumenCarrito textarea {
        width: 350px;
    }

    .resumenCarrito .datosPedido .direccionEntrega {
        width: 350px;
    }
}

@media (max-width: 510px) {
    .resumenCarrito textarea, .resumenCarrito .datosPedido .direccionEntrega {
        width: 180px;
    }

    .resumenCarrito .columnaDatosMayor {
        display: flex;
        justify-content: center;
    }

    .resumenCarrito .columnaDatos {
        padding: 0 !important;
        margin: 0 auto;
    }
    .resumenCarrito #otroTransporte {
        width: 100%;
    }
}

/*LOADER*/

.loaderPedidoNuevo,
.loaderPedidoNuevo:before,
.loaderPedidoNuevo:after {
    background: #ffffff;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
}

.loaderPedidoNuevo {
    color: #ffffff;
    text-indent: -9999em;
    margin: 88px auto;
    position: relative;
    font-size: 11px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

.loaderPedidoNuevo:before,
.loaderPedidoNuevo:after {
    position: absolute;
    top: 0;
    content: '';
}

.loaderPedidoNuevo:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.loaderPedidoNuevo:after {
    left: 1.5em;
}

@-webkit-keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}

@keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}

.resumenCarrito .textoEnviando {
    float: left;
    padding: 12px;
}

.resumenCarrito h3 {
    color: #00aaeb;
}

.resumenCarrito .contenedorBotones {
    margin-top: 20px;
}

.resumenCarrito .botoneraIzquierda{
    float: left;
}

.resumenCarrito .botoneraDerecha{
    float: right;
}

@media (max-width: 510px) {
    .resumenCarrito .botoneraIzquierda{
        float: none !important;
    }

    .resumenCarrito .botoneraDerecha{
        float: none !important;
        margin-bottom: 20px;
    }

    .resumenCarrito .contenedorBotones {
       text-align: center;
    }
}

