.subBarra p, .greenBorder a{
    color:#8ddd49;
    padding-top: 20px;
    padding-bottom: 10px;
    font-family: "Myriad Pro";
    font-weight: 200;
    padding-left: 20px;
}

.greenBorder a:hover{
    color:white;
}

.subBarra button{
    border: 1px solid #8ddd49;
    border-radius: 200px;
    background-color: #8ddd49;
    color: #00aeef !important;
    font-family: "Montserrat" !important;
    font-weight: 100 !important;
    width: 126px;
    height: 28px;
    padding-top: 1px;
    margin-left: 15px !important;
    margin-right: 15px !important;
}