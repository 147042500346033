.nuevo-local h1{
    color: white;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 10.8em;
    line-height: 135px;
    margin-top: 70px;
}


@media(max-width: 768px){
    .nuevo-local h1{
        font-size: 7em;
        line-height: 88px;
    }
}

@media(max-width: 400px){
    .nuevo-local h1{
        font-size: 3.5em;
        line-height: 40px;
    }
}
.nuevo-local .seccion-blanca{
    margin-top: 70px;
    background-color: white;
    background-image: url('../img/nuevoLocal/cuadroSup.png');
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
    background-size: 1200px;
    color:#00aaeb;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 28px;
    padding-top: 100px;
    padding-bottom: 100px;
    border-bottom: 3px solid #5cffe2;

}

@media(max-width: 400px){
    .nuevo-local .seccion-blanca{
        font-size: 18px;

    }
}

.nuevo-local p{
    margin-bottom: 0;
}

.nuevo-local .direccion{
    background-color: #98e035;
    color:#00aaeb;
    font-family: "Montserrat";
    font-weight: 400;
    border-bottom: 3px solid #5cffe2;
}

.nuevo-local .direccion p {
    margin:0 !important;
    font-size: 20px;
}





.nuevo-local .direccion h3{
    margin: 0 !important;
    font-size: 38px;
    max-width: 450px;
}
.nuevo-local .center-vertical{
    display: table-cell;
    vertical-align: middle;
}


.nuevo-local .barra-divisora{
    height: 92px;
    background-image: url('../img/nuevoLocal/cuadroInf.png');
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: top;
    background-size: 1200px;
    border-bottom: 3px solid #5cffe2;
}

.nuevo-local .creamos h2{
    color:white;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 35px;
    text-align: center;
    margin-top: 120px;
    margin-bottom: 50px;
    line-height: 50px;
}

@media(max-width: 400px){
    .nuevo-local .creamos h2{
        font-size: 20px;
        line-height: 30px;
        margin-top: 40px;
        margin-bottom: 0px;

    }
}
.nuevo-local .barra-mas{
    height: 20px;
    background-image: url('../img/nuevoLocal/mas.png');
    background-position-y: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.nuevo-local .footer{
    background-color: black;
    color:white;
}

.nuevo-local .footer a{
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: 40px;
    display: inherit;
    font-family: "Myriad Pro";
    font-weight: 500;
}

@media(max-width: 400px){
    .nuevo-local .footer a{
        font-size: 20px;
    }
}