.cotizacion h1{
    color: white;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 10.8em;
    line-height: 135px;
    margin-top: 70px;
}


@media(max-width: 768px){
    .cotizacion h1{
        font-size: 7em;
        line-height: 88px;
    }
}

@media(max-width: 400px){
    .cotizacion h1{
        font-size: 3.5em;
        line-height: 40px;
    }
}
.cotizacion .seccion-blanca{
    margin-top: 70px;
    background-color: white;
    background-image: url('../img/nuevoLocal/cuadroSup.png');
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
    background-size: 1200px;
    color:#00aaeb;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 28px;
    padding-top: 100px;
    padding-bottom: 100px;
    border-bottom: 3px solid #5cffe2;

}

@media(max-width: 400px){
    .cotizacion .seccion-blanca{
        font-size: 18px;

    }
}

.cotizacion p{
    margin-bottom: 0;
}

.cotizacion .direccion{
    background-color: #98e035;
    color:#00aaeb;
    font-family: "Montserrat";
    font-weight: 400;
    border-bottom: 3px solid #5cffe2;
}

.cotizacion .direccion p {
    margin:0 !important;
    font-size: 20px;
}


.cotizacion .direccion h3{
    margin: 0 !important;
    font-size: 38px;
    max-width: 450px;
}
.cotizacion .center-vertical{
    display: table-cell;
    vertical-align: middle;
}

.cotizacion .cotizador-select select{
    -webkit-appearance: none !important;
    background: none;
    margin: 0 auto;
    width: 100%;
    border: none;
    border-radius: 0px;
    border-top: 2px solid #00FFEA;
    border-bottom: 2px solid #00FFEA;
    padding-top: 5px;
    padding-bottom: 4px;
    padding-left: 13px;
    background-image: url("../img/iconLista/expand.png");
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 50%;
    background-size: 33px;
}

.cotizacion p{
    text-align: center;
    margin: 0 auto;
    display: block;
    margin-top: 20px;
    font-size: 30px;
    font-weight: 500;
}

.cotizacion .footer{
    background-color: black;
    color:white;
}

.cotizacion .footer a{
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: 40px;
    display: inherit;
    font-family: "Myriad Pro";
    font-weight: 500;
}

@media(max-width: 400px){
    .cotizacion .footer a{
        font-size: 20px;
    }
}