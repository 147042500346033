/*Datos del usuario*/
.datosUsuario {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    text-align: right;
}

/*Perfil*/
.datosUsuario h3 {
    font-family: "Myriad Pro" !important;
    font-weight: 100;
    color: #00aaeb;
    float: right;
    margin-bottom: 15px;
}

/*Nombre usuario - empresa*/
.datosUsuario .nombre {
    float: right;
    font-family: "Myriad Pro";
    font-weight: 200;
    color: #00aaeb;
    font-size: 14px;
}

/*Lista de links cerrar sesion y cambiar contraseña*/
.datosUsuario .menu {
    list-style: none;
}

/*Estilo de links cerrar sesion y cambiar contraseña*/
.datosUsuario .menu li a {
    font-family: "Myriad Pro" !important;
    font-weight: 400 !important;
    color: #00aaeb !important;
    font-size: 13px;
}

/*Tooltip*/
/*tooltip*/
.datosUsuario .custom-tooltip img {
    position: absolute;
    margin-top: 16px;
    margin-left: -23px;
    border: 1px solid #00ffea;
    border-radius: 40px;
    cursor: help;
    z-index: 1000;
}

#tooltipCuentas {
    background-color: white;
    font-family: "Myriad Pro";
    font-size: 13px;
    font-weight: 100;
    height: fit-content;
    padding: 5px;
    top: 264px !important;
    z-index: 2000;
}

#tooltipCuentas .tooltip-inner {
    background: none !important;
    border: 1px solid #00ffeb;
    color: #00aaeb;
    margin: 0 !important;
    max-width: 250px !important;
}

#tooltipCuentas .tooltip-arrow {
    display: none;
}

#tooltipCuentas p {
    padding-top: 13px;
    text-align: left;

}

.datosUsuario .colores {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.datosUsuario .colores .input-row{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.datosUsuario .colores label {
    font-weight: 100;
    font-size: 12px;
    text-align: center;
    line-height: initial;
    margin-bottom: 0;
}

.datosUsuario .colores input {
    box-shadow: none;
    text-shadow: none;
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
}