.profesional-portada {
    border-top: 2px solid #00ffeb;
    background-image: url("../img/bg-compra.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 600px;
}

.recuadro-celeste {
    background-color: #00adee;
    padding: 45px;
    text-align: center;
    position: relative;
    margin-top: 27%;
}

.profesional-portada h1 {
    color: white;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 60px;
    margin: 0;
    margin-bottom: 20px;
    padding: 0;
}

@media(max-width: 400px){
    .profesional-portada h1{
        font-size: 40px;
    }
}

.profesional-portada p {
    color: white;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 16px;
    margin: 0;
    padding: 0;
}

.timeline {
    padding-top: 80px;
    padding-bottom: 120px;
}

.timeline h2 {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 24px;
    color: #8ddd49;
    text-align: center;
    margin-bottom: 40px;
}

.timeline img {
    max-width: auto;
    margin: 0 auto;
}

.garantizado {
    background-color: #9ae235;
    border-top: 2px solid #00ffeb;
}

.garantizado-bg {
    background-image: url("../img/caro.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right bottom;
    padding-top: 120px;
    padding-bottom: 120px;
}

.garantizado .titulos {
    max-width: 432px;
    color: white;
    padding-left: 80px;
}

@media(max-width: 843px){

    .garantizado .titulos {
        padding-left: 20px;
    }
}

.garantizado .titulos h2 {
    margin-bottom: 0px;
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 80px;
}



.garantizado .titulos h3 {
    margin-top: 0px;
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 70px;
}

@media(max-width: 600px){
    .garantizado .titulos h2 {
        font-size: 40px;
    }
    .garantizado .titulos h3 {
        font-size: 30px;
    }
}

.garantizado .titulos p {
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 18px;
}

.sos-integrador {
    background-color: #00ffea;
    padding-bottom: 20px;
    padding-top: 30px;
}

.sos-integrador .titulos {
    color: #00adee;
    max-width: 380px;
}

.sos-integrador .titulos h2 {
    font-family: "Montserrat";
    font-size: 40px;
}

.sos-integrador .titulos p{
    font-family: "Myriad Pro";
    font-weight: 100;
    margin-top: 20px;
    font-size: 20px;
}

.sos-integrador img{
    padding-top: 50px;
    margin-left: 100px;
    width: 100px;
}